import i18n from "../../nls/root/i18n";

export const convertedKpiName = {
  Loss: "loss_percentage",
  Latency: "latency",
  Jitter: "jitter"
}

export const topTunnel = (usageTunnel) => {
  return usageTunnel ? usageTunnel : 0
}

export const convertedKpiV4 = {
  Loss: "loss_percentage_anomaly_cnt",
  Latency: "latency_anomaly_cnt",
  Jitter: "jitter_anomaly_cnt",
  loss_percentage_anomaly_cnt: "Loss",
  latency_anomaly_cnt: "Latency",
  jitter_anomaly_cnt: "Jitter",
  loss_percentage_anomaly_percentage_record: "Loss",
  latency_anomaly_percentage_record: "Latency",
  jitter_anomaly_percentage_record: "Jitter",
  topx_tunnel_usage_loss_percentage_anomaly_percentage_record: "Loss",
  topx_tunnel_usage_latency_anomaly_percentage_record: "Latency",
  topx_tunnel_usage_jitter_anomaly_percentage_record: "Jitter"
}

export const adTableData = (response, usageType) => {
  let tableData = [], lossEntry = {}, latencyEntry = {}, jitterEntry = {};

  let data = response?.data?.data[0];
  if (data) {
    lossEntry.kpiType = "Loss"
    lossEntry.numOfAnomaliesDetected = usageType === i18n.predictiveNetworks.topTunnel
      ? data.topx_tunnel_usage_loss_percentage_anomaly_cnt
      : data.loss_percentage_anomaly_cnt;
    lossEntry.numOfImpactedSites = usageType === i18n.predictiveNetworks.topTunnel
      ? data.topx_tunnel_usage_loss_percentage_anomaly_site_cnt
      : data.loss_percentage_anomaly_site_cnt;
    lossEntry.numOfImpactedTunnels = usageType === i18n.predictiveNetworks.topTunnel
      ? data.topx_tunnel_usage_loss_percentage_anomaly_tunnel_cnt
      : data.loss_percentage_anomaly_tunnel_cnt;
    lossEntry.usedTunnelsX = usageType !== i18n.predictiveNetworks.topTunnel
      ? data.inference_tunnel_cnt
      : data.topx_tunnel_usage_tunnel_count;
    lossEntry.totalTunnelsY = data.total_tunnel_count;
    lossEntry.percentage = usageType === i18n.predictiveNetworks.topTunnel
      ? data.topx_tunnel_usage_loss_percentage_anomaly_percentage_record
      : data.loss_percentage_anomaly_percentage_record;

    latencyEntry.kpiType = "Latency"
    latencyEntry.numOfAnomaliesDetected = usageType === i18n.predictiveNetworks.topTunnel
      ? data.topx_tunnel_usage_latency_anomaly_cnt
      : data.latency_anomaly_cnt;
    latencyEntry.numOfImpactedSites = usageType === i18n.predictiveNetworks.topTunnel
      ? data.topx_tunnel_usage_latency_anomaly_site_cnt
      : data.latency_anomaly_site_cnt;
    latencyEntry.numOfImpactedTunnels = usageType === i18n.predictiveNetworks.topTunnel
      ? data.topx_tunnel_usage_latency_anomaly_tunnel_cnt
      : data.latency_anomaly_tunnel_cnt;
    latencyEntry.usedTunnelsX = usageType !== i18n.predictiveNetworks.topTunnel
      ? data.inference_tunnel_cnt
      : data.topx_tunnel_usage_tunnel_count;
    latencyEntry.totalTunnelsY = data.total_tunnel_count;
    latencyEntry.percentage = usageType === i18n.predictiveNetworks.topTunnel
      ? data.topx_tunnel_usage_latency_anomaly_percentage_record
      : data.latency_anomaly_percentage_record;

    jitterEntry.kpiType = "Jitter"
    jitterEntry.numOfAnomaliesDetected = usageType === i18n.predictiveNetworks.topTunnel
      ? data.topx_tunnel_usage_jitter_anomaly_cnt
      : data.jitter_anomaly_cnt;
    jitterEntry.numOfImpactedSites = usageType === i18n.predictiveNetworks.topTunnel
      ? data.topx_tunnel_usage_jitter_anomaly_site_cnt
      : data.jitter_anomaly_site_cnt;
    jitterEntry.numOfImpactedTunnels = usageType === i18n.predictiveNetworks.topTunnel
      ? data.topx_tunnel_usage_jitter_anomaly_tunnel_cnt
      : data.jitter_anomaly_tunnel_cnt;
    jitterEntry.usedTunnelsX = usageType !== i18n.predictiveNetworks.topTunnel
      ? data.inference_tunnel_cnt
      : data.topx_tunnel_usage_tunnel_count;
    jitterEntry.totalTunnelsY = data.total_tunnel_count;
    jitterEntry.percentage = usageType === i18n.predictiveNetworks.topTunnel
      ? data.topx_tunnel_usage_jitter_anomaly_percentage_record
      : data.jitter_anomaly_percentage_record;

    tableData.push(lossEntry);
    tableData.push(latencyEntry);
    tableData.push(jitterEntry);
  }
  return tableData
}
