import i18n from "amdi18n-loader!../../nls/i18n";
import SitesDropdownWidget from "../../../generics/SitesDropdownWidget";
import AppHOC from "../../../generics/AppHOC";

// style
import css from "../insightsMagneticStyle.less";

const PredictiveNetworkHeader = (props) => {
    return (
        <div className={css["wani-header"]}>
            <div className="hbr-type-h1">{i18n.predictiveNetworks.predictiveNetworksPageTitle}</div>
            <div className="sites-widget"><SitesDropdownWidget {...props} /></div>
        </div>
    )
}
export default AppHOC(PredictiveNetworkHeader);