import React, { useRef, useEffect } from "react";
import { PropTypes } from "prop-types";
import { GeoMap } from "@cisco/geomaps";

const GeoMapComponentMini = ({ longitude, latitude }) => {
  // when latitude is greater than 90 degrees, that is out of bound value,
  //we set both coordinates to 0
  const long = latitude > 90 ? 0 : longitude;
  const lat = latitude > 90 ? 0 : latitude;

  const sites = [
    {
      site_id: "1",
      site_name: "Site",
      longitude: long,
      latitude: lat
    }
  ];

  const mapVisualRef = useRef(null);

  const pointsToShow = sites.map((site, index) => {
    const { site_id, site_name, longitude, latitude } = site;

    let defaultPointingOptions = {
      id: site_id,
      location: [longitude, latitude],
      name: site_name,
      defaultMarkerOptions: {
        color: "var(--success-color, #00AB50)"
      },
      snoToShow: index + 1
    };

    return defaultPointingOptions;
  });

  const getMapSettings = () => {
    // when latitude is greater than 90 degrees, that is out of bound value,
    //we set both coordinates to 0
    const long = latitude > 90 ? 0 : longitude;
    const lat = latitude > 90 ? 0 : latitude;
    return {
      zoom: 1,
      center: [long, lat]
    };
  };

  const createMapViual = () => {
    let defaultInitProperties = {
      domHolder: mapVisualRef.current,
      trackResize: false,
      enableNav: false,
      enableLegend: false,
      points: pointsToShow,
      mapSettings: getMapSettings()
    };

    const map = new GeoMap(defaultInitProperties);

    map.init();
    map.fitBounds(100);
  };

  useEffect(() => {
    createMapViual();
  }, []);

  return (
    <div
      ref={mapVisualRef}
      style={{ height: "100%", width: "100%" }}
      name="map-visual"
    ></div>
  );
};

GeoMapComponentMini.propTypes = {
  latitude: PropTypes.any,
  longitude: PropTypes.any
};

export default GeoMapComponentMini;
