import React, { useState, useMemo, useCallback } from "react";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import reduxContext from "../reducer";
import * as actions from "../actions";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import AppHOC from "../../../generics/AppHOC";
import Spinner from "../../../common/Spinner";
import ErrorComponent from "../../../common/ErrorComponent";
import NoDataAvailable from "../../../common/NoDataAvailable";
import TrendAnalysis from "./TrendAnalysis";
import { getColor } from "../../../utils/colors";
import { getCircuitsAvailability } from "../common/masterTableUtil";
import i18n from "amdi18n-loader!../../nls/i18n";
import css from "../circuitsMagneticStyle.less";

const [HbrButton, HbrDrawer, HbrIcon] = reactWrapper([
  "hbr-button",
  "hbr-drawer",
  "hbr-icon"
]);

const Availability = ({
  globalFilter: { selectedSite, circuitsMasterTable: circuitsData },
  actions
}) => {
  const [showSidebar, setShowSidebar] = useState(false);

  const data = useMemo(() => {
    const upTimeData = {
      error: null,
      upTime: 0,
      textColor: ""
    };
    if (!circuitsData.loader) {
      upTimeData.error = circuitsData.error;

      if (!upTimeData.error) {
        let availability = {}
        if (selectedSite > -1) {
          availability = getCircuitsAvailability(
            circuitsData,
            selectedSite
          );
        } else {
             availability = getCircuitsAvailability(
               circuitsData,
               selectedSite,
               true,
               true
          );
        }
        upTimeData.upTime = parseFloat(availability.current.toFixed(2));
        upTimeData.textColor = getColor(upTimeData.upTime, "availability");
      }
    }
    return upTimeData;
  }, [selectedSite, circuitsData.loader]);

  const onOpen = () => setShowSidebar(true);
  const onClose = useCallback(event => {
    // fix for harbor drawer element auto closing issue on hovering over the tooltip content
    if (event.target.tagName === "HBR-DRAWER") {
      setShowSidebar(false);
      actions.resetHeatMapAvail({});
      actions.selectedTrendCircuits([]);
    }
  }, []);

  return (
    <div
      className={`flex-column-full ${css.availability}`}
      data-testid="availability"
    >
      <div className="flex-items-two">
        <div className="hbr-type-h2">{i18n.circuit.aTitle}</div>
        {!circuitsData.loader && !data.error && (
          <HbrButton className="trend-button" variant="text" onClick={onOpen}>
            {i18n.trendingAppsViewTrendAnalysis}
          </HbrButton>
        )}
      </div>
      <div className="flex-main-content flex-items-center">
        {circuitsData.loader ? (
          <Spinner />
        ) : data.error ? (
          <ErrorComponent
            {...data.error}
            width="110px"
            className="one-line-dashlet-error"
          />
        ) : circuitsData.data.length === 0 ? (
          <NoDataAvailable />
        ) : (
          <div
            className="flex-items-center hbr-type-h1"
            style={{ color: data.textColor }}
          >
            <div>{`${data.upTime}${i18n.circuit.aUptimeSuffix}`}</div>
            <HbrIcon name="trend-up" label={i18n.circuit.aUptimeSuffix} />
            {showSidebar && (
              <div className={css.trendsidebar}>
                <HbrDrawer
                  overlay={true}
                  label={i18n.circuit.aTitle}
                  open
                  onHbr-hide={onClose}
                >
                  <TrendAnalysis />
                </HbrDrawer>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

Availability.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default reduxContext.withProvider(
  connect(null, mapDispatchToProps)(AppHOC(Availability))
);
