import React, { useCallback } from "react";
import { PropTypes } from "prop-types";
import AppHOC from "../../../generics/AppHOC";
import Spinner from "../../../common/Spinner";
import ErrorComponent from "../../../common/ErrorComponent";
import NoDataAvailable from "../../../common/NoDataAvailable";
import StackedMultiLineChart from "../../../common/StackedMultiLineChart";
import { getTooltipHTMLString } from "../../../utils/chart";
import i18n from "amdi18n-loader!../../nls/i18n";
import css from "../circuits360MagneticStyle.less";

const View = ({
  globalFilter,
  loader,
  error,
  data,
  seriesColors
}) => {

  // customize graph
  const customizeChart = useCallback(chart => {
    chart.series.values.forEach(series => {
      series.adapter.add("tooltipHTML", (html, target) => {
        const data = target.tooltipDataItem.dataContext;
        if (data && data.tooltipData) {
          const reverseSeries = [...chart.series.values].reverse();
          return getTooltipHTMLString(
            reverseSeries, data, data.tooltipData.date, i18n.c360.ucTtipTitle
          );
        }
        return html;
      });
    });
    chart.yAxes.values[0].numberFormatter.numberFormat = "#.b";
    chart.yAxes.values[0].maxPrecision = 0;
    chart.yAxes.values[0].renderer.minGridDistance = 75;
    chart.legend.userClassName += " app-usg-legend";
  }, []);

  return (
    <div className={`${css.chart} chart-section flex-column`}>
      <div className="flex-column flex-main-content">
        <div className="title-block">
          <div className="hbr-type-h2">{i18n.c360.ucTitle}</div>
        </div>
        {loader === true ? (
          <Spinner />
        ) : error !== null ? (
          <ErrorComponent
            {...error}
            className="small-dashlet-error"
            width="220px"
          />
        ) : data.length === 0 ? (
          <NoDataAvailable />
        ) : (
          <div className="flex-main-content">
            <StackedMultiLineChart
              id="c360_app_usage"
              label={i18n.c360.ucYLabel}
              scrollbar
              scrollBarInitialZoom
              colorset={seriesColors}
              callbackFromParent={customizeChart}
              data={data}
              min={globalFilter.timeFilter.current_period[0]}
              max={globalFilter.timeFilter.current_period[1]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

View.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  loader: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  seriesColors: PropTypes.object.isRequired
};

export default AppHOC(View);
