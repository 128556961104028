import axios from "axios";
import reduxContext from "./reducer";
import apiService from "../../config/api-config";
import { createGenericErrorObject } from "../../utils/common";
import { circuitUrls } from "../../apis/apiConstants";
import { ACTIONS } from "./actionTypes";

export const getDiaTrendData = async globalV4Payload => {
  try {
    const payload = {
      ...globalV4Payload,
      sort: { entry_ts: "asc" }
    };
    return await axios.post(circuitUrls.diaVsTunnelUsage, payload);
  } catch (e) {
    return createGenericErrorObject(e);
  }
};

export const getColorTrendData = async globalV4Payload => {
  try {
    const payload = {
      ...globalV4Payload,
      sort: { entry_ts: "asc" }
    };
    return await axios.post(circuitUrls.colorUsage, payload);
  } catch (e) {
    return createGenericErrorObject(e);
  }
};

export const getBandwidthData = async (v4Payload, circuits) => {
  try {
    const payload = {
      ...v4Payload,
      circuit_name: circuits.map(item => item.circuit_name),
      sort: { entry_ts: "asc" }
    };
    return await axios.post(circuitUrls.bandwidthAvg, payload);
  } catch (error) {
    return createGenericErrorObject(error);
  }
};

export const setSelectedCircuits = (selectionIds, data) => {
  reduxContext.store.dispatch({
    type: ACTIONS.setSelectedCircuits,
    payload: data.filter(item => selectionIds.includes(item.id))
  });
};

export const resetHeatMapAvail = empty => dispatch => {
  dispatch({
    type: ACTIONS.RESET_HEATMAP_AVAIL_CHANGE,
    payload: empty
  });
};

export const getTrendCircuitsAvailability = trendCircuits => dispatch => {
  apiService.getTrendCircuitsAvailability(trendCircuits).then(res => {
    if (!res.errorObject) {
      if (Array.isArray(res.data?.data)) {
        let circuitsList = res.data.data;
        for (const item of circuitsList) {
          const [, color] = item.circuit_name.split(":");
          item.circuit_name = `${item.host_name}:${color}`;
        }
      }
      dispatch({
        type: ACTIONS.SET_TREND_CIRCUITS_AVAIL,
        payload: res.data
      });
    } else {
      dispatch({
        type: ACTIONS.HEATMAP_API_STATUS,
        payload: { isloading: false, resObj: res.errorObject }
      });
    }
  });
};

export const selectedTrendCircuits = trendCircuitsList => dispatch => {
  dispatch({
    type: ACTIONS.GET_SELECTED_TREND_CIRCUITS_LIST,
    payload: trendCircuitsList
  });
};

export const heatMapApiStatus = payload => dispatch => {
  dispatch({
    type: ACTIONS.HEATMAP_API_STATUS,
    payload: payload
  });
};
