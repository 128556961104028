import React, { useEffect, useCallback, useState, Suspense } from "react";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

//styles
import css from "../../anomalyMagneticStyle.less";

//utils
import ApiService from "../../../../../../config/api-config";
import { elt_anomaly_payload, top_usage_ad_payload } from "../../../../../../config/reqConfig/anomalyDetection";
import {
  displayPathAnalyticsTime,
  shortDisplayDate24HourWithSec,
  formatDate,
  getTimePeriodForAD,
  convertTsToLocal
} from "../../../../../../utils/displayTime";
import ADStackedBarChart from "../../ADStackedBarChart";
import Spinner from "../../../../../../common/Spinner";
import CustomTimeStamp from "../../../../../../generics/TimeStamp/customTimeStamp";
import AppHOC from "../../../../../../generics/AppHOC";
import reduxContext from "../../reducer";
import * as actions from "../../actions";
import ADTable from "../ADTable";
import i18n from "../../../../../nls/root/i18n";
const NoDataAvailable = React.lazy(() =>
  import("../../../../../../common/NoDataAvailable")
);
const ErrorComponent = React.lazy(() =>
  import("../../../../../../common/ErrorComponent")
);

const mapStateToProps = state => {
  return {
    adKey: state.vanalytics.adKey
  };
};
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

let timePeriodAD = getTimePeriodForAD();
let dateWeekAgo = timePeriodAD[0];
let todayDate = timePeriodAD[1];

const ADChartView = (props) => {
  const [selectedHour, setSelectedHour] = useState(null);
  const [kpiDistData, setKpiDistData] = useState([]);
  const [kpiDistDataLoader, setKpiDistDataLoader] = useState(true);
  const [kpiDistDataError, setKpiDistDataError] = useState('');

  const loadData = useCallback(async () => {
    setKpiDistDataLoader(true)
    setKpiDistDataError(null)
    let timePeriod = [shortDisplayDate24HourWithSec(formatDate(dateWeekAgo).utc()), shortDisplayDate24HourWithSec(formatDate(todayDate).utc())]
    let payload = props.usageType === i18n.predictiveNetworks.topTunnel
      ? top_usage_ad_payload(timePeriod)
      : elt_anomaly_payload(timePeriod)
    setSelectedHour(null);
    let res = await ApiService.getADChartKPI(payload) // send 7 days period [start, end] based on current processing time
    setKpiDistData(res.data?.data)
    setKpiDistDataLoader(false)
    let loss = [], jitter = [], latency = []
    if (!res.errorObject) {
      res?.data?.data.forEach(data => {
        if(props.usageType === i18n.predictiveNetworks.topTunnel) {
          loss.push({ entry_ts: data.entry_ts + " GMT", count: data.topx_tunnel_usage_loss_percentage_anomaly_percentage_record })
          latency.push({ entry_ts: data.entry_ts + " GMT", count: data.topx_tunnel_usage_latency_anomaly_percentage_record })
          jitter.push({ entry_ts: data.entry_ts + " GMT", count: data.topx_tunnel_usage_jitter_anomaly_percentage_record })
        } else {
          loss.push({ entry_ts: data.entry_ts + " GMT", count: data.loss_percentage_anomaly_percentage_record })
          latency.push({ entry_ts: data.entry_ts + " GMT", count: data.latency_anomaly_percentage_record })
          jitter.push({ entry_ts: data.entry_ts + " GMT", count: data.jitter_anomaly_percentage_record })
        }

        data.entry_ts = convertTsToLocal(data.entry_ts);
      });

    let responseObj = {
      Loss: loss,
      Latency: latency,
     Jitter: jitter,
    }

    props.actions.updateBarChartData(responseObj)
      // Save last date for anomalies detected for last 1h
      if(res?.data?.data.length === 0) {
        props.actions.saveDateForTable(null);
        setSelectedHour(null);
      } else {
        let hour = res?.data?.data[res?.data?.data.length - 1]?.entry_ts
        props.actions.saveDateForTable(displayPathAnalyticsTime(hour));
        setSelectedHour(displayPathAnalyticsTime( hour));
      }
    } else {
      setKpiDistDataError(res.errorObject)
      props.actions.saveDateForTable(null);
    }
  });

  const loadGrpah = () => {
    props.setAltoProps({ predictiveTabSelected: 'anomalyTab', status: "" });
    loadData();
  }

  useEffect(() => {
    loadGrpah()
    return () => {
      props.actions.saveDateForTable(null);
    };
  }, [props.globalFilter.onAnomalyRefresh, props.usageType]);

  return (
    <div
      className={css["anomaly-widget"]}
    >
      {kpiDistDataLoader? (
        <Spinner />
      ) : kpiDistDataError ? (
        <Suspense fallback={<Spinner />}>
          <ErrorComponent
            {...kpiDistDataError}
            className="small-dashlet-error"
            width="220px"
          />
        </Suspense>
      ) : kpiDistData?.length === 0 ? (
        <div className="ad-emptyData">
          <Suspense fallback={<Spinner />}>
            <NoDataAvailable
              text={i18n.emptyData}
            />
          </Suspense>
        </div>
      ) : ( selectedHour &&
        <div className="ad-stack-chart">
          <div className="timestamp">
            <CustomTimeStamp
              prevTime={dateWeekAgo}
              currentTime={todayDate}
            />
          </div>
          <ADStackedBarChart
            saveData={props.actions.saveDateForTable}
            data={kpiDistData}
            disabledScrollbar={false}
            isTitleShown={true}
            chartsData={[]}
            chartProps={{
              chartId: "anomaly-chart",
              leftTitleForY: i18n.predictiveNetworks.anomalyCounts,
            }}
            selectedBar={selectedHour}
            usageType={props.usageType}
          />
          {selectedHour && <ADTable usageType={props.usageType}/>}
        </div>
      )}
    </div>
  );
};

ADChartView.propTypes = {
  globalFilter: PropTypes.object,
  setAltoProps: PropTypes.func,
  actions: PropTypes.object,
  adKey: PropTypes.object,
  usageType: PropTypes.string
};
export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHOC(ADChartView))
);
