import { useState } from "react";

// apis
import ApiService from "../../../../config/api-config";

// utils
import {
  displayUserExpChartTooltp,
  getStartOfHour
} from "../../../../utils/displayTime";
import { getLocalStorageFlag } from "../../../../utils/common";
import { validateProps, validate } from "../../../../utils/waniCommon";

const useAppGroupPerSiteApis = () => {
  const [pathUserExprChartData, setPathUserExprChartData] = useState();
  const [
    isFetchingPathQosChartDataDone,
    setIsFetchingPathQosUserChartDataDone
  ] = useState(false);
  const [
    isFetchingPathQosChartDataError,
    setIsFetchingPathQosUserChartDataError
  ] = useState({});
  const [pathMetrix, setPathMetrixChartData] = useState();
  const [
    isFetchingPathMetrixChartDone,
    setIsFetchingPathMetrixChartDone
  ] = useState(false);
  const [
    isFetchingPathMetrixChartError,
    setIsFetchingPathMetrixChartError
  ] = useState({});
  const [networkQoeMetricsData, setNetworkQoeMetricsData] = useState();
  const [
    isFetchingNetworkQoeMetricsDone,
    setIsFetchingNetworkQoeMetricsDone
  ] = useState(false);
  const [
    isFetchingNetworkQoeMetricsError,
    setIsFetchingNetworkQoeMetricsError
  ] = useState({});

  /**
   * Returns an array that contains a list of objects with such properties as: date, value0, value1,...valuen
   * @description  create a list of values to built multiple linse for an unique kpi i.e. jitter
   * @param {object} pathMetricsDateArray is an array that consists of list of objects with keys such as date and value0, value1,...valuen
   *  @param {array} kpi is an array that contains such elements as jitter, latency and loss
   */
  const transformDateObjecToArray = (pathMetricsDateArray, kpi) => {
    return pathMetricsDateArray.map(pathMetrics => {
      const objectForKpi = {};

      pathMetrics[kpi].forEach((value, index) => {
        objectForKpi[`value${index}`] = value;
      });
      const epoch = new Date(pathMetrics.date);
      return {
        date: epoch.getTime(),
        ...objectForKpi
      };
    });
  };

  /**
   * Returns an object called 'pathMetrixChartData' that contains such props as: pathMetrixJitter, pathMetrixLoss, pathMetrixLatency
   * @description call api to get path metrics chart details data for side panel
   * @param {object} props is an object that  contains globalFilter, and pathEndpointPair which is a string that contains symEnpointsArray of endpoints
   */
  const getPathMetricsChartData = async props => {
    const pathDetails = JSON.parse(localStorage.getItem("pathDetails"));
    const symEndpointsArray = pathDetails.pathQosPair.split(",");
    const {
      selectedOverlay,
      appGroup,
      latestWindowStart,
      latestWindowEnd
    } = validateProps(props.globalFilter);
    let isError = false;

    if (isFetchingPathMetrixChartError.errorObject) {
      setIsFetchingPathMetrixChartError({});
    } else if (isFetchingNetworkQoeMetricsError.errorObject) {
      setIsFetchingNetworkQoeMetricsError({});
    }

    const pathMetricResult = await ApiService.getAltoNetworkPaths(
      {
        aggregatedBy: "none",
        endpointPair: symEndpointsArray[0] + "," + symEndpointsArray[1],
        limit: 5000
      },
      selectedOverlay
    );

    const promisesQoe = [];
    const promisesQos = [];
    const ganttData = [];

    //check if pathMetricResult contains a error object and if so set the error
    if (pathMetricResult.errorObject) {
      setIsFetchingPathMetrixChartError(pathMetricResult);
    } else {
      // use metricsType: "NetworkQos" for path metrics
      pathMetricResult.data.paths.forEach((item, index) => {
        promisesQos.push(
          ApiService.getAltoPathMetrics(
            {
              numWindows: "4320",
              windowSize: "10m",
              appClass: "global",
              metricsType: "NetworkQos",
              pathId: item.pathId,
              experimental: "v2",
              limit: 5000
            },
            selectedOverlay
          )
        );
        //create the list of all the interfaces for paths
        // this list is used for gantt charts with list of interfaces
        ganttData.push({
          srcInterfaceId: pathMetricResult.data.paths[index].srcInterfaceId,
          dstInterfaceId: pathMetricResult.data.paths[index].dstInterfaceId
        });
      });

      // use metricsType: "NetworkQoe" for path metrics
      pathMetricResult.data.paths.forEach(item => {
        promisesQoe.push(
          ApiService.getAltoPathMetrics(
            {
              numWindows: "4320",
              windowSize: "10m",
              appClass: appGroup,
              metricsType: "NetworkQoe",
              pathId: item.pathId,
              experimental: "v2",
              limit: 5000
            },
            selectedOverlay
          )
        );
      });

      Promise.all([...promisesQoe]).then(pathMetricResults => {
        const metrics = {};

        //check if there is a result with an error object and set the error if so
        for (const pathMetricResult of pathMetricResults) {
          if (pathMetricResult.errorObject) {
            isError = true;
            setIsFetchingPathMetrixChartError(pathMetricResult);
            break;
          }
        }

        /**
         * we need to compute mean quality for gantt tooltip
         * and we need the probSlaViolation
         * for that we use metricsType: "NetworkQoe" property
         * we have 5 probSlaViolation instnces per hour and so we need the average
         * so we add up 5 probSlaViolation and divide to 5 and multiply by 100 %
         */
        if (!isError) {
          pathMetricResults.forEach((result, index) => {
            let probSlaViolationMean = 0;
            const networkQoe = [];

            if (result.data && result.data.pathMetrics) {
              result.data.pathMetrics.forEach(el => {
                const dateStart = new Date(el.window.start);
                const dateEnd = new Date(el.window.end);
                if (dateStart.getHours() === dateEnd.getHours()) {
                  probSlaViolationMean += el.probSlaViolation;
                } else {
                  const toISOString = new Date(
                    getStartOfHour(dateStart)
                  ).toISOString();
                  networkQoe[toISOString] = {
                    dateStart: new Date(getStartOfHour(dateStart)),
                    dateEnd: new Date(getStartOfHour(dateEnd)),
                    meanQuality: 100 - (probSlaViolationMean / 5) * 100
                  };
                  probSlaViolationMean = 0;
                }
              });
            }

            metrics[index] = networkQoe;
          });
          metrics["gantData"] = ganttData;

          setNetworkQoeMetricsData(metrics);
        }

        setIsFetchingNetworkQoeMetricsDone(true);
      });

      /*
      With api that use metricsType: "NetworkQos" prop
      we can get data for path metrix chart 
      that contains jitter latency and loss
      */
      Promise.all([...promisesQos]).then(networkQosResults => {
        const numOfResults = networkQosResults.length;
        const isPathMetricChart = true;
        const startDate = latestWindowStart;
        const pathMetricsDateObject = createChartInitialObject(
          startDate,
          latestWindowEnd,
          numOfResults,
          isPathMetricChart
        );

        //check if there is a result with an error object and set the error if so
        for (const pathMetricResult of networkQosResults) {
          if (pathMetricResult.errorObject) {
            isError = true;
            setIsFetchingNetworkQoeMetricsError(pathMetricResult);
            break;
          }
        }

        if (!isError) {
          networkQosResults.forEach((result, resutlsIndex) => {
            if (result.data.pathMetrics) {
              result.data.pathMetrics.forEach(pathMetricElement => {
                let date = new Date(
                  pathMetricElement.window.start
                ).toISOString();

                if (
                  new Date(startDate) <=
                  new Date(pathMetricElement.window.start) &&
                  !pathMetricsDateObject[date]
                ) {
                  pathMetricsDateObject[date] = {
                    jitterMs: [0],
                    lossFrac: [0],
                    latencyMs: [0],
                    date: new Date(date)
                  };
                }

                if (
                  pathMetricsDateObject[date] &&
                  pathMetricsDateObject[date].jitterMs.length - 1 < resutlsIndex
                ) {
                  pathMetricsDateObject[date].jitterMs.push(0);
                  pathMetricsDateObject[date].lossFrac.push(0);
                  pathMetricsDateObject[date].latencyMs.push(0);
                }

                if (pathMetricsDateObject[date]) {
                  pathMetricsDateObject[date].jitterMs[
                    resutlsIndex
                  ] = pathMetricElement.jitterMs
                      ? parseFloat(
                        parseFloat(pathMetricElement.jitterMs).toFixed(2)
                      ) !== 0
                        ? parseFloat(
                          parseFloat(pathMetricElement.jitterMs).toFixed(2)
                        )
                        : 0
                      : 0;
                  pathMetricsDateObject[date].lossFrac[
                    resutlsIndex
                  ] = pathMetricElement.lossFrac
                      ? parseFloat(
                        parseFloat(pathMetricElement.lossFrac * 100).toFixed(2)
                      ) !== 0
                        ? parseFloat(
                          parseFloat(pathMetricElement.lossFrac * 100).toFixed(
                            2
                          )
                        )
                        : 0
                      : 0;
                  pathMetricsDateObject[date].latencyMs[
                    resutlsIndex
                  ] = pathMetricElement.latencyMs
                      ? parseFloat(
                        parseFloat(pathMetricElement.latencyMs).toFixed(2)
                      ) !== 0
                        ? parseFloat(
                          parseFloat(pathMetricElement.latencyMs).toFixed(2)
                        )
                        : 0
                      : 0;
                }
              });
            }
          });

          const sortedChartDataByDate = Object.values(
            pathMetricsDateObject
          ).sort((a, b) => a.date - b.date);

          setPathMetrixChartData({
            pathMetrixJitter: transformDateObjecToArray(
              sortedChartDataByDate,
              "jitterMs"
            ),
            pathMetrixLoss: transformDateObjecToArray(
              sortedChartDataByDate,
              "lossFrac"
            ),
            pathMetrixLatency: transformDateObjecToArray(
              sortedChartDataByDate,
              "latencyMs"
            )
          });
        }
        setIsFetchingPathMetrixChartDone(true);
      });
    }
  };

  /**
   * @description call api to get user experience chart details data for side panel
   * @param {object} props is an object that  contains globalFilter, and pathEndpointPair which is a string that contains symEnpointsArray of endpoints
   */
  const getPathUserExperienceMetricData = async props => {
    const pathDetails = JSON.parse(getLocalStorageFlag("pathDetails"));

    if (isFetchingPathQosChartDataError.errorObject) {
      setIsFetchingPathQosUserChartDataError({});
    }

    const result = await ApiService.getAltoPaths({
      windowSize: "1h",
      appClass: getLocalStorageFlag("appGroup"),
      numWindows: 720,
      directedEndpointPair: pathDetails.pathQosPair,
      aggregatedBy: "endpointId",
      experimental: "v2",
      limit: 5000
    });
    if (result.errorObject) {
      setIsFetchingPathQosUserChartDataError(result);
    } else {
      const latestWindowStart = validate(
        props.globalFilter.latestWindowStart,
        "latestWindowStart"
      );
      const latestWindowEnd = validate(
        props.globalFilter.latestWindowEnd,
        "latestWindowEnd"
      );
      const isUserExperienceChart = false;
      const startDate = latestWindowStart;
      const userExperienceChartObject = createChartInitialObject(
        startDate,
        latestWindowEnd,
        1,
        isUserExperienceChart
      );

      result.data.recoPathMetrics.forEach(item => {
        let chartDate = new Date(item.window.start).toISOString();

        if (userExperienceChartObject[chartDate]) {
          userExperienceChartObject[chartDate] = {
            totalNumUsers: item.totalNumUsers,
            recommendationQuality: Math.round(item.recommendationQuality * 100),
            defaultQuality: Math.round(item.defaultQuality * 100),
            fromDateFormated: displayUserExpChartTooltp(item.window.start),
            toDateFormated: displayUserExpChartTooltp(item.window.end),
            fromDate: new Date(item.window.start),
            toDate: new Date(item.window.end),
            date: new Date(item.window.start),
            hasBulletColumnChartData: true
          };
        }
      });

      const chartData = Object.values(userExperienceChartObject);

      setPathUserExprChartData(chartData);
    }

    setIsFetchingPathQosUserChartDataDone(true);
  };

  return {
    pathState: {
      pathUserExprChartData,
      pathMetrix,
      networkQoeMetricsData,
      isFetchingPathQosChartDataDone,
      isFetchingPathQosChartDataError,
      isFetchingPathMetrixChartDone,
      isFetchingPathMetrixChartError,
      isFetchingNetworkQoeMetricsDone,
      isFetchingNetworkQoeMetricsError
    },
    getPathUserExperienceMetricData,
    getPathMetricsChartData
  };
};

export default useAppGroupPerSiteApis;

const prepopulateArrays = length => {
  //initialize the object
  const initializedObject = {
    jitterMs: [],
    lossFrac: [],
    latencyMs: []
  };

  for (let index = 0; index < length; index++) {
    initializedObject.jitterMs.push(0.0014);
    initializedObject.lossFrac.push(0.0014);
    initializedObject.latencyMs.push(0.0014);
  }
  return initializedObject;
};

const createChartInitialObject = (
  latestWindowStart,
  latestWindowEnd,
  numOfResults,
  isPathMetricChartData
) => {
  let pathMetricsDateObject = {};
  let userExperienceChartObject = {};
  let toDate = new Date(latestWindowStart);

  //prepopulate pathMetricsDateObject or userExperienceChartObject objects with dates starting from latestWindowStart if needed
  while (new Date(latestWindowStart) < new Date(latestWindowEnd)) {
    let dateISO = new Date(latestWindowStart).toISOString();
    let fromDate = new Date(latestWindowStart);

    if (isPathMetricChartData) {
      pathMetricsDateObject[dateISO] = {
        ...prepopulateArrays(numOfResults),
        date: new Date(dateISO)
      };
      toDate = new Date(toDate.setHours(toDate.getHours() + 1));
    } else {
      toDate = new Date(toDate.setHours(toDate.getHours() + 1));

      userExperienceChartObject[dateISO] = {
        totalNumUsers: 0,
        recommendationQuality: 0,
        defaultQuality: 0,
        fromDateFormated: displayUserExpChartTooltp(fromDate),
        toDateFormated: displayUserExpChartTooltp(toDate),
        fromDate: fromDate,
        toDate: toDate,
        hasBulletColumnChartData: false,
        date: new Date(fromDate)
      };
    }
    latestWindowStart = toDate;
  }

  return isPathMetricChartData
    ? pathMetricsDateObject
    : userExperienceChartObject;
};
