import React, { useState, useEffect, useRef, useMemo } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import TableStore from "@harbor/elements/utils/table/store";
import useColumns from "@harbor/elements/utils/table/hooks/columns";
import useSelectableRows from "@harbor/elements/utils/table/hooks/selectableRows";
import AppHOC from "../../../generics/AppHOC";
import OverLappingSpinner from "../../../common/Spinner/OverLappingSpinner";
import ErrorComponent from "../../../common/ErrorComponent";
import { useMount } from "../../../utils/genericCommon";
import { downloadAsCSVFile } from "../../../utils/common";
import { setSelectedCircuits } from "../actions";
import { getTableColumns, setCircuitsTableData } from "./tableConfig";
import apiService from "../../../config/api-config";
import i18n from "amdi18n-loader!../../nls/i18n";

const [
  HbrCard,
  HbrInput,
  HbrIcon,
  HbrButton,
  HbrSpinner,
  HbrTable,
  HbrPagination
] = reactWrapper([
  "hbr-card",
  "hbr-input",
  "hbr-icon",
  "hbr-button",
  "hbr-spinner",
  "hbr-table",
  "hbr-pagination"
]);
// default sort column and sort order
const defaultSort = [["total_bps", "desc"]];
// maximum number of selections allowed in the table
const maxSelections = 5;
const tableId = "circuit_dashboard_table_main";

const Table = ({ history, globalFilter }) => {
  const [loader, setLoader] = useState(true);
  const [exportLoader, setExportLoader] = useState(false);
  const [, forceUpdate] = useState(null);
  const mount = useMount();
  const dataRef = useRef({ timestamp: 0, error: null });

  const tableStore = useMemo(() => {
    const selectableRowsHook = useSelectableRows({ selected: [] });
    const columnsHook = useColumns({
      config: ({ store }) => {
        return getTableColumns(store, setSelectedCircuits, history, tableId);
      },
      order: defaultSort
    });
    const store = new TableStore({
      query: { page: 0, pageLength: 30, maxSelections },
      hooks: [columnsHook, selectableRowsHook],
      fetchData: async ({ query, setSource, setCount },) => {
        const timestamp = Date.now();
        const payload = {
          ...globalFilter.globalV4Payload,
          size: query.pageLength,
          offset: query.page,
          sort: Object.fromEntries(query.order)
        };
        if (query.search !== "") payload.search = query.search;
        Object.assign(dataRef.current, { timestamp, error: null });
        setLoader(true);
        const res = await apiService.getCircuitsTableList(payload);
        if (
          mount.mounted === true
          && timestamp === dataRef.current.timestamp
        ) {
          let selections = [];
          let tableData = [];
          let totalCount = 0;
          if (res.errorObject instanceof Object)
            dataRef.current.error = res.errorObject;
          else if (
            res.data instanceof Object && Array.isArray(res.data.data)
          ) {
            const resData = res.data.data;
            tableData = setCircuitsTableData(resData);
            selections = tableData.slice(0, maxSelections).map(({ id }) => id);
            if (Number.isInteger(res.data.count)) totalCount = res.data.count;
          }
          setSource(tableData);
          setCount(totalCount, false);
          selectableRowsHook.setSelected(selections);
          setSelectedCircuits(selections, tableData);
          setLoader(false);
        }
      }
    });
    return store;
  }, [
    globalFilter.timeFilter.current_period[0],
    globalFilter.timeFilter.current_period[1],
    globalFilter.currentTimeStamp,
    globalFilter.selectedSite
  ]);

  useEffect(() => {
    const unsubscribe = tableStore.subscribe(forceUpdate);
    return () => unsubscribe();
  }, [tableStore]);

  // load table data on search
  const onSearch = ({ target: { value } }) => {
    if (value !== search) tableStore.setQuery({ search: value });
  };

  const { source, columns, query, selected, count } = tableStore.getState();
  const { search, pageLength, page } = query;

  const getExportData = async () => {
    const payload = {
      ...globalFilter.globalV4Payload,
      sort: Object.fromEntries(query.order)
    };
    if (query.search !== "") payload.search = query.search;
    setExportLoader(true);
    const res = await apiService.getCircuitsTableList(payload);
    if (mount.mounted === true) {
      if (
        res.errorObject instanceof Object === false
        && res.data instanceof Object && Array.isArray(res.data.data)
      ) {
        const resData = res.data.data;
        const tableData = setCircuitsTableData(resData);
        const cols = columns.slice(0);
        // exclude checkbox column header
        cols.shift();
        const columnTitles = cols.map(item => `"${item.name}"`);
        // get colum header row
        const data = [columnTitles.join(",")];
        // add records
        const columnKeys = cols.map(item => item.prop);
        for (const item of tableData) {
          const keys = [];
          for (const key of columnKeys) keys.push(item[key]);
          data.push(keys.join(","));
        }
        downloadAsCSVFile(data.join("\n"), i18n.circuit.title);
      }
      setExportLoader(false);
    }
  };

  return (
    <HbrCard
      className="widget-container"
      data-testid="circuit_circuits_table"
      container
    >
      <div className="table-container hbr-css__layout-col-md">
        {loader === true && <OverLappingSpinner />}
        <div className="hbr-css__layout-col-md">
          <div className="hbr-css__text-heading-lg-bold">
            {i18n.circuit.title}
          </div>
          <div
            className="hbr-css__layout-row-xs hbr-css__layout-justify-between"
          >
            <div className="hbr-css__layout-row-xs">
              <HbrInput
                placeholder="Search"
                clearable
                disabled={loader}
                value={search}
                onHbr-clear={onSearch}
                onkeydown={event => {
                  if (event.code === "Enter") onSearch(event);
                }}
                onHbr-blur={onSearch}
              >
                <HbrIcon
                  slot="prefix"
                  name="magnifying-glass"
                  sentiment="neutral"
                />
              </HbrInput>
              <div className="hbr-css__filter-description">
                {`${count}${i18n.circuit.ctCountText}`}
              </div>
              <div className="hbr-css__filter-description">
                {`${selected.length}${i18n.circuit.ctSelectCountText}`}
              </div>
            </div>
            <HbrButton
              variant="outline"
              onClick={getExportData}
              disabled={loader === true || exportLoader === true}
            >
              {exportLoader === true ? (
                <HbrSpinner slot="prefix" />
              ) : (
                <HbrIcon slot="prefix" name="upload-simple" />
              )}
              {i18n.export}
            </HbrButton>
          </div>
        </div>
        <HbrTable
          id={tableId}
          data-testid={tableId}
          columns={columns}
          readonly
          resize
          serverSide
          externalSorting
          canFocus={false}
          useClipboard={false}
          onHbr-before-sort={event => {
            const { column, order } = event.detail;
            const columnsHook = tableStore.getHook("columns");
            if (order) columnsHook.sort({ order: [[column.id, order]] });
            else if (column.id === defaultSort[0][0])
              columnsHook.sort({ order: [[column.id, "asc"]] });
            else columnsHook.sort({ order: defaultSort });
          }}
          source={source}
        >
          {count === 0 && loader === false && (
            <div slot="empty" className="hbr-css__table-empty-state">
              {dataRef.current.error === null ? (
                <>
                  <HbrIcon size="75px" sentiment="neutral" name="empty-state" />
                  <span className="hbr-css__text-secondary-sm">
                    {i18n.c360.tEmpty}
                  </span>
                </>
              ) : (
                <ErrorComponent
                  {...dataRef.current.error}
                  className="small-dashlet-error"
                  width="110px"
                />
              )}
            </div>
          )}
        </HbrTable>
        <HbrPagination
          orderArray={[1, 2, 0]}
          showAll
          disabled={loader}
          tableStore={tableStore}
          pageSize={pageLength}
          itemCount={count}
          page={page}
          size="small"
          onHbr-page-changed={event => {
            const index = Number.isInteger(event.detail) ? event.detail : 0;
            tableStore.setQuery({ page: index });
          }}
          onHbr-size-changed={event => {
            tableStore.setQuery({ pageLength: event.detail.pageSize, page: 0 })
          }}
        />
      </div>
    </HbrCard>
  );
};

Table.propTypes = {
  history: PropTypes.object.isRequired,
  globalFilter: PropTypes.object.isRequired
};

export default AppHOC(Table);
