import axios from "axios";
import { circuitUrls } from "../../apis/apiConstants";
import { createGenericErrorObject } from "../../utils/common";

export const ACTIONS = {
  GET_PREDICTIVE_TAB: "GET_PREDICTIVE_TAB",
  GET_BANDWIDTH_FORECAST_CIRCUIT: "GET_BANDWIDTH_FORECAST_CIRCUIT"
};

export const getFabricCircuits = async () => {
  try {
    return await axios.get(circuitUrls.fabricCircuits);
  } catch (e) {
    return createGenericErrorObject(e);
  }
};

export const setBandwidthForecastCircuit = bandwidthProps => dispatch => {
  dispatch({
    type: ACTIONS.GET_BANDWIDTH_FORECAST_CIRCUIT,
    payload: bandwidthProps
  });
};

export const setPredictiveTab = tab => dispatch => {
  dispatch({
    type: ACTIONS.GET_PREDICTIVE_TAB,
    payload: tab
  });
};
