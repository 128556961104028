import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import React, { useEffect, useState, Suspense } from "react";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

//components
import AppHoc from "../../../../generics/AppHOC";
import GeoMapComponentMini from "./GeoMapComponentMini";
import Spinner from "../../../../common/Spinner";
import reduxContext from "../../reducer";
import PathRecommendation from "../../../../common/PathRecommendation";

//lazy loading
const NoDataAvailable = React.lazy(() =>
  import("../../../../common/NoDataAvailable")
);

//utils
import usePredictiveNetworksHooks from "../../../insights/usePredictiveNetworkHooks";
import { validateProps, formatGains } from "../../../../utils/waniCommon";
import {
  getLocalStorageFlag,
  setLocalStorageFlag,
  removeLocalStorageFlag,
  getStoredCurrentSite
} from "../../../../utils/common";
import { BASE_URL } from "../../../../apis/apiConstants";

//i18nMessageBundle
import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";

//style
import css from "../../insightsAppMagneticStyle.less";

const [HbrCard, HbrSelect, HbrOption, HbrIcon] = reactWrapper([
  "hbr-card",
  "hbr-select",
  "hbr-option",
  "hbr-icon"
]);

const mapStateToProps = state => {
  return {
    predictiveNetworks: state.vanalytics.predictiveNetworks
  };
};

const InsightsHeroBar = props => {
  const { selectedOverlay, selectedSite, onboardingStatus } = validateProps(
    props.globalFilter
  );
  const { setSelectedSite } = props;
  const { state, getEndpointsDetails } = usePredictiveNetworksHooks();
  const [appGroup, setAppGroup] = useState(getLocalStorageFlag("appGroup"));
  const [isLoading, setIsLoading] = useState(true);
  let isCalledOnce = true;
  const isSingleSite = parseInt(selectedSite) > 0;
  const isDeviceViewPage =
    location.pathname ===
    `${BASE_URL}/predictivenetworks/applicationinformation/device`;
  const isSiteViewPage =
    location.pathname === `${BASE_URL}/predictivenetworks/applicationinformation/site`;
  const isAllSiteViewPage =
    location.pathname === `${BASE_URL}/predictivenetworks/applicationinformation`;
  const appClassLabels = JSON.parse(getLocalStorageFlag("activeAppClasses"));

  const appGroupList =
    state?.recommedationsAppGroup &&
    Object.keys(state.recommedationsAppGroup).map(appGroup => ({
      value: appGroup,
      label: appClassLabels[appGroup]
    }));

  useEffect(() => {
    window.scrollTo(0, 0);
    if (onboardingStatus === "ACTIVE") {
      const siteWidget = document.querySelector("[data-cy=siteDropDown]");
      if (siteWidget) {
        siteWidget.style.visibility = "hidden";
      }

      if (!isSingleSite) {
        removeLocalStorageFlag("pathDetails");
        setSelectedSite(-1);
      } else if (!isDeviceViewPage) {
        setSelectedSite(selectedSite);
      }

      setIsLoading(true);
    }
  }, []);

  useEffect(() => {
    const siteId = getStoredCurrentSite();

    if (onboardingStatus === "ACTIVE") {
      if (
        !state.isFetchingRecommedationsDone &&
        props.globalFilter.appGroup == "all-apps-group"
      ) {
        isCalledOnce = false;

        getEndpointsDetails(selectedOverlay, siteId);
      } else if (
        !state.isFetchingRecommedationsDone &&
        props.globalFilter.appGroup !== "all-apps-group"
      ) {
        isCalledOnce = false;

        getEndpointsDetails(selectedOverlay, siteId);
      }
    } else {
      setIsLoading(false);
    }
  }, [props.globalFilter.appGroup]);

  useEffect(() => {
    const siteId = getStoredCurrentSite();

    if (onboardingStatus === "ACTIVE") {
      const isSiteIdUndefined =
        state.endpointDetails?.location?.siteId == undefined;
      const isSiteSelectedDropdown =
        state.endpointDetails?.location?.siteId !== selectedSite;
      const isPrevSiteSelectedAllSites =
        state.endpointDetails?.endpoint?.length == 1;

      if (
        (isCalledOnce && siteId !== selectedSite) ||
        (!isSiteIdUndefined && isSiteSelectedDropdown) ||
        isPrevSiteSelectedAllSites
      ) {
        getEndpointsDetails(selectedOverlay, selectedSite);
      }
    } else {
      setIsLoading(false);
    }
  }, [selectedSite]);

  useEffect(() => {
    const isLoaded = isDeviceViewPage && state.pathRecommendation && isLoading;
    const hasRecommedationResponse =
      state.isFetchingRecommedationsDone ||
      state.isFetchingRecommedationsError.errorObject;

    if (
      isLoaded ||
      ((isSiteViewPage || isAllSiteViewPage || isDeviceViewPage) &&
        isLoading &&
        hasRecommedationResponse)
    ) {
      setIsLoading(false);
    }
  }, [
    state.isFetchingRecommedationsDone,
    state.isFetchingRecommedationsError,
    state.pathRecommendation
  ]);

  const onAppGroupChange = event => {
    setLocalStorageFlag("appGroup", event.currentTarget.value);
    setAppGroup(event.currentTarget.value);
    props.setAltoProps({ appGroup: event.currentTarget.value });
  };

  if (isLoading) {
    return (
      <div className="spinner-all-app-cards" data-cy="spinner-all-app-cards">
        <Spinner />
      </div>
    );
  } else if (onboardingStatus !== "ACTIVE") {
    return (
      <div className={css["insights-filter"]} data-cy="insightsFilter">
        <Suspense fallback={<Spinner />}>
          <div className="heroNodata">
            <NoDataAvailable text={i18nMessageBundle.syncronizedChartNoData} />
          </div>
        </Suspense>
      </div>
    );
  } else {
    return (
      <div className={css["insights-filter"]}>
        <div className="back-to-predictive-networks">
          <span
            className="back-icon"
            onClick={() => props.history.push(`${BASE_URL}/predictivenetworks`)}
          >
            <HbrIcon name="arrow-left"></HbrIcon>
          </span>
          <span
            className="back-btn hbr-type-body3"
            onClick={() => props.history.push(`${BASE_URL}/predictivenetworks`)}
          >
            {i18nMessageBundle.predictiveNetworks.predictiveNetworks}
          </span>
        </div>
        <div className="app-info-title">
          <div className="hbr-type-h1 title-label">
            {i18nMessageBundle.predictiveNetworks.appInfoLabel}
          </div>
          <HbrSelect
            className="select-dropdown-app-group"
            value={appGroup}
            onHbr-change={onAppGroupChange}
          >
            {appGroupList.map(option => (
              <HbrOption key={option.label} value={option.value}>
                {option.label}
              </HbrOption>
            ))}
          </HbrSelect>
        </div>
        <div className="hero-stats">
          {isSingleSite &&
            state.isFetchingRecommedationsDone &&
            state.endpointDetails?.location?.longitude && (
              <HbrCard className="map-container-app360" data-cy="stats-map">
                <GeoMapComponentMini
                  longitude={state.endpointDetails.location.longitude}
                  latitude={state.endpointDetails.location.latitude}
                />
              </HbrCard>
            )}

          {isSingleSite && state.isFetchingRecommedationsDone && (
            <HbrCard className="stats-location" data-cy="stats-location">
              <div className="stats-location-view">
                <div>
                  {i18nMessageBundle.predictiveNetworks.heroBarSiteSemiLabel}
                </div>
                <div>{selectedSite}</div>
                <div>
                  {i18nMessageBundle.predictiveNetworks.heroBarLocationLabel}
                </div>
                <div>
                  {" "}
                  {`${state.endpointDetails?.location?.country} · ${state.endpointDetails?.location?.city}`}
                </div>
              </div>
            </HbrCard>
          )}
          <div className="hero-stats-summary">
            <HbrCard data-cy="hero-stats-summary-card-current">
              <div className="hbr-type-body3 card-title">
                {i18nMessageBundle.predictiveNetworks.heroBarCurPath}
              </div>
              <div className="hbr-type-body1 card-value">
                {state.recommedationsAppGroup[appGroup]?.defaultQuality}
              </div>
            </HbrCard>
          </div>
          <div className="hero-stats-summary">
            <HbrCard data-cy="hero-stats-summary-card-recomended">
              <div className="hbr-type-body3 card-title">
                {i18nMessageBundle.predictiveNetworks.heroBarRecomPath}
              </div>
              <div className="hbr-type-body1 card-value">
                {state.recommedationsAppGroup[appGroup]?.recommendationQuality}
                <span>
                  {formatGains(state.recommedationsAppGroup[appGroup].gains)}
                </span>
              </div>
            </HbrCard>
          </div>
          <div className="hero-stats-summary">
            <HbrCard data-cy="hero-stats-summary-card-impact">
              <div className="hbr-type-body3 card-title">
                {i18nMessageBundle.predictiveNetworks.heroBarImactedUserLabel}
              </div>
              <div className="hbr-type-body1 card-value">
                {isSingleSite && state.isFetchingRecommedationsDone
                  ? state.recommedationsAppGroup[appGroup]?.impactedUsers
                  : state.recommedationsAppGroup[appGroup]?.impactedUsers}
              </div>
            </HbrCard>
          </div>
          {isSingleSite && state.isFetchingRecommedationsDone && (
            <div className="stats-recom-path-metric">
              {state.pathRecommendation ? (
                <PathRecommendation
                  {...state.pathRecommendation[0]?.metadata}
                />
              ) : (
                  <PathRecommendation
                    {...state.endpointDetails.pathsRecomendations}
                  />
                )}
            </div>
          )}
        </div>
      </div>
    );
  }
};
InsightsHeroBar.propTypes = {
  globalFilter: PropTypes.object,
  setSelectedSite: PropTypes.func,
  setAltoProps: PropTypes.func,
  history: PropTypes.object
};

export default reduxContext.withProvider(
  connect(mapStateToProps)(AppHoc(InsightsHeroBar))
);
