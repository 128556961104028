import i18n from "amdi18n-loader!../../../nls/i18n";
import { BASE_URL } from "../../../../apis/apiConstants";
import { getColor } from "../../../../utils/colors";
import { downloadAsCSVFile, setLocalStorageFlag } from "../../../../utils/common";
import { formatBytes, formatNumberNDecimalPlaces } from "../../../../utils/format";

export const getColumns = (history, setSelectedSite, site) => [
  {
    data: "application",
    title: i18n.c360.atApp,
    name: "app",
    render: data => (data ?
      `<div class="flex-items"><div class="table-link">${data}</div><div>`
      : data),
    createdCell: (td, data) => {
      const linkElement = td.querySelector(".table-link");
      if (linkElement)
        linkElement.addEventListener("click", e => {
          e.preventDefault();
          setLocalStorageFlag("currentSite", site);
          setSelectedSite(site);
          history.push(`${BASE_URL}/Dashboards/${data}`);
        });
    }
  },
  {
    data: "vqoe_score",
    title: i18n.c360.atQoE,
    name: "qoe",
    render: data => {
        if (!data) return 0;
        let qoe_score = formatNumberNDecimalPlaces(data, 1);
       let color = getColor(qoe_score, "qoe");
        return `<div style="color: ${color}">${qoe_score}</div>`;
    }
  },
  {
    data: "application_family_long_name",
    title: i18n.c360.atFamily,
    name: "fy",
  },
  {
    data: "total_octets", title: i18n.c360.atUsage, name: "usg",
    render: data => {
        if (data === null || data === undefined || data === "-") return "-";
       return formatBytes(data);
    }
  },
  {
    data: "loss", title: i18n.c360.atLoss, name: "ls", type: "num",
    render: data => {
        if (!data) return 0;
        return formatNumberNDecimalPlaces(data, 1);
      }  },
  {
    data: "latency", title: i18n.c360.atLatency, name: "ly", type: "num",
    render: data => {
        if (!data) return 0;
        return formatNumberNDecimalPlaces(data, 1);
      }  },
  {
    data: "jitter", title: i18n.c360.atJitter, name: "jr", type: "num",
    render: data => {
        if (!data) return 0;
        return formatNumberNDecimalPlaces(data, 1);
      }  },
];

export const getTools = (getExportData) => {

  return [
    // table title
    {
      ref: { name: "title", config: { text: i18n.c360.atTitle, count: true } }
    },
    // search
    {
      ref: { name: "search", config: { inputPlaceholder: i18n.c360.tSearch } },
      level: "secondary",
      justify: "start"
    },
    {
      ref: {
        name: "export",
        config: {
          title: i18n.c360.atTitle,
          action: async dataTable => {
            const res = await getExportData(dataTable);
            if (
              res.errorObject instanceof Object === false
              && res.data instanceof Object
              && Array.isArray(res.data.data)
            ) {
              const exportData = res.data.data;
              const columns =
                dataTable.props.columns.filter(item => item.visible !== false);
              if (dataTable.props.rowSelect === true) columns.shift();
              const data = [columns.map(item => `"${item.title}"`).join(",")];
              for (const item of exportData) {
                data.push([
                  `"${item.application}"`,
                  `"${item.vqoe_score}"`,
                  `"${item.application_family_long_name}"`,
                  `"${item.total_octets}"`,
                  `"${item.loss}"`,
                  `"${item.latency}"`,
                  `"${item.jitter}"`,
                ].join(","));
              }
              downloadAsCSVFile(data.join("\n"), i18n.c360.atTitle);
            }
          }
        }
      },
      level: "secondary",
      justify: "end"
    }
  ]
};

export const defaultTableProps = (history, setSelectedSite, site) => {
  return {
    id: "c360-at",
    dtOptions: {
      stateSave: false,
      scrollX: true,
      scrollY: 300,
      order: [[3, "desc"]],
      language: {
        zeroRecords: i18n.c360.tEmpty,
        emptyTable: i18n.c360.tEmpty
      }
    },
    serverSide: true,
    columns: getColumns(history, setSelectedSite, site),
    dataTableClasses: "nowrap"
  }
};

