import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

//component
import reduxContext from "../../reducer";
import AppHoc from "../../../../generics/AppHOC";

//i18nMessageBundle
import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";

//style
import css from "../../insightsAppMagneticStyle.less";

//utils
import {
  setLocalStorageFlag,
  isDeviceInfoPage,
  getLocalStorageFlag,
  removeLocalStorageFlag
} from "../../../../utils/common";
import { BASE_URL } from "../../../../apis/apiConstants";

const mapStateToProps = state => {
  return {
    predictiveNetworks: state.vanalytics.predictiveNetworks
  };
};

const [HbrBreadcrumb, HbrBreadcrumbItem] = reactWrapper([
  "hbr-breadcrumb",
  "hbr-breadcrumb-item"
]);

const deviceInfoConfig = {
  title: i18nMessageBundle.predictiveNetworks.deviceInfoPageTitle,
  crumbs: [
    i18nMessageBundle.predictiveNetworks.predictiveNetworks,
    i18nMessageBundle.predictiveNetworks.appInfoLabel,
    i18nMessageBundle.predictiveNetworks.siteInfoPageTitle
  ]
};
const siteInfoCofig = {
  title: i18nMessageBundle.predictiveNetworks.siteInfoPageTitle,
  crumbs: [
    i18nMessageBundle.predictiveNetworks.predictiveNetworks,
    i18nMessageBundle.predictiveNetworks.appInfoLabel
  ]
};

const HeaderBar = props => {
  const config = isDeviceInfoPage() ? deviceInfoConfig : siteInfoCofig;
  const appClassLabels = JSON.parse(getLocalStorageFlag("activeAppClasses"));

  return (
    <div className={css["header-bar-network-page"]}>
      <HbrBreadcrumb>
        {config.crumbs.map(crumb => (
          <HbrBreadcrumbItem
            onClick={() => {
              if (
                crumb.includes(
                  i18nMessageBundle.predictiveNetworks.predictiveNetworks
                )
              ) {
                props.history.push(`${BASE_URL}/predictivenetworks`);
              } else if (
                crumb.includes(
                  i18nMessageBundle.predictiveNetworks.appInfoLabel
                )
              ) {
                setLocalStorageFlag("actionCardSite", -1);
                props.history.push(
                  `${BASE_URL}/predictivenetworks/applicationinformation`
                );
              } else {
                removeLocalStorageFlag("pathDetails");
                props.history.push(
                  `${BASE_URL}/predictivenetworks/applicationinformation/site`
                );
              }
            }}
            key={crumb}
          >
            {crumb}
          </HbrBreadcrumbItem>
        ))}
      </HbrBreadcrumb>
      <div className="network-site-info hbr-type-h2">
        {config.title}
        <span className="app-group-label hbr-type-body3">{appClassLabels[getLocalStorageFlag("appGroup")]}</span>
      </div>
    </div>
  );
};

HeaderBar.propTypes = {
  history: PropTypes.object
};
export default reduxContext.withProvider(
  connect(mapStateToProps)(AppHoc(HeaderBar))
);
