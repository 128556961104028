import axios from "axios";
import { createGenericErrorObject } from "../../utils/common";
import { circuitUrls, c360Urls } from "../../apis/apiConstants";
import { getFormattedUTCDate } from "../../utils/displayTime";

const bandwidthChartPayloadELT = {
  aggregation: {
    field: [
      { property: "circuit_name", sequence: "1" },
      { property: "entry_ts", sequence: "2" }
    ],
    metrics: [
      { property: "rx_octets", type: "sum" },
      { property: "tx_octets", type: "sum" }
    ]
  },
  fields: ["circuit_name", "entry_ts"],
  query: {
    condition: "AND",
    rules: [
      {
        field: "entry_ts", operator: "between", type: "string", value: ["", ""]
      },
      { field: "circuit_name", operator: "in", type: "string", value: [""] }
    ]
  },
  size: "16000",
  sort: [{ order: "asc", property: "entry_ts" }]
};

const bandwidthChartPayloadHourly = {
  time_frame: "time_frame",
  entry_ts: "entry_ts",
  circuit_name: "circuit_name",
  sort: {
    entry_ts: "asc"
  }
};

const appsPerformanceTablePayload = {
  aggregation: {
    field: [
      { property: "application", sequence: "1" },
      { property: "application_family_long_name", sequence: "2" },
      { property: "application_class", sequence: "3" }
    ],
    metrics: [
      { property: "vqoe_score", type: "avg" },
      { property: "latency", type: "avg" },
      { property: "loss_percentage", type: "avg" },
      { property: "jitter", type: "avg" }
    ]
  },
  fields: ["application", "application_family_long_name", "application_class"],
  size: "4000",
  query: {
    condition: "AND",
    rules: [
      {
        field: "entry_time",
        operator: "between",
        type: "date",
        value: [0, 0]
      },
      {
        field: "device_host_name",
        operator: "in",
        type: "string",
        value: [""]
      },
      {
        field: "local_color",
        operator: "in",
        type: "string",
        value: [""]
      }
    ]
  },
  sort: [{ order: "asc", property: "application" }]
};

const appsUsageTablePayload = {
  current_period: [],
  filter: {
    circuit_name: ""
  }
};

const tunnelsTablePayload = {
  aggregation: {
    field: [{ property: "tunnel_name", sequence: "1" }],
    metrics: [
      { property: "rx_octets", type: "sum" },
      { property: "tx_octets", type: "sum" },
      { property: "site_id", type: "max" },
      { property: "loss_percentage", type: "avg" },
      { property: "jitter", type: "avg" },
      { property: "latency", type: "avg" },
      { property: "local_system_ip", type: "max" },
      { property: "remote_system_ip", type: "max" },
      { property: "local_color", type: "max" },
      { property: "remote_color", type: "max" }
    ]
  },
  fields: ["tunnel_name"],
  size: "16000",
  query: {
    condition: "AND",
    rules: [
      {
        field: "entry_time", operator: "between", type: "date", value: [0, 0]
      },
      {
        field: "device_host_name", operator: "in", type: "string", value: [""]
      },
      { field: "vdevice_id", operator: "in", type: "string", value: [""] },
      { field: "local_color", operator: "in", type: "string", value: [""] }
    ]
  }
};

const flowsTablePayload = {
  current_period: [],
  filter: {
    circuit_name: ""
  }
};

export const getRxTxChartData = async (globalV4Payload, circuitData) => {
  try {
      bandwidthChartPayloadHourly.time_frame = globalV4Payload.time_frame
      bandwidthChartPayloadHourly.entry_ts = globalV4Payload.entry_ts
      bandwidthChartPayloadHourly.circuit_name =
        `${circuitData.vdevice_id}:${circuitData.color}`;
        bandwidthChartPayloadHourly.site_id = circuitData.site_id;
        return await axios.post(c360Urls.bandwidthAvgElt, bandwidthChartPayloadHourly);
  } catch (e) {
    return createGenericErrorObject(e);
  }
};

export const getBFChartData = async (timeRange, circuitData) => {
  try {
    bandwidthChartPayloadELT.query.rules[0].value = [
      getFormattedUTCDate(timeRange[0]),
      getFormattedUTCDate(timeRange[1])
    ];
    bandwidthChartPayloadELT.query.rules[1].value =
      [`${circuitData.vdevice_id}:${circuitData.color}`];
    return await axios.post(circuitUrls.bandwidthAvgEltDaily,
      bandwidthChartPayloadELT);
  } catch (e) {
    return createGenericErrorObject(e);
  }
};

export const getAppsUsageChartData = async (globalV4Payload, circuitData) => {
  try {
    const payload = {
      ...globalV4Payload,
      circuit_name: `${circuitData.vdevice_id}:${circuitData.color}`,
      site_id: circuitData.site_id,
      sort: { entry_ts: "asc" },
      usage_rank: [1, 2, 3, 4, 5]
    };
    return await axios.post(c360Urls.topAppsUsage, payload);
  } catch (e) {
    return createGenericErrorObject(e);
  }
};

export const getTunnelUsageChartData = async (globalV4Payload, circuit) => {
  try {
    const payload = {
      ...globalV4Payload,
      vdevice_id: circuit.vdevice_id,
      local_color: circuit.color,
      site_id: circuit.site_id,
      sort: { entry_ts: "asc" },
      usage_rank: [1, 2, 3, 4, 5]
    };
    return await axios.post(c360Urls.topTunnelsUsage, payload);
  } catch (e) {
    return createGenericErrorObject(e);
  }
};

export const getDiaVsTunnelChartData = async (globalV4Payload, circuitData) => {
  try {
    const payload = {
      ...globalV4Payload,
      circuit_name: `${circuitData.vdevice_id}:${circuitData.color}`,
      site_id: circuitData.site_id,
      sort: { entry_ts: "asc" }
    };
    return await axios.post(c360Urls.diaVsTunnelUsage, payload);
  } catch (e) {
    return createGenericErrorObject(e);
  }
};

export const getAppsTableData = async (timeRange, circuitData) => {
  appsPerformanceTablePayload.query.rules[0].value = timeRange;
  appsPerformanceTablePayload.query.rules[1].value =
    [circuitData.device_host_name];
  appsPerformanceTablePayload.query.rules[2].value = [circuitData.color];

  appsUsageTablePayload.current_period = timeRange;
  appsUsageTablePayload.filter.circuit_name = `${circuitData.vdevice_id}:${circuitData.color}`;
  try {
    return await Promise.all([
      axios.post(c360Urls.appsPerformanceTable, appsPerformanceTablePayload),
      axios.post(c360Urls.appsUsageTable, appsUsageTablePayload)
    ]);
  } catch (e) {
    return createGenericErrorObject(e);
  }
};

export const getTunnelsTableData = async (timeRange, circuitData) => {
  tunnelsTablePayload.query.rules[0].value = timeRange;
  tunnelsTablePayload.query.rules[1].value = [circuitData.device_host_name];
  tunnelsTablePayload.query.rules[2].value = [circuitData.vdevice_id];
  tunnelsTablePayload.query.rules[3].value = [circuitData.color];
  try {
    return await axios.post(c360Urls.tunnelsTable, tunnelsTablePayload);
  } catch (e) {
    return createGenericErrorObject(e);
  }
};

export const getFlowsTableData = async (timeRange, circuitData) => {
  flowsTablePayload.current_period = timeRange;
  flowsTablePayload.filter.circuit_name = `${circuitData.vdevice_id}:${circuitData.color}`;

  try {
    return await axios.post(c360Urls.flowsTable, flowsTablePayload);
  } catch (e) {
    return createGenericErrorObject(e);
  }
};
