import React, { useState, useEffect, useRef, useCallback } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import reduxContext from "../reducer";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import AppHOC from "../../../generics/AppHOC";
import Spinner from "../../../common/Spinner";
import ErrorComponent from "../../../common/ErrorComponent";
import NoDataAvailable from "../../../common/NoDataAvailable";
import StackedMultiLineChart from "../../../common/StackedMultiLineChart";
import { useMount } from "../../../utils/genericCommon";
import { getBandwidthData } from "../actions";
import {
  chartTypes,
  chartTypesObj,
  defaultData,
  getChartData,
  setChartData
} from "./util";
import i18n from "amdi18n-loader!../../nls/i18n";
import css from "../circuitsMagneticStyle.less";

const [HbrCard, HbrTooltip, HbrIcon, HbrSelect, HbrOption] = reactWrapper([
  "hbr-card",
  "hbr-tooltip",
  "hbr-icon",
  "hbr-select",
  "hbr-option"
]);

const View = ({ globalFilter, circuits }) => {
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [isTypeChange, setIsTypeChange] = useState(true);
  const [chartType, setChartType] = useState(chartTypes[0].value);
  const mount = useMount();
  const dataRef = useRef({
    ...defaultData,
    currentCircuits: [],
    type: chartType
  });
  const chartRef = useRef(null);

  useEffect(() => {
    Object.assign(dataRef.current, defaultData);
    setLoader(true);
  }, [
    globalFilter.timeFilter.current_period[0],
    globalFilter.timeFilter.current_period[1],
    globalFilter.selectedSite,
    globalFilter.currentTimeStamp
  ]);

  useEffect(() => {
    if (mount.initMount === false) {
      const prevCircuitNames = dataRef.current.currentCircuits
        .map(item => item.circuit_display_name);
      dataRef.current.currentCircuits = circuits;
      const circuitNames = circuits.map(item => item.circuit_display_name);
      // validation to skip api call
      if (
        circuits.length > 0 && circuits.length < prevCircuitNames.length
        && circuitNames.every(item => prevCircuitNames.includes(item))
      ) {
        if (loader === false && data.length > 0 && chartRef.current !== null) {
          // find series to remove
          const seriesIndex = chartRef.current.series.values.findIndex(
            item => circuitNames.includes(item.name) === false
          );
          if (seriesIndex !== -1) {
            // remove series
            chartRef.current.series.removeIndex(seriesIndex).dispose();
            // update vmanage configuration status
            const chartData = getChartData(globalFilter, dataRef.current);
            dataRef.current.configStatus = chartData.configStatus;
            // set chart data
            setData(chartData.data);
          }
        }
      }
      else {
        const timestamp = Date.now();
        const currentData = { ...defaultData, timestamp, circuits };
        Object.assign(dataRef.current, currentData);
        setData([]);
        if (circuits.length > 0) {
          // load chart data using api
          setLoader(true);
          loadData(globalFilter, circuits, timestamp);
        }
        else {
          // hide loader when there are no circuits
          dataRef.current.loader = false;
          setLoader(false);
        }
      }
    }
    else mount.initMount = false;
  }, [circuits]);

  const loadData = useCallback(async (gFilter, queryData, timestamp) => {
    const res = await getBandwidthData(gFilter.globalV4Payload, queryData);
    if (mount.mounted === true && timestamp === dataRef.current.timestamp) {
      if (res.errorObject instanceof Object)
        dataRef.current.error = res.errorObject;
      else if (
        res.data instanceof Object && Array.isArray(res.data.data)
        && res.data.data.length > 0
      ) {
        // set api data
        dataRef.current.data = setChartData(res.data.data, queryData);
        // get chart data
        const chartData = getChartData(gFilter, dataRef.current);
        // set vmanage configuration status
        dataRef.current.configStatus = chartData.configStatus;
        // set chart data
        setData(chartData.data);
      }
      dataRef.current.loader = false;
      setLoader(false);
    }
  }, []);

  const onChange = useCallback(e => {
    const value = e.target.value;
    dataRef.current.type = value;
    // hide chart
    setIsTypeChange(false);
    setChartType(value);
    // set chart data
    if (dataRef.current.loader === false)
      setData(getChartData(globalFilter, dataRef.current).data);
    // show chart
    setTimeout(() => mount.mounted === true && setIsTypeChange(true));
  }, [globalFilter.globalV4Payload]);

  const chartCallback = chart => chartRef.current = chart;

  return (
    <HbrCard
      className={`${css.bandwidth} widget-container`}
      data-testid="circuit_bandwidth_chart"
      container
    >
      <div className="flex-column flex-main-content">
        <div className="flex-items title-block">
          <div className="flex-items-center hbr-type-h2">
            {i18n.circuit.bTitle}
            <HbrTooltip content={i18n.circuit.bDefinition} placement="top">
              <HbrIcon name="info" sentiment="neutral" />
            </HbrTooltip>
          </div>
          <HbrSelect
            name="bandwidth"
            size="small"
            value={chartType}
            onHbr-change={onChange}
          >
            {chartTypes.map(item => (
              <HbrOption key={item.value} value={item.value}>
                {item.label}
              </HbrOption>
            ))}
          </HbrSelect>
        </div>
        <div className="flex-main-content flex-items-center">
          {loader === true ? (
            <Spinner />
          ) : dataRef.current.error !== null ? (
            <ErrorComponent
              {...dataRef.current.error}
              className="small-dashlet-error"
              width="220px"
            />
          ) : dataRef.current.data.length === 0 ? (
            <NoDataAvailable />
          ) : isTypeChange === true && (
            <StackedMultiLineChart
              id="circuit-bw"
              scrollbar
              scrollBarInitialZoom
              isMultiLineTip
              stacked={false}
              areaChart={false}
              i18n={i18n}
              label={
                chartTypesObj[chartType] === i18n.circuit.rxTx
                  ? i18n.circuit.bYLabel
                  : chartTypesObj[chartType]
              }
              chartType={chartTypesObj[chartType]}
              data={data}
              min={globalFilter.timeFilter.current_period[0]}
              max={globalFilter.timeFilter.current_period[1]}
              order={circuits.map(item => item.circuit_display_name)}
              configurationStatus={dataRef.current.configStatus[chartType]}
              callbackFromParent={chartCallback}
            />
          )}
        </div>
      </div>
    </HbrCard>
  );
};

View.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  circuits: PropTypes.arrayOf(PropTypes.object).isRequired
};

const mapStateToProps = state => ({
  circuits: state.vanalytics.circuits.selectedCircuits
});

export default reduxContext.withProvider(
  connect(mapStateToProps)(AppHOC(View))
);
