import Authorization from "../../generics/authorization";
import FilterView from "./filterView";
import BandwidthView from "./rxtxView";
import DiaVsTunnel from "./diaVsTunnel";
import TabView from "./tabView";

const xValue = 0;
const wValue = 12;

const pageProps = {
  filterDashlets: [{
    component: FilterView,
    name: "summary",
    layout: { x: xValue, y: 0, w: wValue, h: 3.6 }
  }],
  dashlets: [
    {
      component: BandwidthView,
      name: "bandwidth",
      layout: { x: xValue, y: 0, w: wValue, h: 28.75 }
    },
    {
      component: DiaVsTunnel,
      name: "dia_vs_tunnel",
      layout: { x: xValue, y: 28.75, w: wValue, h: 28.75 }
    },
    {
      component: TabView,
      name: "tabs",
      layout: { x: xValue, y: 57.5, w: wValue, h: 40 }
    }
  ]
};

const Page = () => (<Authorization pageProps={pageProps} />);

export default Page;
