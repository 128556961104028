import React, { useState, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import AppHOC from "../../../generics/AppHOC";
import Spinner from "../../../common/Spinner";
import ErrorComponent from "../../../common/ErrorComponent";
import NoDataAvailable from "../../../common/NoDataAvailable";
import DonutChart from "../../../common/donutChart";
import SideBar from "./SideBar";
import { formatBytes } from "../../../utils/format";
import i18n from "../../nls/root/i18n";
import css from "../circuitsMagneticStyle.less";
import { chartPrimaryColors } from "../../../utils/colors";

const [HbrButton, HbrDrawer] = reactWrapper(["hbr-button", "hbr-drawer"]);
const defaultTableData = [
  {
    id: i18n.circuit.ddcDia,
    color: chartPrimaryColors[0],
    value: 0,
    str: "0 Bytes",
    percent: 0
  },
  {
    id: i18n.circuit.tunnel,
    color: chartPrimaryColors[1],
    value: 0,
    str: "0 Bytes",
    percent: 0
  }
];

const View = ({ globalFilter, height, isReport, reportData }) => {
  const [loader, setLoader] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const dataRef = useRef({
    error: null,
    internalText: ["0", "Bytes"]
  });

  useEffect(() => {
    const circuitsData = isReport
      ? reportData
      : globalFilter.circuitsMasterTable;
    if (circuitsData.loader === true) setLoader(true);
    else {
      dataRef.current.error = circuitsData.error;

      if (dataRef.current.error !== null) setLoader(false);
      else {
        const siteId = globalFilter.selectedSite;
        let usageData = [];
        let diaUsage = 0;
        let tunnelUsage = 0;
        let data = circuitsData.data;
        if (siteId !== -1) data = data.filter(item => item.site_id === siteId);
        // get total dia usage and tunnel usage values
        if (data.length > 0) {
          for (const item of data) {
            if (Number.isFinite(item.dia_usage)) diaUsage += item.dia_usage;
            if (Number.isFinite(item.tunnel_usage))
              tunnelUsage += item.tunnel_usage;
          }
          const totalUsage = diaUsage + tunnelUsage;

          if (totalUsage > 0) {
            // set usage data
            usageData[0] = { ...defaultTableData[0] };
            if (diaUsage > 0) {
              usageData[0].value = diaUsage;
              usageData[0].str = formatBytes(diaUsage);
              usageData[0].percent = parseFloat(
                ((diaUsage / totalUsage) * 100).toFixed(1)
              );
            }
            usageData[1] = { ...defaultTableData[1] };
            if (tunnelUsage > 0) {
              usageData[1].value = tunnelUsage;
              usageData[1].str = formatBytes(tunnelUsage);
              usageData[1].percent = parseFloat(
                ((tunnelUsage / totalUsage) * 100).toFixed(1)
              );
            }
            // set center text of the donut chart
            const { value, size } = formatBytes(totalUsage, null, 0, true);
            dataRef.current.internalText[0] = value;
            dataRef.current.internalText[1] = size;

            if (diaUsage > 0 && tunnelUsage > 0) {
              if (usageData[0].percent < 1) {
                usageData[0].value = 1;
                usageData[1].value = 99;
                if (usageData[0].percent < 0.1) usageData[0].percent = "< 0.1";
              } else if (usageData[1].percent < 1) {
                usageData[1].value = 1;
                usageData[0].value = 99;
                if (usageData[1].percent < 0.1) usageData[1].percent = "< 0.1";
              }
            }
          }
        }
        setTableData(usageData);
        setLoader(false);
      }
    }
  }, [
    globalFilter.selectedSite,
    globalFilter.circuitsMasterTable.loader,
    reportData
  ]);

  const onOpen = () => setShowSidebar(true);
  const onClose = () => setShowSidebar(false);

  return (
    <div className="flex-column-full" data-testid="dia-usage-donut">
      <div className="flex-items-two">
        <div className="hbr-type-h2">{i18n.circuit.ddcTitle}</div>
        {loader === false &&
          globalFilter.circuitsMasterTable.loader === false &&
          dataRef.current.error === null &&
          tableData.length > 0 && (
            <HbrButton className="trend-button" variant="text" onClick={onOpen}>
              {i18n.trendingAppsViewTrendAnalysis}
            </HbrButton>
          )}
      </div>
      <div className="flex-main-content flex-items-center">
        {loader === true || globalFilter.circuitsMasterTable.loader ? (
          <Spinner />
        ) : dataRef.current.error !== null ? (
          <ErrorComponent
            {...dataRef.current.error}
            width="110px"
            className="one-line-dashlet-error"
          />
        ) : tableData.length === 0 ? (
          <NoDataAvailable />
        ) : (
          <>
            <DonutChart
              height={height}
              data={tableData}
              centerText={dataRef.current.internalText}
            />
            {showSidebar && (
              <div className={css.sidebar}>
                <HbrDrawer
                  overlay={true}
                  label={i18n.circuit.ddcTitle}
                  open
                  onHbr-hide={onClose}
                >
                  <SideBar
                    circuitsData={globalFilter.circuitsMasterTable}
                    globalFilter={globalFilter}
                  />
                </HbrDrawer>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

View.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  height: PropTypes.string,
  isReport: PropTypes.bool,
  reportData: PropTypes.object
};

View.defaultProps = {
  height: "250px",
  isReport: false,
  reportData: {}
};

export default AppHOC(View);
