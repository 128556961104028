import Authorization from "../../../generics/authorization";
import HeaderBar from "../common/HeaderBar";
import ActionCards from "../applicationInfoPerSingleSiteView/ActionCards";
import PathMetricDashlet from "./PathMetricDashlet";

const pageProps = {
  filterDashlets: [{
    component: HeaderBar,
    name: "header-hero-bar-device-info",
    layout: { x: 0, y: 0, w: 12, h: 3 }
  }],
  dashlets: [
    {
      component: ActionCards,
      name: "single-site-action-card",
      layout: { x: 0, y: 12, w: 2.5, h: 50 }
    },
    {
      component: PathMetricDashlet,
      name: "path-metric-dashlet",
      layout: { x: 4, y: 5, w: 9.5, h: 65 }
    }
  ]
};

const Page = () => (<Authorization pageProps={pageProps} />);

export default Page;
