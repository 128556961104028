import React, { useState, useEffect, useRef, useCallback } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import AppHOC from "../../../generics/AppHOC";
import Application from "./apllicationTab";
import AppUsageView from "../appUsageView";
import Tunnel from "./tunnelTab";
import TunnelsUsageView from "../tunnelsUsage";
import Flow from "./flowTab";
import { useMount } from "../../../utils/genericCommon";
import {
  displayDateTime,
  getTimestampFromUTCString
} from "../../../utils/displayTime";
import { formatBytes } from "../../../utils/format";
import { chartPrimaryColors } from "../../../utils/colors";
import { getCurrentCircuit } from "../common";
import { compareAndMatchDateTime, getIsHourlyData, getTimeInterval } from "../../../utils/common";
import {
  getAppsUsageChartData,
  getFlowsTableData,
  getTunnelUsageChartData,
} from "../actions";
import i18n from "amdi18n-loader!../../nls/i18n";
import css from "../circuits360MagneticStyle.less";
import { defaultCellValue, defaultTextValue, protocols } from "../../../utils/enums";
import ApiService from "../../../config/api-config";

const [HbrCard, HbrTabGroup, HbrTab] =
  reactWrapper(["hbr-card", "hbr-tab-group", "hbr-tab"]);
const tabs = [
  { label: i18n.c360.tabApp, value: "app", default: true },
  { label: i18n.c360.ttTitle, value: "tunnel" },
  { label: i18n.c360.tabFlow, value: "flow" }
];
const defaultData = JSON.stringify({
  at: { error: null, data: [] },
  ac: { error: null, data: [], seriesColors: {} },
  tt: { error: null, data: [] },
  tc: { error: null, data: [], seriesColors: {} },
  ft: { error: null, data: [], timePeriod: [0, 0] }
});

const TabView = props => {
  const [tab, setTab] = useState(tabs.find(item => item.default).value);
  const [acLoader, setACLoader] = useState(true);
  const [tcLoader, setTCLoader] = useState(true);
  const [ftLoader, setFTLoader] = useState(true);

  const [ftTimestamp, setFtTimestamp] = useState(0);
  const dataRef = useRef({ timestamp: 0, ...JSON.parse(defaultData) });
  const mount = useMount();

  // get circuit data from session storage
  const circuitData = getCurrentCircuit(props.match.params.circuitName);

  // get table data
  // load data on initial load, refresh and time filter change


  const getAppData = useCallback(async (payload) => {
    const result = await ApiService.getCircuitsApplicationsList(payload);
    if (result.errorObject instanceof Object === false
      && result.data instanceof Object
      && Array.isArray(result.data.data)) {
      setFtTimestamp(result.data.data[1].max_entry_ts)
    }
  }, []);

  //application api on time filter cahneg
  useEffect(() => {
    if (
      circuitData !== null &&
      (circuitData.overlay === props.globalFilter.selectedOverlay || mount.initMount)
    ) {
      const timestamp = Date.now();
      Object.assign(dataRef.current, { timestamp }, JSON.parse(defaultData));
      loadACData(props.globalFilter, circuitData, timestamp);
      loadTCData(props.globalFilter, circuitData, timestamp);

      if (ftTimestamp != props.globalFilter.timePeriodSelected && ftTimestamp != 0 && tab != "app") {
          setFtTimestamp(0)
        let payload = {
          ...props.globalFilter.globalV4Payload,
          circuit_name: circuitData.circuit_name,
          sort: {
            total_octets: "desc",
            application: "asc"
          },
          offset: 0,
          size: 15
        };
        getAppData(payload);
      }
    }
    mount.initMount = false;
  }, [
    props.globalFilter.timeFilter.current_period[0],
    props.globalFilter.timeFilter.current_period[1],
    props.globalFilter.currentTimeStamp
  ]);

  //call for flows api
  useEffect(() => {
    if (ftTimestamp != 0) {
      const flowsTableDuration = 1200000; // 20 minutes
       let timeRangeMax = getTimestampFromUTCString(ftTimestamp)
       const timeRange = props.globalFilter.timeFilter.current_period.slice(0);
       let timeRangeMin = timeRangeMax - flowsTableDuration > timeRange[0]
         ? timeRangeMax - flowsTableDuration
         : timeRange[0];
      setFTLoader(true);
      loadFTData([timeRangeMin, timeRangeMax], circuitData, dataRef.current.timestamp);
     }
  }, [ftTimestamp]);

  const loadACData = useCallback(async (gFilter, circuit, timestamp) => {
    setACLoader(true);
    const res = await getAppsUsageChartData(gFilter.globalV4Payload, circuit);
    if (mount.mounted === true && timestamp === dataRef.current.timestamp) {
      if (res.errorObject instanceof Object)
        dataRef.current.ac.error = res.errorObject;
      else if (
        res.data instanceof Object && Array.isArray(res.data.data)
        && res.data.data.length > 0
      ) {

        const timeInterval = getTimeInterval(gFilter.globalV4Payload.time_frame);
        const resData = res.data.data;
        const entryData = {};
        const visibleApps = {};
        // create entry object from response data
        for (const { application, entry_ts, usage, usage_rank } of resData) {
          const date = getTimestampFromUTCString(entry_ts);
          if (Number.isFinite(usage)) {
            visibleApps[application] = usage_rank;
            if (entryData[date] instanceof Object)
              entryData[date][application] = usage;
            else entryData[date] = { date, [application]: usage };
          }
        }
        // set chart series colors
        const topApplications = Object.keys(visibleApps)
          .sort((item1, item2) => {
            return visibleApps[item1] < visibleApps[item2] ? -1 : 1
          });
        const chartColors =
          chartPrimaryColors.slice(0, topApplications.length).reverse();
        topApplications.reverse().forEach((app, appIndex) => {
          dataRef.current.ac.seriesColors[app] = chartColors[appIndex];
        });
        // generate graph data from entry array
        const data = Object.values(entryData);
        const newEntries = [];
        const lastIndex = data.length - 1;
        data.forEach((entry1, dataIndex) => {
          // create graph hover tooltip data
          entry1.tooltipData = { date: displayDateTime(entry1.date) };
          for (const item of topApplications) {
            entry1.tooltipData[item] = Number.isFinite(entry1[item])
              ? formatBytes(entry1[item])
              : defaultTextValue;
          }
          if (dataIndex < lastIndex) {
            const entry2 = data[dataIndex + 1];
            /* add an entry to extend lines till current entry's end range if
            next entry is after an hour */
            if (entry2.date > entry1.date + timeInterval) {
              newEntries.push({ ...entry1, date: entry1.date + (timeInterval - 1) });
            } else {
              const newEntry = {
                ...entry1,
                date: entry1.date + (timeInterval - 1)
              };
              const missedApps = topApplications.filter(item => {
                if (Number.isFinite(entry1[item])) {
                  if (Number.isFinite(entry2[item]) === false) {
                    newEntry[item] = 0;
                    return true;
                  } else newEntry[item] = entry2[item];
                }
                return false;
              });
              /* add an entry in current entry's end range if any application
              in current entry is missing in next entry */
              if (missedApps.length > 0) newEntries.push(newEntry);
            }
          }
        });
        // add new records to graph data
        data.push(...newEntries);
        // sort graph data based on entry time
        data.sort((item1, item2) => item1.date > item2.date ? 1 : -1);
        dataRef.current.ac.data = getIsHourlyData(gFilter.globalV4Payload.time_frame) ?
          compareAndMatchDateTime(data, gFilter, "date") : data;
      }
      setACLoader(false);
    }
  }, []);

  // load graph data
  const loadTCData = useCallback(async (gFilter, circuit, timestamp) => {
    setTCLoader(true);
    const res = await getTunnelUsageChartData(gFilter.globalV4Payload, circuit);
    if (mount.mounted === true && timestamp === dataRef.current.timestamp) {
      if (res.errorObject instanceof Object)
        dataRef.current.tc.error = res.errorObject;
      else if (
        res.data instanceof Object && Array.isArray(res.data.data)
        && res.data.data.length > 0
      ) {
        const resData = res.data.data;
        const entryData = {};
        const visibleTunnels = {};
        const timeInterval = getTimeInterval(gFilter.globalV4Payload.time_frame);
        // create entry object from response data
        for (const { tunnel_name, entry_ts, usage, usage_rank } of resData) {
          const date = getTimestampFromUTCString(entry_ts);
          if (Number.isFinite(usage)) {
            visibleTunnels[tunnel_name] = usage_rank;
            if (entryData[date] instanceof Object)
              entryData[date][tunnel_name] = usage;
            else entryData[date] = { date, [tunnel_name]: usage };
          }
        }
        // set chart series colors
        const topTunnels = Object.keys(visibleTunnels)
          .sort((item1, item2) => {
            return visibleTunnels[item1] < visibleTunnels[item2] ? -1 : 1
          });
        const chartColors =
          chartPrimaryColors.slice(0, topTunnels.length).reverse();
        topTunnels.reverse().forEach((tunnel, tunnelIndex) => {
          dataRef.current.tc.seriesColors[tunnel] = chartColors[tunnelIndex];
        });
        // generate graph data from entry array
        const data = Object.values(entryData);
        const newEntries = [];
        const lastIndex = data.length - 1;
        data.forEach((entry1, dataIndex) => {
          // create graph hover tooltip data
          entry1.tooltipData = { date: displayDateTime(entry1.date) };
          for (const item of topTunnels) {
            entry1.tooltipData[item] = Number.isFinite(entry1[item])
              ? formatBytes(entry1[item])
              : defaultTextValue;
          }
          if (dataIndex < lastIndex) {
            const entry2 = data[dataIndex + 1];
            /* add an entry to extend lines till current entry's end range if
            next entry is after an hour */

            if (entry2.date > entry1.date + timeInterval) {
              newEntries.push({ ...entry1, date: entry1.date + (timeInterval - 1) });
            } else {
              const newEntry = {
                ...entry1,
                date: entry1.date + (timeInterval - 1)
              };
              const missedApps = topTunnels.filter(item => {
                if (Number.isFinite(entry1[item])) {
                  if (Number.isFinite(entry2[item]) === false) {
                    newEntry[item] = 0;
                    return true;
                  } else newEntry[item] = entry2[item];
                }
                return false;
              });
              /* add an entry in current entry's end range if any application
              in current entry is missing in next entry */
              if (missedApps.length > 0) newEntries.push(newEntry);
            }
          }
        });
        // add new records to graph data
        data.push(...newEntries);
        // sort graph data based on entry time
        data.sort((item1, item2) => item1.date > item2.date ? 1 : -1);
        dataRef.current.tc.data = getIsHourlyData(gFilter.globalV4Payload.time_frame) ?
          compareAndMatchDateTime(data, gFilter, "date") : data;
      }
      setTCLoader(false);
    }
  }, []);

  // load flows table data
  const loadFTData = useCallback(async (timePeriod, circuit, timestamp) => {
    const res = await getFlowsTableData(timePeriod, circuit);
    if (mount.mounted === true && timestamp === dataRef.current.timestamp) {
      if (res.errorObject instanceof Object)
        dataRef.current.ft.error = res.errorObject;
      else if (
        res.data instanceof Object && Array.isArray(res.data.data)
        && res.data.data.length > 0
      ) {
        const data = res.data.data;
        for (const item of data) {
          item.usage = Number.isFinite(item.octets)
            ? formatBytes(item.octets)
            : defaultCellValue;
          item.date = Number.isFinite(item.entry_time)
            ? displayDateTime(item.entry_time)
            : defaultCellValue;
          item.protocol_key = protocols[item.ip_protocol] || defaultCellValue;
          if (item.tunnel_name === "::::") item.tunnel_name = defaultCellValue;
        }
        dataRef.current.ft.data = data;
      }
      dataRef.current.ft.timePeriod = timePeriod;
      setFTLoader(false);
    }
  }, []);

  const onTabChange = e => setTab(e.target.panel);

  return (
    <HbrCard className={css.tabs} data-testid="c360_tabs" container>
      <div className="flex-column">
        <HbrTabGroup size="small">
          {tabs.map(({ value, label }) => (
            <HbrTab
              key={value}
              className="hbr-type-body3"
              slot="nav"
              panel={value}
              active={value === tab}
              onHbr-click={onTabChange}
            >
              {label}
            </HbrTab>
          ))}
        </HbrTabGroup>

        <div className="panel">
          {tab === tabs[0].value && (
            <>
              <Application
                circuitData={circuitData}
                setFtTimestamp={setFtTimestamp}
                history={props.history}
              />
              <AppUsageView
                loader={acLoader}
                {...dataRef.current.ac}
              />
            </>
          )}
          {tab === tabs[1].value && (
            <>
             <Tunnel
                globalFilter={props.globalFilter}
                circuitData={circuitData}
              />
              <TunnelsUsageView
                loader={tcLoader}
                {...dataRef.current.tc}
              />
            </>
          )}
          {tab === tabs[2].value && (
            <Flow
              loader={ftLoader}
              {...dataRef.current.ft}
              history={props.history}
            />
          )}
        </div>
      </div>
    </HbrCard>
  );
};

TabView.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  setTimeframeFlowsWidget: PropTypes.func
};

export default AppHOC(TabView);
