import { circuitNameClick } from "../../../utils/common";
import { formatBytes } from "../../../utils/format";
import {
  getSelectableColumn,
  getColumnTemplateWithInfo,
  getLinkCellTemplate,
  getCellTemplateWithTooltip
} from "../../../utils/table";
import { convertTimetoUnit } from "../utils/circuitsTableView";
import { formatBits } from "../../circuit360/common";
import i18n from "amdi18n-loader!../../nls/i18n";
import { defaultCellValue } from "../../../utils/enums";

export const getTableColumns = (store, selectCallback, history, tableId) => {
  const tableColumns = [
    {
      prop: "circuit_display_name",
      name: i18n.circuit.ctName,
      size: 220,
      cellTemplate:
        (...args) => getLinkCellTemplate(history, circuitNameClick, ...args)
    },
    {
      prop: "service_provider",
      name: i18n.circuit.ctProvider,
      size: 185,
      cellTemplate: (...args) => getCellTemplateWithTooltip(tableId, ...args)
    },
    {
      prop: "site_name",
      name: i18n.circuit.ctSites,
      size: 180,
      cellTemplate: (...args) => getCellTemplateWithTooltip(tableId, ...args)
    },
    {
      prop: "site_location",
      name: i18n.circuit.ctLocation,
      size: 150,
      cellTemplate: (...args) => getCellTemplateWithTooltip(tableId, ...args)
    },
    {
      prop: "availability",
      name: i18n.circuit.ctAvail,
      size: 132,
      columnTemplate: (...args) => getColumnTemplateWithInfo(
        `${tableId}_tooltip_avail`,
        i18n.circuit.unknownAvailTtip,
        ...args
      )
    },
    {
      prop: "downtime",
      name: i18n.circuit.ctDowntime,
      size: 107
    },
    {
      prop: "total_bps",
      name: i18n.circuit.ctRxTx,
      size: 91
    },
    {
      prop: "rx_bps",
      name: i18n.circuit.ctRx,
      order: "asc",
      size: 90
    },
    {
      prop: "tx_bps",
      name: i18n.circuit.ctTx,
      size: 90
    },
    {
      prop: "rx_utilization",
      name: i18n.circuit.ctRxUtil,
      size: 154
    },
    {
      prop: "tx_utilization",
      name: i18n.circuit.ctTxUtil,
      size: 154
    },
    {
      prop: "vdevice_id",
      name: i18n.circuit.ctLocalDevice,
      size: 130
    },
    {
      prop: "color",
      name: i18n.circuit.ctLocalColor,
      size: 120
    },
    {
      prop: "loss",
      name: i18n.circuit.ctLoss,
      size: 141
    },
    {
      prop: "latency",
      name: i18n.circuit.ctLatency,
      size: 127
    },
    {
      prop: "jitter",
      name: i18n.circuit.ctJitter,
      size: 108
    },
    {
      prop: "total_octets",
      name: i18n.circuit.ctTotalUsage,
      size: 145
    },
    {
      prop: "tunnel_usage",
      name: i18n.circuit.ctTunnelUsage,
      size: 157
    },
    {
      prop: "dia_usage",
      name: i18n.circuit.ctDIAUsage,
      size: 160,
      columnTemplate: (...args) => getColumnTemplateWithInfo(
        `${tableId}_tooltip_dia`,
        i18n.circuit.diaText,
        ...args
      )
    },
    {
      prop: "apps_count",
      name: i18n.circuit.ctAppCount,
      size: 73
    },
    {
      prop: "tunnel_count",
      name: i18n.circuit.ctTunnelCount,
      size: 91
    },
    {
      prop: "vrf_count",
      name: i18n.circuit.ctVRFCount,
      size: 72
    }
  ];
  for (const item of tableColumns)
    Object.assign(item, { id: item.prop, sortable: true });
  tableColumns.unshift(getSelectableColumn(store, selectCallback));
  return tableColumns;
};

const validateAndFormatNumber = value => {
  if (Number.isFinite(value)) return `${parseFloat(value.toFixed(2))}%`;
  return defaultCellValue;
};

const validateAndFormatBits = value => {
  if (Number.isFinite(value)) return formatBits(value);
  return defaultCellValue;
};

export const setCircuitsTableData = data => {
  for (const item of data) {
    item.id = item.circuit_display_name.replace(":", "_");
    item.availability = validateAndFormatNumber(item.availability);
    item.downtime = convertTimetoUnit(item.downtime);
    item.total_bps = validateAndFormatBits(item.total_bps);
    item.rx_bps = validateAndFormatBits(item.rx_bps);
    item.tx_bps = validateAndFormatBits(item.tx_bps);
    item.rx_utilization = validateAndFormatNumber(item.rx_utilization);
    item.tx_utilization = validateAndFormatNumber(item.tx_utilization);
    item.total_octets = formatBytes(item.total_octets);
    item.tunnel_usage = formatBytes(item.tunnel_usage);
    item.dia_usage = formatBytes(item.dia_usage);
  }
  return data;
};
