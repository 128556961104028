import _ from "lodash";
import { useHistory } from "react-router-dom";

//i18nMessageBundle
import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";

//utils
import { setLocalStorageFlag } from "../../../../utils/common";
import { BASE_URL } from "../../../../apis/apiConstants";

/**
 * Returns list of objects that has data for each row in the single site filter table
 * @description creates a new object with source and destinaions endpoints
 * @return {array} list of objects that has data for each row in the single site filter table
 */
export const getSingleSiteColumns = () => {
  const history = useHistory();

  return [
    {
      data: "localSite",
      title: i18nMessageBundle.appGroupPerSite.localSiteLabel,
      searchable: true,
      render: data => {
        if (_.isEmpty(data)) {
          return "--";
        } else {
          return data;
        }
      }
    },
    {
      data: "localDevice",
      title: i18nMessageBundle.appGroupPerSite.localDeviceLabel,
      searchable: true,
      render: data => {
        if (_.isEmpty(data)) {
          return "--";
        } else {
          return data;
        }
      }
    },
    {
      data: "remoteSite",
      title: i18nMessageBundle.appGroupPerSite.remoteSiteLabel,
      searchable: true,
      render: data => {
        if (_.isEmpty(data)) {
          return "--";
        } else {
          return data;
        }
      }
    },
    {
      data: "remoteDevice",
      title: i18nMessageBundle.appGroupPerSite.remoteDeviceLabel,
      searchable: true,
      render: data => {
        if (_.isEmpty(data)) {
          return "--";
        } else {
          return data;
        }
      }
    },
    {
      data: "location",
      title: i18nMessageBundle.predictiveNetworks.appTableConfigLocationLabel,
      render: (data = "--") => {
        return data;
      }
    },
    {
      data: "defaultQuality",
      title: i18nMessageBundle.defaultPathQualtyLabel,
      orderable: false,
      render: data => {
        return data;
      }
    },
    {
      data: "recommendationQuality",
      title: i18nMessageBundle.recoPathQualityLabel,
      orderable: false,
      render: data => {
        return data;
      }
    },
    {
      data: "gains",
      title: i18nMessageBundle.appGroupPerSite.recoExpecteGainLabel,
      render: data => {
        if (!data) return "--";
        else
          return `<div id="table-col-row-site-impact-col">
                  ${data}%
                <div>`;
      }
    },
    {
      data: "impactedUsers",
      title: i18nMessageBundle.appGroupPerSite.recoImpactedUsers,
      render: data => {
        return data;
      }
    },
    {
      data: "action",
      title: i18nMessageBundle.reporTableActions,
      createdCell: td => {
        td.addEventListener("click", event => {
          event.preventDefault();

          const pathDetailsList =
            event.path && event.path[0]
              ? event.path[0].id.split(",")
              : event.target.id.split(",");
          const pathDetails = {
            pathQosPair: `${pathDetailsList[0]},${pathDetailsList[1]}`,
            localDevice: pathDetailsList[2],
            remoteDevice: pathDetailsList[3],
            defaultQuality: pathDetailsList[4],
            recommendationQuality: pathDetailsList[5],
            totalNumUsers: pathDetailsList[6],
            gains: pathDetailsList[7]
          };
          setLocalStorageFlag("pathDetails", JSON.stringify(pathDetails));

          history.push(`${BASE_URL}/predictivenetworks/applicationinformation/device`);
        });
      },
      render: function(data, type, row) {
        return `<div>
                  <dnx-button name="${row.pathQosPair},${row.localDevice},${row.remoteDevice},${row.defaultQuality},${row.recommendationQuality},${row.totalNumUsers},${row.gains}" 
                  label="${i18nMessageBundle.appGroupPerSite.pathListTable}"
                  flavor="inline-link"></dnx-button>
              </div>`;
      }
    }
  ];
};

/**
 * Returns list of objects that has data for each row in the all sites table
 * @description creates a new object with source and destinaions endpoints
 * @param {object} sitesObject list of sites object
 * @return {array} list of objects that has data for each row in the all sites table
 */
export const getAllSitesColumns = () => {
  //sitesObject
  return [
    {
      data: "siteIds",
      title: i18nMessageBundle.appGroupPerSite.recoSiteLabel,
      render: data => {
        return `<div>${data}</div>`;
      }
    },
    {
      data: "location",
      title: i18nMessageBundle.appGroupPerSite.recoPathLocationLabel,
      render: data => {
        return data;
      }
    },
    {
      data: "currentPath",
      title: i18nMessageBundle.predictiveNetworks.appTableConfigCurPathLabel,

      render: data => {
        if (data) return data.replaceAll(",", ", ");
        else return "--";
      }
    },
    {
      data: "recommendedPath",
      title: i18nMessageBundle.predictiveNetworks.appTableConfigRecomPathLabel,

      render: data => {
        if (data) return data.replaceAll(",", ", ");
        else return "--";
      }
    },
    {
      data: "defaultQuality",
      title: i18nMessageBundle.defaultPathQualtyLabel,
      render: data => {
        return data;
      }
    },
    {
      data: "recommendationQuality",
      title: i18nMessageBundle.recoPathQualityLabel,
      render: data => {
        return data;
      }
    },
    {
      data: "gains",
      title: i18nMessageBundle.appGroupPerSite.recoExpecteGainLabel,
      render: data => {
        if (!data) return "--";
        else
          return `<div id="table-col-row-site-impact-col">
                  ${data}%
                <div>`;
      }
    },
    {
      data: "impactedUsers",
      title: i18nMessageBundle.appGroupPerSite.recoImpactedUsers,
      render: data => {
        return data;
      }
    },
    {
      data: "state",
      title: i18nMessageBundle.appGroupPerSite.recoActionLabel,
      render: data => {
        return data;
      }
    }
  ];
};
export const getTools = (isEndpointTableLabel = false) => {
  const tool = [
    // Builtin title ref
    {
      ref: {
        name: "title",
        config: {
          text: isEndpointTableLabel
            ? i18nMessageBundle.singleSitePathTableLabel
            : i18nMessageBundle.allSiteSitesLabel,
          count: false
        }
      }
    },
    {
      ref: {
        name: "search"
      }
    }
  ];
  return tool;
};
