import React, { Suspense } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import _ from "lodash";

// components
import { GanttChart } from "../../../../../common/GanttChart";
import Spinner from "../../../../../common/Spinner";

// lazy loading components
const NoDataAvailable = React.lazy(() =>
  import("../../../../../common/NoDataAvailable")
);
const ErrorComponent = React.lazy(() =>
  import("../../../../../common/ErrorComponent")
);

// utils
import { displayUserExpChartTooltp, getStartOfHour } from "../../../../../utils/displayTime";
import {
  getChartHight,
  getColorFromMeanQuality
} from "../../../../../utils/common";
import { chartPrimaryColors, colors } from "../../../../../utils/colors";

// i18nMessageBundle
import i18nMessageBundle from "amdi18n-loader!../../../../nls/i18n";

// styles
import css from "../../../insightsAppMagneticStyle.less";

const [HbrCard] = reactWrapper(["hbr-card"]);

const NetworkQoeMetric = props => {
  switch (true) {
    case (props.hasFailedNetworkQoeMetrics || props.hasFailedChartData):
      return (
        <div
          className={css["load-balance-chart"]}
          data-cy="networkQoeMetricError"
        >
          <Suspense fallback={<Spinner />}>
            {props.hasFailedNetworkQoeMetrics ?
              <ErrorComponent
                {...props.errorMessageQoeMetrics}
                width={"200px"}
                className={"super-small-dashlet-error"}
              /> :
              <ErrorComponent
                {...props.errorMessageChartData}
                width={"200px"}
                className={"super-small-dashlet-error"}
              />
            }
          </Suspense>
        </div>
      );
    case props.isDone && !_.isEmpty(props.data): {
      const data = processData(props);

      return (
        <HbrCard
          className={css["load-balance-chart"]}
          data-cy="networkQoeMetricChartId"
        >
          <GanttChart
            id="networkQoeMetricChartId"
            data={data}
            chartsData={props.chartsData}
            callbackFromParent={props.callbackFromParent}
            hoverCallback={() => { }}
            chartHeight={getChartHight(props.data.gantData.length)}
            isPathQoSdetails={true}
            disableYAxisLabel={false}
            disableGridLine={true}
            scaleGridLine={false}
            enableDisposeAllCharts={false}
            dateAxisGridLineDisabled={true}
            seriesHeight={8}
            showSynchronization
            calcBaseIterval={true}
          />
        </HbrCard>
      );
    }
    case props.isDone && _.isEmpty(props.data):
      return (
        <div
          className={css["load-balance-chart"]}
          data-cy="networkQoeMetricNoData"
        >
          <Suspense fallback={<Spinner />}>
            <NoDataAvailable
              text={
                i18nMessageBundle.predictiveNetworks.networkQoeMetricChartNoData
              }
            />
          </Suspense>
        </div>
      );
    default:
      return (
        <div className="center-spinner">
          <Spinner />
        </div>
      );
  }
};

NetworkQoeMetric.propTypes = {
  chartsData: PropTypes.array.isRequired,
  callbackFromParent: PropTypes.func.isRequired,
  data: PropTypes.object,
  hasFailed: PropTypes.bool,
  isDone: PropTypes.bool,
  errorMessage: PropTypes.object,
  hasFailedNetworkQoeMetrics: PropTypes.bool,
  hasFailedChartData: PropTypes.bool,
  errorMessageQoeMetrics: PropTypes.object,
  errorMessageChartData: PropTypes.object
};

export default NetworkQoeMetric;

const processData = props => {
  let ganttChartData = [];
  const currentRecomPath = props.pathRecommendation[0]
    ? props.pathRecommendation[0]?.metadata.recommendedInterfaceIds
    : [];

  //loop tru props.data.gantData list to extract the interface for gantt chart
  props.data.gantData.forEach((item, key) => {
    const currentPaths = [];
    const currentPathsColors = [];
    const recommendedPaths = [];
    let meanQ = "-";
    let color = colors.gray50;

    /*
    while we have item from props.data.gantData 
    we need to generate a tooltip for each interface
    and so we loop tru props.data.gantData again
    */
    props.data.gantData.forEach((elem, k) => {
      const path = `${elem.dstInterfaceId} · ${elem.srcInterfaceId}`;

      currentPaths.push(path);
      currentPathsColors.push(chartPrimaryColors[k]);

      if (currentRecomPath.includes(elem.dstInterfaceId))
        recommendedPaths.push({ color: chartPrimaryColors[k], pathName: path.trim() });
    });

    /*
    for each interface we need to determine the color and mean quality for each segment on the chart
    for one hour we have one segment with lets say green color
    */
    const data = props.networkQoeMetricData.map(elem => {
      const fromDate = new Date(elem.date);
      const dateIncreasedByADay = new Date(
        elem.date.setHours(elem.date.getHours() + 1)
      );
      const toDate = new Date(dateIncreasedByADay);
      const dateToISOString = new Date(getStartOfHour(elem.date)).toISOString();
      const isSelectedDateInObject = props.data[key][dateToISOString];
      let isTooltipShown = false;

      for (const [key] of Object.entries(props.data)) {
        if (props.data[key][dateToISOString]) {
          isTooltipShown = true;
          break;
        }
      }

      if (isSelectedDateInObject) {
        /*
        props.networkQoeMetricData and props.data are 2 arrays that hold data for path metric chart and gantt chart respectively
        in the folowing logic below we are trying to loop tru gantt chart data and assign mean quality if it exists
        First we loop to find a date that is equal to "dateFromGanttChartData" in the gantt chart data. 
        we start by comparing "fromDate" from path metric chart with "dateFromGanttChartData" from the gantt chart.
        When we find dates that are the same we associate to that element mean quality
        */
        const dateFromGanttChartData =
          props.data[key][dateToISOString].dateStart;

        if (
          new Date(fromDate).toDateString() ===
          new Date(dateFromGanttChartData).toDateString()
        ) {
          meanQ = `${Math.round(
            props.data[key][dateToISOString].meanQuality
          )}%`;
          color = getColorFromMeanQuality(
            props.data[key][dateToISOString].meanQuality
          );
        } else {
          meanQ = "-";
          color = colors.gray50;
        }

        return {
          index: key, //index gives us the srcInterfaceId the user points/hoverover
          color: color,
          hasGanttChartData: true,
          meanQuality: meanQ,
          name: `${item.dstInterfaceId} · ${item.srcInterfaceId}`,
          label: `${item.dstInterfaceId} · ${item.srcInterfaceId}`,
          currentPaths: currentPaths, //list of current paths i.e. [biz-internet, mpls]
          lengthOfPaths: currentPaths.length,
          currentPathsColor: currentPathsColors[key], //color that belongs to currnt path selected
          currentPathsColors: currentPathsColors, // list of colors for all paths
          recommendedPaths: recommendedPaths, //list of recommeded paths
          fromDateFormated: displayUserExpChartTooltp(fromDate),
          toDateFormated: displayUserExpChartTooltp(toDate),
          fromDate: fromDate.getTime(),
          toDate: toDate.getTime()
        };
      } else {
        return {
          index: key, //index gives us the srcInterfaceId the user points/hoverover
          color: colors.gray90,
          hasGanttChartData: isTooltipShown,
          meanQuality: "-",
          name: `${item.dstInterfaceId} · ${item.srcInterfaceId}`,
          label: `${item.dstInterfaceId} · ${item.srcInterfaceId}`,
          currentPaths: currentPaths, //list of current paths i.e. [biz-internet, mpls]
          lengthOfPaths: currentPaths.length,
          currentPathsColor: currentPathsColors[key], //color that belongs to currnt path selected
          currentPathsColors: currentPathsColors, // list of colors for all paths
          recommendedPaths: recommendedPaths, //list of recommeded paths
          fromDateFormated: displayUserExpChartTooltp(fromDate),
          toDateFormated: displayUserExpChartTooltp(toDate),
          fromDate: fromDate.getTime(),
          toDate: toDate.getTime()
        };
      }
    });
    ganttChartData = [...ganttChartData, ...data];
  });

  return ganttChartData;
};
