import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import KpiChartView from "./KpiChartView";
import { withRouter} from 'react-router-dom';
import i18n from "../../../../nls/root/i18n";
import css from "../anomalyMagneticStyle.less";
import { BASE_URL } from "../../../../../apis/apiConstants";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

const [
  HbrBreadcrumb,
  HbrBreadcrumbItem,
  HbrIcon,
  HbrCard] = reactWrapper([
    "hbr-breadcrumb",
    "hbr-breadcrumb-item",
    "hbr-icon",
    "hbr-card"]);

export const AdKpiView = (props) => {
    const[KpiType, setKpiType] = useState("")

    useEffect(() => {
        window.scrollTo(0, 0)
        const Kpi_type = JSON.parse(
            sessionStorage.getItem("adSelectedChartData")
        ).kpiType
        setKpiType(Kpi_type)
    }, [])

    return (
        <div>
            <div className={css["kpi-bread-crumb"]}>
                <HbrBreadcrumb shadowrootmode="open">
                    <HbrBreadcrumbItem
                        shadowrootmode="open"
                        onClick={() => props.history.push(`${BASE_URL}/predictivenetworks/anomalydetection`)}
                    >
                    <div className="flex-items">
                        <HbrIcon name="arrow-left" />
                        {i18n.predictiveNetworks.anomaly}
                    </div>
                    </HbrBreadcrumbItem>
                </HbrBreadcrumb>
            </div>
            <div className={css["Kpi-heading"]}>
                {`${KpiType} KPI details`}
            </div>
            <HbrCard className={css["anomaly-detection-kpi-widget"]}>
                <KpiChartView />
            </HbrCard>
        </div>
    )
}

AdKpiView.propTypes = {
    history: PropTypes.object.isRequired
}

export default withRouter(AdKpiView)
