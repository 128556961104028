import Authorization from "../../generics/authorization";
import TabNavigation from "../../common/TabNavigation";
import TimeStampFilterComponent from "../../common/TimeStampFilterComponent";
import CardsView from "./cardsView";
import BandwidthView from "./bandwidth";
import CircuitsTableView from "./circuitsTable";

const xValue = 0;
const wValue = 12;

const pageProps = {
  filterDashlets: [
    {
      component: TabNavigation,
      name: "tab-nav",
      layout: { x: xValue, y: 0, w: wValue, h: 9 }
    },
    {
      component: TimeStampFilterComponent,
      name: "time-filter",
      layout: { x: xValue, y: 10, w: wValue, h: 2 }
    }
  ],
  dashlets: [
    {
      component: CardsView,
      name: "cards",
      layout: { x: xValue, y: 0, w: wValue, h: 22 }
    },
    {
      component: BandwidthView,
      name: "bandwidth",
      layout: { x: xValue, y: 22, w: wValue, h: 30 }
    },
    {
      component: CircuitsTableView,
      name: "circuits-table",
      layout: { x: xValue, y: 52, w: wValue, h: 38.75 }
    }
  ]
};

const Page = () => (<Authorization pageProps={pageProps} />);

export default Page;
