import React, { lazy, Suspense, useEffect, useState} from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import AppHOC from "../../../generics/AppHOC";
import Spinner from "../../../common/Spinner";
import PredictiveNetworksTabs from "../PredictiveNetworksTabs";
import CircuitTable from "./CircuitTable";
import { useMount } from "../../../utils/genericCommon";
import {
  getLocalStorageFlag,
  isBandForecastingTabSelected
} from "../../../utils/common";
import css from "../insightsMagneticStyle.less";
import { getCookie } from "../../../serviceWorker";

const [HbrCard] = reactWrapper(["hbr-card"]);
const OnboardingView = lazy(() => import("./OnboardingView"));
const BandwidthForecast = lazy(() => import("./BandwidthForecast"));

const PredictiveWidgetTop = props => {
   useState({ loader: true, error: null, data: [] });
  const mount = useMount();
  const first20CharsUrl = location.href.substring(0, 20);
  //check if au region
  const isPredictivLabelEnabled = first20CharsUrl.includes("au");
  const { currentTimeStamp } = props.globalFilter;
  const { predictiveTabSelected } = isPredictivLabelEnabled ? { predictiveTabSelected: "bandForecastingTab" } : props.globalFilter;
  const isBF = isBandForecastingTabSelected();
  const isUIConvergence = getCookie("cl-analytics");

  useEffect(() => {
    if (mount.initMount === true || isBF || predictiveTabSelected === "bandForecastingTab"
    ) {
      const availableFeatures =
        JSON.parse(getLocalStorageFlag("availableFeatures"));
      if (availableFeatures?.bwf) {
        mount.initMount = false;
      }
    }
  }, [currentTimeStamp]);

  switch (true) {
    case predictiveTabSelected === "bandForecastingTab" || isBF: {
      return (
        <Suspense fallback={<Spinner />}>
          <HbrCard className={css["bandwidth-forecasting-widget"]}>
            {!isUIConvergence && <PredictiveNetworksTabs />}
            <BandwidthForecast />
            <CircuitTable {...props} />
          </HbrCard>
        </Suspense>
      );
    }
    case (predictiveTabSelected === "pprTab" || predictiveTabSelected === "anomalyTab") && !isPredictivLabelEnabled: {
      return (
        <Suspense fallback={<Spinner />}>
          <OnboardingView />
        </Suspense>
      );
    }
    default:
      return null;
  }
};

PredictiveWidgetTop.propTypes = {
  globalFilter: PropTypes.object
};

export default AppHOC(PredictiveWidgetTop);
