import { createContext } from "@cisco-dna/redux-utils";
import { ACTIONS } from "./actions";

const reduxContext = createContext();
const storeKey = "vanalytics.bandwidthForecastKey";

export default reduxContext;

const initialState = {
  bandwidthProps: { circuit: "", associatedOverlay: "" },
  tab: "pprTab"
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.GET_BANDWIDTH_FORECAST_CIRCUIT:
      return {
        ...state,
        bandwidthProps: payload
      };
    case ACTIONS.GET_PREDICTIVE_TAB:
      return {
        ...state,
        tab: payload
      };
    default:
      return state;
  }
};

reduxContext.injectReducer({
  STORE: storeKey,
  REDUCER: reducer,
  ACTION_TYPE: Object.values(ACTIONS)
});
