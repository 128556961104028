import { useState } from "react";

//config
import ApiService from "../../config/api-config";
import { BASE_URL } from "../../apis/apiConstants";

//utils
import {
  formatEndpointsBySiteid,
  validateAppClass,
  arrayToObject,
  formatActionStateLabel,
  formatLocationToString,
  formatRecommendationsFromAction,
  validateRecommandationValue,
  computeGains,
  isApplyRevertRecommendation
} from "../../utils/waniCommon";
import { getLocalStorageFlag } from "../../utils/common";
import {
  subtractUnitsFromDate,
  shortDisplayTime
} from "../../utils/displayTime";
import { ACTIONSTATE } from "../../utils/enums";

const usePredictiveNetworksHooks = () => {
  const [endpointDetails, setEndpointDetails] = useState({});
  const [
    isFetchingEndpointsListDone,
    setIsFetchingEndpointsListDone
  ] = useState(false);
  const [recommedationsAppGroup, setRecommedationsAppGroup] = useState();
  const [
    isFetchingRecommedationsDone,
    setIsFetchingRecommedationsDone
  ] = useState(false);
  const [
    isFetchingRecommedationsError,
    setIsFetchingRecommedationsError
  ] = useState({});
  const [actionPaths, setActionPaths] = useState();
  const [isFetchingActionsDone, setIsFetchingActionsDone] = useState(false);
  const [isFetchingActionsError, setIsFetchingActionsError] = useState({});
  const [
    isFetchingPathRecommendationDone,
    setIsFetchingPathRecommendationDone
  ] = useState(false);
  const [
    isFetchingPathRecommendationError,
    setIsFetchingPathRecommendationError
  ] = useState({});
  const [pathRecommendation, setPathRecommendation] = useState();
  const [recommendedReadySites, setRecommendedReadySites] = useState();

  const getEndpointsDetails = async (selectedOverlay, selectedSite) => {
    const promises = [];

    let endpoint = {},
      activeAppClasses = [],
      recomPropsObject = {},
      latestWindow = { start: "", end: "" },
      globalAltoId = "",
      appGroupStateObject = {},
      pathsRecomendations = {};
    let result;
    const initErrorObject = { errorObject: { errorCode: 500, message: "" } };

    setIsFetchingEndpointsListDone(false);
    setIsFetchingRecommedationsDone(false);

    if (isFetchingRecommedationsError?.errorObject) {
      setIsFetchingRecommedationsError({});
    }

    if (!("activeAppClasses" in endpoint)) {
      result = await ApiService.getAltoAppClassList(selectedOverlay);

      if (
        location.pathname ===
        `${BASE_URL}/predictivenetworks/applicationinformation/site`
      ) {
        activeAppClasses = [{ appClassId: getLocalStorageFlag("appGroup") }];
      } else {
        if (result?.errorObject) {
          setIsFetchingRecommedationsError(result);
        } else {
          activeAppClasses = validateAppClass(result.data.appClasses);
        }
      }

      if (result?.errorObject || result?.data?.error) {
        if (result?.data?.error) {
          setIsFetchingRecommedationsError(initErrorObject);
        } else {
          setIsFetchingRecommedationsError(result);
        }
      } else {
        const lastSeeen =
          result.data?.appClasses[0]?.lastSeen === undefined
            ? new Date().toISOString()
            : new Date(result.data?.appClasses[0]?.lastSeen).toISOString();
        const start = new Date(
          subtractUnitsFromDate(new Date(lastSeeen), 30, "d")
        ).toISOString();

        latestWindow = { start: start, end: lastSeeen };
        recomPropsObject = Object.assign(
          {},
          { activeAppClasses: activeAppClasses }
        );
      }
    } else {
      activeAppClasses = validateAppClass(endpoint.activeAppClasses);
    }

    recomPropsObject = Object.assign(
      {},
      { activeAppClasses: activeAppClasses }
    );
    if (!result?.errorObject && !result?.data?.error) {
      if (!("endpoint" in endpoint)) {
        const resultEndpoint = await ApiService.getAltoEndpoints(
          {
            aggregatedBy: "location.global",
            limit: 5000
          },
          selectedOverlay
        );
        const resultEndpoints = await ApiService.getAltoEndpoints(
          { limit: 5000 },
          selectedOverlay
        );
        if (resultEndpoint?.errorObject || resultEndpoint?.data?.error) {
          if (resultEndpoint?.data?.error) {
            setIsFetchingRecommedationsError(initErrorObject);
          } else {
            setIsFetchingRecommedationsError(result);
          }
        } else if (resultEndpoints?.errorObject) {
          setIsFetchingRecommedationsError(resultEndpoints);
        } else {
          const singleSiteEndpoint =
            resultEndpoints.data.endpoints &&
            formatEndpointsBySiteid(resultEndpoints.data.endpoints);

          endpoint = resultEndpoint.data.endpoints;

          globalAltoId =
            parseInt(selectedSite) > 0
              ? singleSiteEndpoint[selectedSite.toString()]?.endpointId
              : endpoint[0]?.endpointId;

          activeAppClasses.forEach(appClass => {
            promises.push(
              ApiService.getAltoEndpointRecommendationAction(
                {
                  appClass: appClass.appClassId,
                  limit: 5000
                },
                selectedOverlay
              )
            );
            promises.push(
              ApiService.getAltoRecoEndpointMetrics(
                {
                  windowSize: "7d",
                  appClass: appClass.appClassId,
                  numWindows: 1,
                  endpointId: globalAltoId,
                  experimental: "v2",
                  limit: 5000
                },
                selectedOverlay
              )
            );
          });

          Promise.all(promises)
            .then(results => {
              let isError = {};

              //loop tru actionResults to check if any response failed and if so set the error
              for (const result of results) {
                if (result?.errorObject || result?.data?.error) {
                  if (result?.data?.error) {
                    setIsFetchingRecommedationsError(initErrorObject);
                    isError = initErrorObject;
                  } else {
                    setIsFetchingRecommedationsError(result);
                    isError = result;
                  }
                  break;
                }
              }
              if (!isError.errorObject) {
                const globalEndpoints = formatEndpointsBySiteid(
                  resultEndpoints.data.endpoints
                );
                const endpointsWithLocation = resultEndpoints?.data?.endpoints
                  ? arrayToObject(resultEndpoints.data.endpoints, "endpointId")
                  : [];
                appGroupStateObject = activeAppClasses.reduce(
                  (array, value) => ({ ...array, [value.appClassId]: false }),
                  {}
                );

                const initValues = {
                  defaultQuality: "--",
                  recommendationQuality: "--",
                  gains: "",
                  impactedUsers: "--"
                };
                let avrgRecommendation = activeAppClasses.reduce(
                  (array, value) => ({
                    ...array,
                    [value.appClassId]: {
                      ...initValues,
                      appClass: value.appClassId
                    }
                  }),
                  {}
                );
                let siteEnpointId = "";

                results.forEach(result => {
                  if (result.data.actions?.length) {
                    const appClass = result.config.params.appClass;

                    if (selectedSite > 0) {
                      result.data.actions.forEach(action => {
                        if (selectedSite.toString() == action.siteId) {
                          pathsRecomendations = action.metadata;

                          avrgRecommendation = {
                            ...avrgRecommendation,
                            [appClass]: {
                              ...initValues,
                              appClass: appClass,
                              pathsRecomendations: pathsRecomendations,
                              location:
                                endpointsWithLocation[action.srcEndpointId]
                                  .location
                            }
                          };
                        }
                      });
                    } else {
                      avrgRecommendation = {
                        ...avrgRecommendation,
                        [appClass]: {
                          ...initValues,
                          appClass: appClass
                        }
                      };
                    }

                    for (const action of result.data.actions) {
                      const isActive = isApplyRevertRecommendation(action);

                      if (isActive) {
                        //i.e. appGroupStateObject = {google-workspace: true, gotomeeting: true, office365: false, salesforce: true}
                        // in this case google-workspace app has recommendation and its true, othewise no recommendation
                        appGroupStateObject[appClass] = true;
                        break;
                      }
                    }
                  } else if (
                    result.data.recoEndpointMetrics?.length &&
                    avrgRecommendation[result.config.params.appClass]
                  ) {
                    const appClass = result.config.params.appClass;
                    siteEnpointId =
                      result.data.recoEndpointMetrics[0].endpointId;

                    avrgRecommendation[
                      appClass
                    ].defaultQuality = validateRecommandationValue(
                      result.data.recoEndpointMetrics[0].defaultQuality
                    );
                    avrgRecommendation[
                      appClass
                    ].recommendationQuality = validateRecommandationValue(
                      result.data.recoEndpointMetrics[0].recommendationQuality
                    );
                    avrgRecommendation[appClass].gains = computeGains(
                      result.data.recoEndpointMetrics[0]
                    );
                    avrgRecommendation[appClass].impactedUsers = result.data
                      .recoEndpointMetrics[0].numUsers
                      ? result.data.recoEndpointMetrics[0].numUsers
                      : 0;
                  }
                });

                recomPropsObject = Object.assign(
                  {},
                  {
                    ...recomPropsObject,
                    endpoint: endpoint,
                    endpoints: globalEndpoints
                  }
                );

                //details for all sites filter
                setEndpointDetails({
                  listOfEndpoints: recomPropsObject,
                  globalAltoId: globalAltoId,
                  latestWindow: latestWindow,
                  endpoint: endpoint,
                  pathsRecomendations: pathsRecomendations,
                  location: endpointsWithLocation[siteEnpointId]
                    ? endpointsWithLocation[siteEnpointId].location
                    : {},
                  //appGroupStateObject shows if an app group has active recommendations; i.e {voice: true, office: false}
                  appGroupStateObject: appGroupStateObject
                });
                setRecommedationsAppGroup(avrgRecommendation);

                setIsFetchingRecommedationsDone(true);
              }
            })
            .catch(error => {
              console.log("Failed to get site endpoints", error);
            });
        }
      }
      setIsFetchingEndpointsListDone(true);
      localStorage.setItem("latestWindowStart", latestWindow.start);
      localStorage.setItem("latestWindowEnd", latestWindow.end);
      localStorage.setItem("globalAltoId", globalAltoId);
    }
  };

  const getPathRecommendation = async () => {
    const siteId = getLocalStorageFlag("actionCardSite");
    const result = await ApiService.getAltoEndpointRecommendationAction(
      {
        appClass: getLocalStorageFlag("appGroup"),
        limit: 5000
      },
      getLocalStorageFlag("currentOverlay")
    );

    if (result?.errorObject) {
      setIsFetchingPathRecommendationError(result);
    } else {
      setPathRecommendation(
        result.data.actions.filter(action => action.siteId === siteId)
      );
    }
    setIsFetchingPathRecommendationDone(true);
  };

  const getListActions = async selectedOverlay => {
    const promiseList = [];
    let appClasses;

    const resultEndpoints = await ApiService.getAltoEndpoints(
      { limit: 5000 },
      selectedOverlay
    );
    if (!resultEndpoints?.errorObject) {
      const result = await ApiService.getAltoAppClassList(selectedOverlay);
      if (result?.errorObject) {
        appClasses = result;
      } else {
        const valideAppClasses = validateAppClass(result.data.appClasses);
        appClasses = valideAppClasses.map(app => app.appClassId);
      }

      if (!appClasses?.errorObject) {
        appClasses.forEach(appClass => {
          if (isFetchingActionsDone) setIsFetchingActionsDone(false);

          if (isFetchingActionsError?.errorObject)
            setIsFetchingActionsError({});

          // get all recom for appClass
          promiseList.push(
            ApiService.getAltoRecoEndpointMetrics(
              {
                windowSize: "7d",
                appClass: appClass,
                numWindows: 1,
                aggregatedBy: "siteId",
                experimental: "v2",
                limit: 5000
              },
              selectedOverlay
            )
          );

          promiseList.push(
            ApiService.getAltoEndpointRecommendationAction(
              {
                appClass: appClass,
                limit: 5000
              },
              selectedOverlay
            )
          );
        });

        Promise.all(promiseList).then(results => {
          let isError = {};

          // check if resultes has api failure
          for (const result of results) {
            if (result?.errorObject) {
              isError = result;
              break;
            }
          }

          if (isError?.errorObject) {
            setIsFetchingActionsError(isError);
          } else {
            const formatedEndpoints = resultEndpoints?.data?.endpoints
              ? arrayToObject(resultEndpoints.data.endpoints, "endpointId")
              : [];
            let listRecommendationActions = [];
            let recomSummary = {}, recommededSites = {}, uniqueRecommendationPerSiteApp = {};

            //in case no api failure detected in the api response
            for (const result of results) {
              if (result.data.actions) {
                const appClass = result.config.params.appClass;
                result.data.actions.forEach(action => {
                  const isActive = isApplyRevertRecommendation(action);

                  if (isActive) {
                    const {
                      defaultQuality,
                      recommendationQuality,
                      totalNumUsers,
                      location,
                      gains,
                      impactedUsers,
                      siteId
                    } = formatRecommendationsFromAction(
                      action,
                      formatedEndpoints,
                      recomSummary
                    );

                    if (
                      formatedEndpoints[action.srcEndpointId] &&
                      formatedEndpoints[action.srcEndpointId].location
                    ) {
                      const index = listRecommendationActions.length;
                      const recommendation = {
                        idTable: index,
                        state: formatActionStateLabel(action),
                        appClass: appClass,
                        currentPath: action.metadata.defaultInterfaceIds.toString(),
                        recommendedPath: action.metadata.recommendedInterfaceIds.toString(),
                        metadata: action.metadata,
                        location: location,
                        locationOneString: formatLocationToString(location),
                        siteId: siteId.toString(),
                        totalNumUsers: totalNumUsers,
                        defaultQuality: defaultQuality,
                        recommendationQuality: recommendationQuality,
                        gains: gains,
                        srcEndpointId: action.srcEndpointId,
                        actionState: formatActionStateLabel(action),
                        currentState: formatActionStateLabel(action),
                        actionId: action.actionId,
                        impactedUsers: impactedUsers,
                        date: shortDisplayTime(action.windowStart),
                        stateRequest: action.curState,
                        isFavorite:
                          (action.isActive &&
                            action.curState == ACTIONSTATE.APPLY_REQUEST) ||
                          action.curState == ACTIONSTATE.REVERT_REQUEST
                      };

                      //create a unique recommendation per site application
                      const uniqueSite = uniqueRecommendationPerSiteApp[siteId.toString()];

                      if (uniqueSite && uniqueSite[appClass]) {
                        if (new Date(listRecommendationActions[uniqueSite[appClass].index].date) < new Date(recommendation.date))
                          listRecommendationActions[uniqueSite[appClass].index] = recommendation;
                      } else {
                        uniqueRecommendationPerSiteApp[siteId.toString()] = {
                          [appClass]: {
                            date: shortDisplayTime(action.windowStart),
                            index: index
                          }
                        }
                        listRecommendationActions.push(recommendation);
                      }
                    }
                  }
                });
              } else {
                recomSummary = result?.data?.recoEndpointMetrics
                  ? arrayToObject(result.data.recoEndpointMetrics, "endpointId")
                  : [];
              }
            }

            listRecommendationActions.sort((a, b) => a.gains - b.gains);

            setActionPaths(listRecommendationActions);

            listRecommendationActions.forEach((action) => {
              if (recommededSites[action.siteId]) {
                recommededSites[action.siteId] = {
                  ...recommededSites[action.siteId],
                  metrics: {
                    ...recommededSites[action.siteId].metrics,
                    [action.appClass]: {
                      defaultQuality: action.defaultQuality,
                      recommendationQuality: action.recommendationQuality,
                      actionState: action.state
                    }
                  }
                }
              } else {
                recommededSites[action.siteId] = {
                  longitude: action.location.longitude,
                  latitude: action.location.latitude,
                  site_id: action.siteId,
                  actionState: action.state,
                  metrics: {
                    [action.appClass]: {
                      defaultQuality: action.defaultQuality,
                      recommendationQuality: action.recommendationQuality,
                      actionState: action.state
                    }
                  }
                };
              }
            });

            setRecommendedReadySites(recommededSites)
            setIsFetchingActionsDone(true);
          }
        });
      } else {
        // error in getAltoAppClassList() api call
        setIsFetchingActionsError(appClasses);
      }
    } else {
      // error in getAltoEndpoints() api call
      setIsFetchingActionsError(resultEndpoints);
    }
  };

  return {
    state: {
      isFetchingEndpointsListDone,
      endpointDetails,
      isFetchingRecommedationsDone,
      isFetchingRecommedationsError,
      recommedationsAppGroup,
      actionPaths,
      isFetchingActionsDone,
      isFetchingActionsError,
      pathRecommendation,
      isFetchingPathRecommendationError,
      isFetchingPathRecommendationDone,
      recommendedReadySites
    },
    getEndpointsDetails,
    getListActions,
    getPathRecommendation
  };
};

export default usePredictiveNetworksHooks;