import { createContext } from "@cisco-dna/redux-utils";
import { ACTIONS } from "./actionTypes";

const reduxContext = createContext();
const keyStore = "vanalytics.circuits";
const initialState = {
  selectedCircuits: [],
  selectedTrendCircuits: [],
  trendCircuitsApiData: {},
  trendHeatWidget: { isloading: true, resObj: undefined }
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.setSelectedCircuits:
      return { ...state, selectedCircuits: payload };
    case ACTIONS.HM_STATUS:
      return {
        ...state,
        trendCircuitsApiData: {}
      };
    case ACTIONS.HEATMAP_API_STATUS:
      return {
        ...state,
        trendHeatWidget: {
          isloading: payload.isloading,
          resObj: payload.resObj
        }
      };
    case ACTIONS.RESET_HEATMAP_AVAIL_CHANGE:
      return {
        ...state,
        trendCircuitsApiData: {}
      };
    case ACTIONS.SET_TREND_CIRCUITS_AVAIL:
      return {
        ...state,
        trendCircuitsApiData: payload,
        trendHeatWidget: {
          isloading: false,
          resObj: { errorCode: 200, message: "successful" }
        }
      };
    case ACTIONS.GET_SELECTED_TREND_CIRCUITS_LIST:
      return {
        ...state,
        selectedTrendCircuits: payload
      };
    default:
      return state;
  }
};

reduxContext.injectReducer({
  STORE: keyStore,
  REDUCER: reducer,
  ACTION_TYPE: Object.values(ACTIONS)
});

export default reduxContext;
