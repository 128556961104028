import AdKpiView from "./index";
import Authorization from "../../../../../generics/authorization";

const pageProps = {
  dashlets: [
    {
      component: AdKpiView,
      name: "ad-kip-view",
      layout: { x: 0, y: 0, w: 12, h: 66 }
    }
  ]
};

const Page = () => (<Authorization pageProps={pageProps} />);

export default Page;
