import React, { useEffect, useState, Suspense } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import _ from "lodash";

// component
import NetworkQoeMetric from "./NetworkQoeMetric";
import PathQosMetrics from "./PathQosMetric";
import PathUserExperienceMetric from "./PathQosUserExpChart";
import AppHOC from "../../../../generics/AppHOC";
import Spinner from "../../../../common/Spinner";
import { validateProps } from "../../../../utils/waniCommon";

const ErrorComponent = React.lazy(() =>
  import("../../../../common/ErrorComponent")
);
//lazy loading
const NoDataAvailable = React.lazy(() =>
  import("../../../../common/NoDataAvailable")
);

// style
import css from "../../insightsAppMagneticStyle.less";

//utils
import reduxContext from "../../reducer";
import useAppGroupPerSiteApis from "../PathMetricDashlet/usePathMetricHooks";
import usePredictiveNetworksHooks from "../../../insights/usePredictiveNetworkHooks";
import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";

const mapStateToProps = state => {
  return {
    predictiveNetworks: state.vanalytics.predictiveNetworks
  };
};

const PathMetricDashlet = props => {
  const { selectedSite, onboardingStatus } = validateProps(props.globalFilter);

  const {
    pathState,
    getPathUserExperienceMetricData,
    getPathMetricsChartData
  } = useAppGroupPerSiteApis(props);
  const [isLoading, setIsLoading] = useState(true);
  const { state, getPathRecommendation } = usePredictiveNetworksHooks();
  const chartsData = [];

  useEffect(() => {
    let abortController = new AbortController();

    if (onboardingStatus === "ACTIVE") {
      getPathUserExperienceMetricData(props);
      getPathMetricsChartData(props);
      getPathRecommendation();
    } else {
      setIsLoading(false);
    }

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (
      pathState.isFetchingPathQosChartDataDone &&
      pathState.isFetchingNetworkQoeMetricsDone &&
      state.isFetchingPathRecommendationDone &&
      pathState.isFetchingPathMetrixChartDone
    ) {
      setIsLoading(false);
    } else if (
      pathState.isFetchingPathQosChartDataError.errorObject ||
      pathState.isFetchingNetworkQoeMetricsError.errorObject ||
      pathState.isFetchingPathMetrixChartError.errorObject ||
      state.isFetchingPathRecommendationError.errorObject ||
      pathState.isFetchingPathQosUserChartDataError

    ) {
      setIsLoading(false);
    }
  }, [
    pathState.isFetchingPathQosChartDataDone,
    pathState.isFetchingPathQosChartDataError,
    pathState.isFetchingNetworkQoeMetricsDone,
    pathState.isFetchingNetworkQoeMetricsError,
    pathState.isFetchingPathMetrixChartDone,
    pathState.isFetchingPathMetrixChartError,
    state.isFetchingPathRecommendationDone,
    state.isFetchingPathRecommendationError
  ]);

  const chartCallbackPathMetric = chart => {
    //multiline chart
    chartsData.push(chart);
  };
  const chartCallbackNetworkQoeMetric = chart => {
    //gantt chart
    chartsData.push(chart);
  };
  const chartCallbackUserExperienceMetric = chart => {
    //bulet column chart
    chartsData.push(chart);
  };

  if (isLoading) {
    return (
      <div className={css["path-qos-charts"]}>
        <div className="device-page-spinner">
          <Spinner />
        </div>
      </div>
    );
  } else if (onboardingStatus !== "ACTIVE") {
    return (
      <div className={css["path-qos-charts"]} data-cy="path-qos-charts">
        <Suspense fallback={<Spinner />}>
          <div className="device-page-no-data">
            <NoDataAvailable text={i18nMessageBundle.syncronizedChartNoData} />
          </div>
        </Suspense>
      </div>
    );
  } else if (
    pathState.isFetchingPathMetrixChartError?.errorObject
  ) {
    return (
      <div
        className={css["qos-user-exp-chart"]}
        data-cy="pathQosUserExpChartError"
      >
        <Suspense fallback={<Spinner />}>
          <ErrorComponent
            {...pathState.isFetchingPathMetrixChartError.errorObject}
            width={"200px"}
            className={"super-small-dashlet-error"}
          />
        </Suspense>
      </div>
    )
  }

  return (
    <div className={css["path-qos-charts"]}>
      <div className="flex-column">
        {
          <>
            <PathUserExperienceMetric
              {...props}
              userChartData={pathState.pathUserExprChartData}
              chartsData={chartsData}
              callbackFromParent={chartCallbackUserExperienceMetric}
              chartProps={{
                site: selectedSite,
                isSeriesConnected: false
              }}
              isDone={pathState.isFetchingPathQosChartDataDone}
              hasFailed={!_.isEmpty(pathState.isFetchingPathQosChartDataError)}
              errorMessage={
                pathState.isFetchingPathQosChartDataError.errorObject
              }
            />
            <NetworkQoeMetric
              {...props}
              pathRecommendation={state.pathRecommendation}
              networkQoeMetricData={pathState.pathUserExprChartData}
              data={pathState.networkQoeMetricsData}
              chartsData={chartsData}
              callbackFromParent={chartCallbackNetworkQoeMetric}
              isDone={pathState.isFetchingNetworkQoeMetricsDone}
              hasFailedNetworkQoeMetrics={!_.isEmpty(pathState.isFetchingNetworkQoeMetricsError)}
              hasFailedChartData={!_.isEmpty(pathState.isFetchingPathQosUserChartDataError)}
              errorMessageQoeMetrics={
                pathState.isFetchingNetworkQoeMetricsError.errorObject
              }
              errorMessageChartData={
                pathState.isFetchingPathQosUserChartDataError
              }
            />
            <PathQosMetrics
              data={pathState.pathMetrix}
              legend={pathState?.networkQoeMetricsData?.gantData}
              chartsData={chartsData}
              callbackFromParent={chartCallbackPathMetric}
              hoverCallback={() => { }}
              isDone={pathState.isFetchingPathMetrixChartDone}
              hasFailed={!_.isEmpty(pathState.isFetchingPathMetrixChartError)}
              errorMessage={
                pathState.isFetchingPathMetrixChartError.errorObject
              }
            />
          </>
        }
      </div>
    </div>
  );
};

PathMetricDashlet.propTypes = {
  globalFilter: PropTypes.object.isRequired
};

export default reduxContext.withProvider(
  connect(mapStateToProps)(AppHOC(PathMetricDashlet))
);
