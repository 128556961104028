import React, { useState, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import AppHOC from "../../../generics/AppHOC";
import Spinner from "../../../common/Spinner";
import ErrorComponent from "../../../common/ErrorComponent";
import NoDataAvailable from "../../../common/NoDataAvailable";
import DonutChart from "../../../common/donutChart";
import SideBar from "./SideBar";
import { formatBytes } from "../../../utils/format";
import i18n from "../../nls/root/i18n";
import css from "../circuitsMagneticStyle.less";
import { chartPrimaryColors } from "../../../utils/colors";

const [HbrButton, HbrDrawer] = reactWrapper(["hbr-button", "hbr-drawer"]);

const View = ({ globalFilter, height, isReport, reportData }) => {
  const [loader, setLoader] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const dataRef = useRef({
    error: null,
    internalText: ["0", "Bytes"]
  });

  useEffect(() => {
    const circuitsData = isReport === true
      ? reportData
      : globalFilter.circuitsMasterTable;
    if (circuitsData.loader === true) setLoader(true);
    else {
      dataRef.current.error = circuitsData.error;

      if (dataRef.current.error !== null) setLoader(false);
      else {
        const siteId = globalFilter.selectedSite;
        let usageData = [];
        let data = circuitsData.data;
        if (siteId !== -1) data = data.filter(item => item.site_id === siteId);
        if (data.length > 0) {
          // get usage values by color
          let totalUsageByColor = {};
          let others = null;
          let othersUsage = 0;
          let totalUsage = 0;
          for (const item of data) {
            if (Number.isFinite(item.total_octets)) {
              if (totalUsageByColor[item.color])
                totalUsageByColor[item.color] += item.total_octets;
              else totalUsageByColor[item.color] = item.total_octets;
            }
          }
          const totalUsageList = Object.entries(totalUsageByColor).filter(
            item => item[1] > 0
          );
          totalUsageList.sort((item1, item2) => (item1[1] > item2[1] ? -1 : 1));
          for (const item of totalUsageList) totalUsage += item[1];
          if (totalUsageList.length > 7) {
            others = totalUsageList.splice(6);
            others.forEach(item => (othersUsage += item[1]));
          }
          totalUsageByColor = Object.fromEntries(totalUsageList);
          if (others) totalUsageByColor.others = othersUsage;

          // set usage data
          usageData = Object.entries(totalUsageByColor).map((item, index) => {
            const usageItem = {
              id: item[0],
              color: chartPrimaryColors[index],
              value: item[1],
              str: "0 Bytes",
              percent: 0
            };
            if (usageItem.value > 0) {
              usageItem.str = formatBytes(usageItem.value);
              usageItem.percent = parseFloat(
                ((usageItem.value / totalUsage) * 100).toFixed(1)
              );
            }
            if (index === 6 && others !== null) {
              usageItem.others = others.map(otherItem => {
                const otherUsageItem = {
                  id: otherItem[0],
                  color: usageItem.color,
                  value: otherItem[1],
                  str: "0 Bytes",
                  percent: 0
                };
                if (otherUsageItem.value > 0) {
                  otherUsageItem.str = formatBytes(otherUsageItem.value);
                  otherUsageItem.percent = parseFloat(
                    ((otherUsageItem.value / totalUsage) * 100).toFixed(1)
                  );
                }
                return otherUsageItem;
              });
            }
            return usageItem;
          });
          // set center text of the donut chart
          if (totalUsage > 0) {
            const { value, size } = formatBytes(totalUsage, null, 0, true);
            dataRef.current.internalText[0] = value;
            dataRef.current.internalText[1] = size;
          } else dataRef.current.internalText = ["0", "Bytes"];

          // set minimum display of a data to 1% of donut
          if (usageData.length > 1) {
            let donutSize = 1000;
            let visibileUsage = 0;
            const visibleUsageData = usageData.filter(item => {
              if (item.percent >= 1) {
                visibileUsage += item.value;
                return true;
              } else if (item.value) {
                item.value = 10;
                donutSize -= item.value;
                if (item.percent < 0.1) item.percent = "< 0.1";
              }
              return false;
            });
            if (donutSize < 1000) {
              for (const item of visibleUsageData) {
                item.value = Math.floor(
                  (item.value / visibileUsage) * donutSize
                );
              }
            }
          }
        }
        setTableData(usageData);
        setLoader(false);
      }
    }
  }, [
    globalFilter.selectedSite,
    globalFilter.circuitsMasterTable.loader,
    reportData
  ]);

  const onOpen = () => setShowSidebar(true);
  const onClose = () => setShowSidebar(false);

  const hasValidData = globalFilter.circuitsMasterTable.loader === false &&
    loader === false && dataRef.current.error === null && tableData.length > 0;

  return (
    <div className="flex-column-full" data-testid="color-usage-donut">
      <div className="flex-items-two">
        <div className="hbr-type-h2">{i18n.circuit.cdcTitle}</div>
        {hasValidData === true && (
          <HbrButton className="trend-button" variant="text" onClick={onOpen}>
            {i18n.trendingAppsViewTrendAnalysis}
          </HbrButton>
        )}
      </div>
      <div className="flex-main-content flex-items-center">
        {loader === true || globalFilter.circuitsMasterTable.loader ? (
          <Spinner />
        ) : dataRef.current.error !== null ? (
          <ErrorComponent
            {...dataRef.current.error}
            width="110px"
            className="one-line-dashlet-error"
          />
        ) : tableData.length === 0 ? (
          <NoDataAvailable />
        ) : (
          <>
            <DonutChart
              height={height}
              data={tableData}
              centerText={dataRef.current.internalText}
            />
            {showSidebar === true && (
              <div className={css.sidebar}>
                <HbrDrawer
                  label={i18n.circuit.cdcTitle}
                  overlay
                  open
                  onHbr-hide={onClose}
                >
                  <SideBar
                    circuitsData={globalFilter.circuitsMasterTable}
                    globalFilter={globalFilter}
                  />
                </HbrDrawer>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

View.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  height: PropTypes.string,
  isReport: PropTypes.bool,
  reportData: PropTypes.object
};

View.defaultProps = {
  height: "250px",
  isReport: false,
  reportData: {}
};

export default AppHOC(View);
