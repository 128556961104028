import React, { useRef, useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { DtableWrapper } from "dnx-web-components/dist/index-with-deps-react.min";
import table from "dnx-web-components/dist/table.min";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
//configs
import { getColumns, getTools } from "./circuitTableConfig";
import reduxContext from "../../reducer";
import * as actions from "../../actions";
import ApiService from "../../../../config/api-config";

//components

//utils
import { createRequest } from "../../../../utils/query-utils";
import { getLocalStorageFlag } from "../../../../utils/common";
import css from "../../insightsMagneticStyle.less";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { toast } from "../../../../utils/genericCommon";
import i18n from "amdi18n-loader!../../../nls/i18n";
import {
  getTimePeriodELT,
  shortDisplayDate24HourWithSec,
  formatDate,
} from "../../../../utils/displayTime";

const [HbrCard] = reactWrapper(["hbr-card"]);

let selected_circuit_names = [];

const CircuitTable = (props) => {
  const [tableComp, seTableComp] = useState();
  const DnxTable = DtableWrapper(table);
  const tableRef = useRef();

  const getExportData = dataTable => {
    const { columns } = dataTable.props;
    const settings = dataTable.table.settings()[0];
    const timePeriodArray = getTimePeriodELT("24");
    const payload = {
      time_frame: "24h",
      entry_ts: {
        start: shortDisplayDate24HourWithSec(
          formatDate(timePeriodArray.current_period[0]).utc()
        ),
        end: shortDisplayDate24HourWithSec(
          formatDate(timePeriodArray.current_period[1]).utc()
        ),
      },
      sort: {
        [columns[settings.aaSorting[0][0]].data]: settings.aaSorting[0][1]
      }
    };
    if (settings.oPreviousSearch.sSearch)
      payload.search = settings.oPreviousSearch.sSearch;
    return ApiService.getBwfCircuitsTableList(payload);
  };

  useEffect(() => {
    const getTableProps = () => {
      const tableConfig = {
        columns: getColumns(props.history),
        idData: "circuit_display_name",
        serverSide: true,
        tools: getTools(getExportData),
        getData: getData,
        dtOptions: {
          stateSave: false,
          scrollX: true,
          scrollY: 300,
          stateDuration: -1,
          order: [[6, "desc"]],
          drawCallback: function () {
            if (!tableRef.current) return;
            if (selected_circuit_names?.length > 0) {
              tableRef.current.table.selectRowsUndraw(selected_circuit_names);
            }
          }
        },
        columnSearch: true,
        columnSearchEnabled: true,
        dataTableClasses: "nowrap",
        rowSelect: true,
        multiSelect: false,
        rowSelectCallback: (item, itemList, itemObj, itemObjList) => {
          props.setBandwidthForecastCircuit({ circuit: itemObjList[0] });
        },
      };
      return tableConfig;
    };

    const getData = async (aoData) => {
      let dTable = null;
      selected_circuit_names = []
      const timePeriodArray = getTimePeriodELT("24");
      let payload = {
        time_frame: "24h",
        entry_ts: {
          start: shortDisplayDate24HourWithSec(
            formatDate(timePeriodArray.current_period[0]).utc()
          ),
          end: shortDisplayDate24HourWithSec(
            formatDate(timePeriodArray.current_period[1]).utc()
          ),
        },
      };
      const defaultRequest = { ...payload };
      if (tableRef.current) {
        dTable = tableRef.current.table;
        if (dTable?.store?.state?.search_simpleQuery) {
          defaultRequest.search = dTable.store.state.search_simpleQuery;
        }
      }
      defaultRequest.sort = { total_bps: "desc" };
      const requestPayload = createRequest(defaultRequest, aoData, dTable);

      if (!requestPayload.size) {
        requestPayload.size = 25;
        requestPayload.offset = 0;
      }

      const result = await ApiService.getBwfCircuitsTableList(requestPayload);
      const selectedCircuit = getLocalStorageFlag("selectedCircuit");

      if (selectedCircuit) {
        const circuitName = selectedCircuit.replace("~", ":");
        const circuit =
          result.data.data.find(item => item.circuit_display_name === circuitName);
        if (!circuit)
          toast(i18n.bandwidthForecast.noForecastCircuit, "danger");
      }

      props.setBandwidthForecastCircuit({ circuit: result.data.data[0] });
      selected_circuit_names.push(result.data.data[0]?.circuit_display_name);

      if (result && !result.errorObject) {
        return {
          response: result.data.data,
          count: result.data.count
        }
      } else return {
        response: [],
        count: 0
      }
    };
    const tableProps = getTableProps();
    seTableComp(<DnxTable {...tableProps} ref={tableRef} />);
  }, []);


  return (
    <HbrCard
      className={`${css.table} widget-container`}
      data-testid="circuit_circuits_table"
      container
    >
      <div className="flex-column flex-main-content">
        {tableComp}
      </div>
    </HbrCard>
  );
};

const mapStateToProps = state => ({
  bandwidthForecast: state.vanalytics.bandwidthForecast
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

CircuitTable.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  setBandwidthForecastCircuit: PropTypes.func,
};

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(CircuitTable)
);
