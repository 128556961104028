import React, {
  useState,
  useEffect,
  useCallback
} from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import AppHOC from "../../../generics/AppHOC";
import Spinner from "../../../common/Spinner";
import ErrorComponent from "../../../common/ErrorComponent";
import NoDataAvailable from "../../../common/NoDataAvailable";
import StackedMultiLineChart from "../../../common/StackedMultiLineChart";
import _ from "lodash";
import { useMount } from "../../../utils/genericCommon";
import { formatBits, add_bps, getCurrentCircuit } from "../common";
import {
  displayDateTime, getTimestampFromUTCString
} from "../../../utils/displayTime";
import {
  setLocalStorageFlag,
  getLocalStorageFlag,
  compareAndMatchDateTime,
  getIsHourlyData,
  getTimeInterval
} from "../../../utils/common";
import { getRxTxChartData } from "../actions";
import i18n from "amdi18n-loader!../../nls/i18n";
import css from "../circuits360MagneticStyle.less";
import { defaultTextValue } from "../../../utils/enums";
import { chartPrimaryColors } from "../../../utils/colors";
import { BASE_URL } from "../../../apis/apiConstants";

const [
  HbrCard,
  HbrTooltip,
  HbrIcon,
  HbrSelect,
  HbrOption,
  HbrButton
] = reactWrapper([
  "hbr-card",
  "hbr-tooltip",
  "hbr-icon",
  "hbr-select",
  "hbr-option",
  "hbr-button"
]);
const chartTypesObj = {
  bwidth: i18n.c360.rcTitle,
  util: i18n.c360.bwUtilization
};
const chartTypes = Object.entries(chartTypesObj).map(item => ({
  value: item[0],
  label: item[1]
}));
// color set for the lines of graph
const seriesColors = {
  rx: chartPrimaryColors[0],
  tx: chartPrimaryColors[1]
};

const View = ({
  globalFilter,
  match: {
    params: { circuitName }
  },
  history
}) => {
  const availableFeatures = JSON.parse(
    getLocalStorageFlag("availableFeatures")
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const mount = useMount();
  const [chartType, setChartType] = useState(chartTypes[0].value);
  const [bandwidthChartData, setBandwidthChartData] = useState([]);
  const [isTypeChange, setIsTypeChange] = useState(false);
  const [utilizationChartData, setUtilizationChartData] = useState();
  // load bandwidth usage graph data
  useEffect(async () => {
    const circuit = getCurrentCircuit(circuitName);
    if (
      circuit &&
      (circuit.overlay === globalFilter.selectedOverlay || mount.initMount)
    ) {
      const { bandwidth_downstream, bandwidth_upstream } = circuit;
      setLoading(true);
      setError(null);
      let bandwidthData = [],
        utilizationData = [];
      const min = globalFilter.timeFilter.current_period[0];
      const res = await getRxTxChartData(globalFilter.globalV4Payload, circuit);
      if (!mount.mounted) return;
      if (!res.errorObject) {
        if (res.data?.data?.length) {
          // generate graph data
          const newEntries = [],
            newUEntries = [];
          const lastIndex = res.data.data.length - 1;
          res.data.data.map((entry1, dataIndex, arr) => {
            const date = getTimestampFromUTCString(entry1.entry_ts).valueOf()
            if (date < min) return { date };
            const entry2 = dataIndex < lastIndex ? arr[dataIndex + 1] : null;
            const nextDate = entry2
              ? getTimestampFromUTCString(entry2.entry_ts).valueOf()
              : 0;
            const entry = {
              date,
              rxTx: 0,
              tooltipData: {
                date: displayDateTime(date),
                rx: defaultTextValue,
                tx: defaultTextValue,
                rxTx: defaultTextValue
              }
            };
            let uEntry;
           if ((Number.isFinite(bandwidth_downstream) === true && bandwidth_downstream > 0) || (Number.isFinite(bandwidth_upstream) === true && bandwidth_upstream > 0)) {
              uEntry = {
                date,
                tooltipData: {
                  date: displayDateTime(date),
                  rx: defaultTextValue,
                  tx: defaultTextValue
                }
              };
            }
            if (Number.isFinite(entry1.rx_bps)) {
              entry.rx = entry1.rx_bps;
              entry.tooltipData.rx = formatBits(entry.rx);
              entry.rxTx += entry.rx;
              if (Number.isFinite(bandwidth_downstream) === true && bandwidth_downstream > 0) {
                uEntry.rx = entry1.rx_bps
                  ? parseFloat((((entry1.rx_bps) / (bandwidth_downstream * 1000)) * 100).toFixed(2))
                  : entry1.rx_bps;
                uEntry.tooltipData.rx = uEntry.rx + "%";
              }
            }
            if (Number.isFinite(entry1.tx_bps)) {
              entry.tx = entry1.tx_bps;
              entry.tooltipData.tx = formatBits(entry.tx);
              entry.rxTx += entry.tx;
              if (Number.isFinite(bandwidth_upstream) === true && bandwidth_upstream > 0) {
                uEntry.tx = entry1.tx_bps
                  ? parseFloat((((entry1.tx_bps) / (bandwidth_upstream * 1000)) * 100).toFixed(2))
                  : entry1.tx_bps;
                uEntry.tooltipData.tx = uEntry.tx + "%";
              }
            }
            if (Number.isFinite(entry.rx) || Number.isFinite(entry.tx))
              entry.tooltipData.rxTx = add_bps([
                entry.tooltipData.rx || "0 bps",
                entry.tooltipData.tx || "0 bps"
              ]);
            if (entry2) {

              const timeInterval = getTimeInterval(globalFilter.globalV4Payload.time_frame);
              /* add an entry to extend lines till current entry's end range if
                next entry is after an hour */
              if (nextDate > entry.date + timeInterval) {
                newEntries.push({ ...entry, date: entry.date + (timeInterval - 1) });
                uEntry?.date &&
                  newUEntries.push({ ...uEntry, date: uEntry.date + (timeInterval -1) });
              } else {
                const newEntry = {
                  ...entry,
                  rxTx: 0,
                  date: entry.date + (timeInterval - 1)
                };
                let newUEntry;
                if (uEntry?.date) {
                  newUEntry = {
                    ...uEntry,
                    date: uEntry.date + (timeInterval - 1)
                  };
                }
                let missedDatapoint = false;
                if (Number.isFinite(entry.rx)) {
                  if (!Number.isFinite(entry2.rx_bps)) {
                    newEntry.rx = 0;
                    newUEntry?.date ? (newUEntry.rx = 0) : "";
                    missedDatapoint = true;
                  } else
                    newEntry.rx = entry2.rx_bps;
                  newEntry.rxTx += newEntry.rx;
                  if (Number.isFinite(bandwidth_downstream) === true && bandwidth_downstream > 0) {
                    newUEntry.rx = entry2.rx_bps
                      ? parseFloat(
                          (
                            ((entry2.rx_bps) / (bandwidth_downstream * 1000)) *
                            100
                          ).toFixed(2)
                        )
                      : entry2.rx_bps;
                  }
                }
                if (Number.isFinite(entry.tx)) {
                  if (!Number.isFinite(entry2.tx_bps)) {
                    newEntry.tx = 0;
                    newUEntry?.date ? (newUEntry.tx = 0) : "";
                    missedDatapoint = true;
                  } else
                    newEntry.tx = entry2.tx_bps;
                  newEntry.rxTx += newEntry.tx;
                  if (Number.isFinite(bandwidth_upstream) === true && bandwidth_upstream > 0) {
                    newUEntry.tx = entry2.tx_bps
                      ? parseFloat(
                          (
                            ((entry2.tx_bps) / (bandwidth_upstream * 1000)) * 100
                          ).toFixed(2)
                        )
                      : entry2.tx_bps;
                  }
                }
                /* add an entry in current entry's end range if rx or tx
                  in current entry is missing in next entry */
                if (missedDatapoint) {
                  newEntries.push(newEntry);
                  newUEntries.push(newUEntry);
                }
              }
            }

            /* add an entry for the last entry to extend lines till its
                end range */
            bandwidthData.push(entry);
            uEntry && !_.isEmpty(uEntry) && utilizationData.push(uEntry);
          });
          // add new records to graph data
          bandwidthData.push(...newEntries);
          newUEntries &&
            !_.isEmpty(newUEntries) &&
            utilizationData.push(...newUEntries);
          // sort graph data based on entry time
          bandwidthData.sort((val1, val2) => (val1.date > val2.date ? 1 : -1));
          utilizationData.sort((val1, val2) =>
            val1.date > val2.date ? 1 : -1
          );

          if (getIsHourlyData(globalFilter.globalV4Payload.time_frame)) {
            bandwidthData = compareAndMatchDateTime(bandwidthData, globalFilter, "date")
            utilizationData = compareAndMatchDateTime(utilizationData, globalFilter, "date")
          }
          setBandwidthChartData(bandwidthData);
          setUtilizationChartData(utilizationData);
          setIsTypeChange(true);
        }
      } else setError(res.errorObject);
      setLoading(false);
    }
    mount.initMount = false;
  }, [
    globalFilter.timeFilter.current_period[0],
    globalFilter.timeFilter.current_period[1],
    globalFilter.currentTimeStamp,
    circuitName
  ]);

  useEffect(() => {
    setIsTypeChange(true);
  }, [chartType]);

  // customize the chart
  const customizeChart = useCallback(
    chart => {
      chart.series.values.forEach(series => {
        series.adapter.add("tooltipHTML", (html, target) => {
          const data = target.tooltipDataItem.dataContext;
          if (data && data.tooltipData) {
            html = `
              <div class="ttip-header">
                ${chartTypesObj[chartType]}&nbsp;
                <span class="date">${data.tooltipData.date}</span>
              </div>
              <table class="ttip-content">
              <tr>
                <td>${
                  chartTypesObj[chartType] === i18n.c360.rcTitle
                    ? i18n.c360.rcTtipRxTx
                    : ""
                }</td>
                <td>${
                  chartTypesObj[chartType] === i18n.c360.rcTitle
                    ? data.tooltipData.rxTx
                    : ""
                }</td>
              </tr>
              ${chart.series.values
                .map(item =>
                  !item.isHidden
                    ? `
                    <tr>
                      <td class="ttip-bwidth">
                        <div class="flex-items">
                          <span
                            class="bar-legend"
                            style="background-color:${item.stroke.hex};"
                          ></span>
                          ${item.name}
                        </div>
                      </td>
                      <td>${data.tooltipData[item.name]}</td>
                    </tr>
                    `
                    : ""
                )
                .join("")}
              </table>`;
          }
          return html;
        });
      });
      if (chartTypesObj[chartType] === i18n.c360.rcTitle) {
        chart.yAxes.values[0].numberFormatter.numberFormat = "#.a";
      } else {
        chart.yAxes.values[0].numberFormatter.numberFormat = "#.##'%'";
      }
      chart.legend.userClassName += " bwidth-legend";
    },
    [chartType]
  );

  const onChange = useCallback(e => {
    const value = e.target.value;
    setIsTypeChange(false);
    setChartType(value);
  }, []);

  const onClick = () => {
    setLocalStorageFlag("selectedCircuit", circuitName);
    setLocalStorageFlag("bandForecastingTab", true);
    history.push(`${BASE_URL}/predictivenetworks`);
  };

  return (
    <HbrCard
      className={`${css.chart} widget-container`}
      data-testid="c360_bandwidth_chart"
      container
    >
      <div className="flex-column flex-main-content">
        <div className="flex-items title-block">
          <div className="flex-items-center hbr-type-h2">
            {i18n.c360.rcTitle}
            <HbrTooltip content={i18n.circuit.bDefinition} placement="top">
              <HbrIcon name="info" sentiment="neutral" />
            </HbrTooltip>
          </div>
          <HbrSelect
            value={chartType}
            onHbr-change={onChange}
            name="c360-bandwidth"
            popoverMinWidth={150}
            size="small"
          >
            {chartTypes.map(item => (
              <HbrOption key={item.value} value={item.value}>
                {item.label}
              </HbrOption>
            ))}
          </HbrSelect>
        </div>
        {loading ? (
          <Spinner />
        ) : error ? (
          <ErrorComponent
            {...error}
            className="small-dashlet-error"
            width="220px"
          />
        ) : bandwidthChartData.length === 0 ? (
          <NoDataAvailable />
        ) : (
          <>
            {isTypeChange && (
              <div className="flex-main-content">
                <StackedMultiLineChart
                  id="c360_bandwidth"
                  scrollbar
                  scrollBarInitialZoom
                  stacked={false}
                  colorset={seriesColors}
                  callbackFromParent={customizeChart}
                  i18n={i18n}
                  data={chartTypesObj[chartType] === i18n.c360.rcTitle
                    ? bandwidthChartData
                    : utilizationChartData}
                  min={globalFilter.timeFilter.current_period[0]}
                  max={globalFilter.timeFilter.current_period[1]}
                  label={chartTypesObj[chartType]}
                  configurationStatus={chartTypesObj[chartType] !== i18n.c360.rcTitle}
                />
              </div>
            )}
            {availableFeatures?.bwf && (
              <div
                className="action-block-circuit360"
                data-cy="view-bwidth-link"
              >
                <HbrButton variant="text" onClick={onClick}>
                  {i18n.circuitViewBandwidthForecast}
                </HbrButton>
              </div>
            )}
          </>
        )}
      </div>
    </HbrCard>
  );
};

View.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default AppHOC(View);
