import React, { useEffect, useMemo, useRef } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { DtableWrapper } from "dnx-web-components/dist/index-with-deps-react.min";
import table from "dnx-web-components/dist/table.min";
import { useMount } from "../../../../utils/genericCommon";
import { createRequest } from "../../../../utils/query-utils";
import { defaultTableProps, getTools } from "./tableConfig";
import apiService from "../../../../config/api-config";
import css from "../../../circuits/circuitsMagneticStyle.less";
import AppHoc from "../../../../generics/AppHOC";

const [HbrCard] = reactWrapper(["hbr-card"]);
const DnxTable = DtableWrapper(table);

let isFilterChanged = false;
const Table = (props) => {
  const tableRef = useRef(null);
  const mount = useMount();
  let site = props.circuitData?.site_id || -1
  const tableProps = defaultTableProps(props.history, props.setSelectedSite, site)
  const dataRef = useRef({
    tableProps: {
      ...tableProps,
      getData: aoData => getData(aoData, tableRef, dataRef, props),
      tools: getTools(dataTable => getExportData(dataTable, dataRef))
    },
    v4Payload: {
      ...props.globalFilter.globalV4Payload,
      site_id: site,
      circuit_name: props.circuitData.circuit_name
    }
  });
  useMemo(() => {
    if (mount.initMount === false)
      Object.assign(dataRef.current.v4Payload, props.globalFilter.globalV4Payload);
  }, [props.globalFilter.globalV4Payload]);

  //refresh table on time filter chaange
  useEffect(() => {
    if (mount.initMount === false && tableRef.current !== null) {
      isFilterChanged = true;
      tableRef.current.table.refresh();
    }
    mount.initMount = false;
  }, [
    props.globalFilter.timeFilter.current_period[0],
    props.globalFilter.timeFilter.current_period[1],
    props.globalFilter.currentTimeStamp
  ]);

  return (
    <HbrCard className={`${css.table} widget-container`} container>
      <div className="flex-column flex-main-content">
        <DnxTable ref={tableRef} {...dataRef.current.tableProps} />
      </div>
    </HbrCard>
  );
};

// get application table data
const getData = async (aoData, tableRef, dataRef, props) => {
  props.setFtTimestamp(0)
  let payload = {
    ...dataRef.current.v4Payload,
    sort: {
      total_octets: "desc",
      application: "asc"
    },
    offset: 0,
    size: 25
  };
  if (tableRef.current !== null) {
    payload = createRequest(payload, aoData, tableRef.current.table)
    let dTable = tableRef.current.table;
    if (dTable?.store?.state?.search_simpleQuery) {
      payload.search = dTable.store.state.search_simpleQuery;
    }
  }

  if (isFilterChanged) {
    payload.sort = {
      total_octets: "desc",
      application: "asc"
    }
    isFilterChanged = false;
  }
  const result = await apiService.getCircuitsApplicationsList(payload);
  if (result.errorObject instanceof Object === false
            && result.data instanceof Object
    && Array.isArray(result.data.data)) {
    //timestamp for flows api
    props.setFtTimestamp(result.data.data[1].max_entry_ts);
    return { response: result.data.data, count: result.data.count };
  } else {
    return { response: [], count: 0 };
  }
};

const getExportData = (dataTable, dataRef) => {
  const { columns } = dataTable.props;
  const settings = dataTable.table.settings()[0];
  const payload = {
    ...dataRef.current.v4Payload,
    sort: {
      [columns[settings.aaSorting[0][0]].data]: settings.aaSorting[0][1]
    }
  };
  if (settings.oPreviousSearch.sSearch)
    payload.search = settings.oPreviousSearch.sSearch;
  return apiService.getCircuitsApplicationsList(payload);
};

Table.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  circuitData: PropTypes.object.isRequired,
  setFtTimestamp: PropTypes.func,
  history: PropTypes.object,
  setSelectedSite: PropTypes.func,
};

export default AppHoc(Table);

