import heatmapDataworker from "workerize-loader?name=[name].[contenthash:8]!./heatmapData.worker";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import reduxContext from "../../reducer";
import * as actions from "../../actions";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import AppHOC from "../../../../generics/AppHOC";
import Spinner from "../../../../common/Spinner";
import ErrorComponent from "../../../../common/ErrorComponent";
import HeatMap from "../../../../common/HeatMap";
import NoDataAvailable from "../../../../common/NoDataAvailable";
import TrendByCircuits from "./TrendByCircuits";
import { useMount } from "../../../../utils/genericCommon";
import i18n from "amdi18n-loader!../../../nls/i18n";
import { health } from "../../common/masterTableUtil";
import { BASE_URL } from "../../../../apis/apiConstants";

const [HbrRadioGroup, HbrRadio] = reactWrapper([
  "hbr-radio-group",
  "hbr-radio"
]);
const TOP_SELECTED = 10;
const tableTypes = [
  { value: "avail", label: i18n.circuit.ctSBTop },
  { value: "custom", label: i18n.trendAnalysisCustomList }
];

const TrendAnalysis = ({
  globalFilter: {
    timePeriodSelected,
    timeFilter,
    selectedSite,
    circuitsMasterTable,
    trendCircuitsApiData,
    trendHeatWidget,
    selectedTrendCircuits
  },
  history,
  actions
}) => {
  const [chartData, setChartData] = useState();
  const [isrender, setRender] = useState();
  const [selectedData, setSelectedData] = useState([]);
  const circuitsRef = useRef({});
  const isloading = useSelector(() => trendHeatWidget.isloading);
  const sortedDataRef = useRef([]);
  const tableTypeRef = useRef(tableTypes[0].value);
  const [maxTime, setMaxTime] = useState();
  const chartRef = useRef(null);
  const mount = useMount();

  useEffect(() => {
    if (selectedTrendCircuits.length > 0) actions.selectedTrendCircuits([]);
    if (selectedSite !== -1)
      sortedDataRef.current = circuitsMasterTable.data.filter(
        item => item.site_id === selectedSite && item.health !== health.gray
      );
    else
      sortedDataRef.current = circuitsMasterTable.data.filter(
        item => item.health !== health.gray
      );
    setSelectedData(sortedDataRef.current.slice(0, TOP_SELECTED));
  }, []);

  const heatMapAvailData = resData => {
    circuitsRef.current = {};
    let instance = heatmapDataworker();
    const lastProcessedTime =
      resData.header.last_processing_time < timeFilter.current_period[1]
        ? resData.header.last_processing_time
        : timeFilter.current_period[1];
    setMaxTime(lastProcessedTime);
    instance
      .processHeatMapData(
        resData,
        selectedTrendCircuits,
        sortedDataRef.current,
        timeFilter.current_period[0],
        lastProcessedTime
      )
      .then(data => {
        const [circuitsData] = data;
        circuitsRef.current = circuitsMasterTable.data;
        setChartData(circuitsData);
        setRender(true);
        instance.terminate();
      });
  };

  useEffect(() => {
    if (trendCircuitsApiData?.data && selectedTrendCircuits.length > 0) {
      chartRef.current = null;
      setRender(false);
      heatMapAvailData(trendCircuitsApiData);
    }
  }, [trendCircuitsApiData?.data]);

  useEffect(() => {
    if (selectedTrendCircuits.length === 0) {
      setChartData([]);
      setRender(true);
    }
  }, [selectedTrendCircuits]);

  const onYAxesLabelClick = useCallback(
    selectedCircuit => {
      const circuit = circuitsRef.current.filter((circuit) => circuit.circuit_display_name === selectedCircuit)[0];
      if (circuit) {
        sessionStorage.setItem(
          "circuit",
          JSON.stringify({
            ...circuit,
            overlay: localStorage.getItem("currentOverlay")
          })
        );
        history.push(
          `${BASE_URL}/circuits/${circuit.circuit_display_name.split(":").join("~")}`
        );
      }
    },
    [circuitsRef]
  );

  const onChange = useCallback(e => {
    const value = e.target.value;
    tableTypeRef.current = value;
    if (value === tableTypes[1].value) setSelectedData(sortedDataRef.current);
    else setSelectedData(sortedDataRef.current.slice(0, TOP_SELECTED));
  }, []);

  const chartCallback = chart => chartRef.current = chart;

  useEffect(() => {
    if (
      isloading === false && isrender === true
      && trendHeatWidget.resObj?.errorCode === 200
      && selectedTrendCircuits.length > 0
      && Array.isArray(chartData)
    ) {

      if (
        chartData.length > 0
        && selectedTrendCircuits.length < 10 && chartRef.current !== null
      ) {
        setRender(false);
        const chartNewData = chartData
          .filter(item => selectedTrendCircuits.includes(item.circuit_name));
          setTimeout(() => {
            if (mount.mounted === true) {
              setChartData(chartNewData);
              setRender(true);
            }
          });
      }
    }
    else chartRef.current = null;
  }, [selectedTrendCircuits]);

  return (
    <div>
      <span className="hbr-type-h2">{i18n.circuit.aSBTitle}</span>
      {isloading || !isrender ? (
        <div className="avail-heatmap-loader">
          <Spinner />
        </div>
      ) : isrender &&
        chartData &&
        trendHeatWidget.resObj.errorCode === 200 &&
        selectedTrendCircuits?.length > 0 ? (
        <div className="avail-heatmap">
          <HeatMap
            id="circuit_availability_heatmap"
            data={[...chartData]}
            min={timeFilter.current_period[0]}
            max={maxTime}
            selectedList={selectedTrendCircuits}
            timePeriod={timePeriodSelected}
            onYAxesLabelClick={onYAxesLabelClick}
            callbackFromParent={chartCallback}
          />
        </div>
      ) : chartData.length === 0 && selectedTrendCircuits?.length === 0 ? (
        <NoDataAvailable />
      ) : (
        trendHeatWidget.resObj.errorCode !== 200 && (
          <ErrorComponent
            {...trendHeatWidget.resObj}
            width="220px"
            className="large-dashlet-error"
          />
        )
      )}
      <span className="hbr-type-h2">{i18n.circuit.circuitTableTitle}</span>
      <HbrRadioGroup onHbr-click={onChange} noFieldset className="trendRadioBtn">
        {tableTypes.map(({ value, label }) => (
          <HbrRadio
            key={value}
            value={value}
            checked={value === tableTypeRef.current}
          >
            {label}
          </HbrRadio>
        ))}
      </HbrRadioGroup>
      <TrendByCircuits tableData={selectedData} view={tableTypeRef.current} />
    </div>
  );
};

TrendAnalysis.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  circuits: PropTypes.object,
  actions: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    circuits: state.vanalytics.circuits
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHOC(TrendAnalysis))
);
