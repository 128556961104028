import { convertedKpiV4, convertedKpiName } from "../../../../utils";
import i18n from "../../../../../nls/root/i18n"

const onClick = (kpiType, val, history, url, kpiDate) => {
  sessionStorage.setItem(
    "kpiTrendInfo",
    JSON.stringify({kpiType, val, kpiDate})
  )
  history.push(`${url}/predictivenetworks/anomalydetection/${convertedKpiName[kpiType]}/${val.tunnel_name}`)
}

export const getColumns = (history, kpiType, url, kpiDate) => {
  let kpi_name = convertedKpiV4[kpiType]
  return [
    {id:"site_name_col",
      prop: "site_name",
      name: i18n.predictiveNetworks.localSite,
      sortable: true,
      size: 130},
    {id:"remote_site_name_col",
      prop: "remote_site_name",
      name: i18n.predictiveNetworks.remoteSite,
      sortable: true,
      size: 130},
    {id:"city_col",
      prop: "city",
      name: i18n.predictiveNetworks.localLocation,
      sortable: true,
      size: 150},
    {id:"remote_city_col",
      prop: "remote_site_city",
      name: i18n.predictiveNetworks.remoteLocation,
      sortable: true,
      size: 150
    },
    {id:"tunnel_name_col" ,
      prop: "tunnel_name",
      name: i18n.predictiveNetworks.tunnelName,
      sortable: true,
      size: 450},
    {id:"kpi_name_col",
      prop: kpi_name,
      name: i18n.predictiveNetworks.anomalyCount,
      sortable: true,
      cellTemplate: (createElement, prop) => {
        return createElement("hbr-link",
          {
            "onHbr-focus": (e) => {
              e.preventDefault()
              onClick(kpiType, prop.model, history, url, kpiDate)
            }
          }, prop.model[kpi_name]);
      },
      size: 150
    },
    {id:"app_usage_col",
      prop: "app_usage",
      name: i18n.predictiveNetworks.applicationUsageInBytes,
      hidden: true,
    },
    {id:"display_app_usage_col",
      prop: "display_app_usage",
      name: i18n.predictiveNetworks.applicationUsage,
      sortable: true,
      size: 100},
    {id:"app_cnt_col",
      prop: "app_cnt",
      name: i18n.predictiveNetworks.applicationCount,
      sortable: true,
      size: 150},
    {id:"circuit_name_col",
      prop: "circuit_name",
      name: i18n.circuitQoeSiteCount,
      sortable: true,
      size: 250}
  ]
}
