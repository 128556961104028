import React, { useEffect, useState, Suspense } from "react";
import ADStackedBarChart from '../../ADStackedBarChart';
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../actions";
import AppHoc from "../../../../../../generics/AppHOC";
import reduxContext from "../../reducer";
import { convertedKpiV4 } from "../../../../utils";
import CustomTimeStamp from "../../../../../../generics/TimeStamp/customTimeStamp";
import css from "../../anomalyMagneticStyle.less";
import Spinner from "../../../../../../common/Spinner";
import {
  convertTsToLocal,
  getTimePeriodForAD,
} from "../../../../../../utils/displayTime";
import i18n from "../../../../../nls/root/i18n";
import KpiTableView from "../KpiTableView";
const NoDataAvailable = React.lazy(() =>
  import("../../../../../../common/NoDataAvailable")
);

const mapStateToProps = state => {
  return {
    adKey: state.vanalytics.adKey
  };
};
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

let timePeriodAD = getTimePeriodForAD();
let dateWeekAgo = timePeriodAD[0];
let todayDate = timePeriodAD[1];

const KpiChartView = props => {
  const [adChartData, setAdChartData] = useState({
    isLoading: true,
    error: null,
    data: []
  });
  const [usageType, setUsageType] = useState('')

  useEffect(() => {
    const chartDataSessionStorage = JSON.parse(
        sessionStorage.getItem("adSelectedChartData")
      );
    let usageType = chartDataSessionStorage.usageType
    let chartData = chartDataSessionStorage?.selectedChartData?.map(data => {
      let date = convertTsToLocal(data.entry_ts)
      let entry = {}
      entry["entry_ts"] = date
      entry[convertedKpiV4[chartDataSessionStorage?.kpiType]] = data.count
      return entry
    })
    setUsageType(usageType)
    setAdChartData({...adChartData, ...{ data: chartData, isLoading: false}})
    props.actions.saveKpiDateForTable(chartDataSessionStorage?.dateSelected)
  }, []);

  return (
    <div
      className={css["kpi-widget"]}
    >
      <div className="kpi-stack-chart">
        <div className="hbr-css__text-secondary-sm-bold">{usageType === i18n.predictiveNetworks.topTunnel
          ? i18n.predictiveNetworks.topTunnelsUsed
          : i18n.predictiveNetworks.allTunnelsUsed}</div>
        <div className="timestamp">
          <CustomTimeStamp
            prevTime={dateWeekAgo}
            currentTime={todayDate}
          />
        </div>
          {adChartData.data.length === 0 ? (
            <Suspense fallback={<Spinner />}>
              <NoDataAvailable />
            </Suspense>
          ) : (
            <ADStackedBarChart
              saveData={props.actions.saveKpiDateForTable}
              data={adChartData.data}
              disabledScrollbar={false}
              isTitleShown={true}
              chartsData={[]}
              chartProps={{
                chartId: "kpi-chart",
                leftTitleForY: i18n.predictiveNetworks.anomalyCounts,
              }}
              selectedBar={props.adKey.kpiDate}
            />
          )}
      </div>
      {adChartData.data.length > 0 && <KpiTableView />}
    </div>
  )
}

KpiChartView.propTypes = {
  actions: PropTypes.object,
  history: PropTypes.object.isRequired,
  adKey: PropTypes.object
};
export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHoc(KpiChartView))
);
