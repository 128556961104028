import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import * as actions from "../../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import reduxContext from "../../reducer";
import { isCustom } from "../../../../../../utils/common";
import i18nMessageBundle from "amdi18n-loader!../../../../../nls/i18n";
import AppHoc from "../../../../../../generics/AppHOC";
import ADTableView from "../../TableView";
import { elt_kpiTablePayload } from "../../../../../../config/reqConfig/anomalyDetection";
import { getColumns } from "./kpiTableConfig";
import { convertedKpiName } from "../../../../utils";
import ApiService from "../../../../../../config/api-config";
import useColumns from "@harbor/elements/utils/table/hooks/columns";
import TableStore from "@harbor/elements/utils/table/store";
import CustomTimeStamp from "../../../../../../generics/TimeStamp/customTimeStamp";
import {
    addUnitsFromDate
} from "../../../../../../utils/displayTime";
import css from "../../anomalyMagneticStyle.less";
import { BASE_URL } from "../../../../../../apis/apiConstants";
import { downloadAsCSVFile } from "../../../../../../utils/common";

const mapStateToProps = state => {
    return {
        adKey: state.vanalytics.adKey
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

let defaultSort = [["display_app_usage_col", "desc"]]

const KPITable = (props) => {
    const {
        timePeriodSelected,
        selectedCustomTime,
    } = props.globalFilter;
    const [kpitype, setkpiType] = useState("")
    const [selectedDate, setSelectedDate] = useState("")
    const [tableStore, setTableStore] = useState("")
    const [kpiSummaryStatus, setKpiSummaryStatus] = useState({
        isLoading: true,
        error: null
    });

    useEffect(async () => {
        let isMounted = true;
        const chartDataSessionStorage = JSON.parse(
            sessionStorage.getItem("adSelectedChartData")
        )
        let kpiType = chartDataSessionStorage.kpiType
        let usageType = chartDataSessionStorage.usageType
        let selectedDate = props.adKey.kpiDate
        setkpiType(kpiType)
        setSelectedDate(selectedDate)
        let columns = getColumns(props.history, kpiType, BASE_URL, selectedDate)
        const columnsConfig = () => (columns);
        const columnsHook = useColumns({
            config: columnsConfig,
            order: defaultSort
        });
        if (!isCustom(timePeriodSelected) || selectedCustomTime) {
            if (isMounted) {
                let tableStore = new TableStore({
                    query: { pageLength: 30 },
                    hooks: [columnsHook],
                    fetchData: async ({ query, setSource, setCount }) => {
                        if (!query.export) {
                            setKpiSummaryStatus({
                                ...kpiSummaryStatus,
                                ...{ isLoading: true }
                            });
                        }
                        let kpi_type = convertedKpiName[kpiType]
                        let requestPayload = elt_kpiTablePayload(selectedDate, usageType)
                        if (query.search !== "") requestPayload["search"] = query.search
                        if (query?.order[0]?.length > 0) {
                            let sort = {}
                            if (query?.order[0][0] === "display_app_usage") {
                                sort["app_usage"] = query?.order[0][1]
                            } else {
                                sort[query?.order[0][0]] = query?.order[0][1]
                            }
                            requestPayload["sort"] = sort
                        }
                        if (query.page) requestPayload["offset"] = query.page * query.pageLength
                        if (query.pageLength) requestPayload["size"] = query.pageLength
                        if (query.export) {
                            delete requestPayload.size
                            delete requestPayload.offset
                        }
                        const result = await ApiService.getKPITableInfo(requestPayload, kpiType);
                        if (result && !result.errorObject && !query.export) {
                            setCount(result?.data?.count, false);
                            setSource(result?.data?.data);
                            setKpiSummaryStatus({
                                ...kpiSummaryStatus,
                                ...{ isLoading: false }
                            });
                        } else if (query.export) {
                            let resData = result?.data?.data
                            const data = [columns.map(item => `"${item.name}"`).join(",")];
                            for (const item of resData) {
                                data.push([
                                    `"${item.site_name}"`,
                                    `"${item.remote_site_name}"`,
                                    `"${item.city}"`,
                                    `"${item.remote_site_city}"`,
                                    `"${item.tunnel_name}"`,
                                    `"${item[`${kpi_type}_anomaly_cnt`]}"`,
                                    `"${item.app_usage}"`,
                                    `"${item.display_app_usage}"`,
                                    `"${item.app_cnt}"`,
                                    `"${item.circuit_name}"`,
                                ].join(","));
                            }
                            downloadAsCSVFile(data.join("\n"), i18nMessageBundle.predictiveNetworks.anomaly);
                            delete query.export
                        } else {
                            setKpiSummaryStatus({
                                ...kpiSummaryStatus,
                                ...{ error: result.errorObject, isLoading: false }
                            });
                        }
                    },
                });
                setTableStore(tableStore)
            }
        } else if (props.adKey.adDate === null) {
            setKpiSummaryStatus({
                ...kpiSummaryStatus,
                ...{ isLoading: false }
            });
        }
        return () => {
            isMounted = false;
        };
    }, [props.adKey.kpiDate,
    props.globalFilter.onKpiRefresh,
    props.adKey.tunnelUsageType])

    return (tableStore &&
        <div className={css["anomaly-kpi-widget"]}>
            <div className="kpi-stack-table">
                <span className="timestamp">
                    <CustomTimeStamp
                        prevTime={selectedDate}
                        currentTime={addUnitsFromDate(new Date(selectedDate), 1, "h")}
                    />
                </span>
                <ADTableView
                    tableStore={tableStore}
                    type={"kpiType"}
                    header={`${kpitype} KPI`}
                    defaultSort={defaultSort}
                    exportText={i18nMessageBundle.export}
                    status={kpiSummaryStatus}
                />
            </div>
        </div>
    )
}

KPITable.propTypes = {
    globalFilter: PropTypes.object,
    actions: PropTypes.object,
    adKey: PropTypes.object,
    history: PropTypes.object.isRequired
};

export default reduxContext.withProvider(
    connect(mapStateToProps, mapDispatchToProps)(AppHoc(KPITable))
);
