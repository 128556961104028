import { ACTIONS } from "./actions";
import { createContext } from "@cisco-dna/redux-utils";

const reduxContext = createContext();
const storeKey = "vanalytics.adKey";

export default reduxContext;

const initialState = {
  adDate: "",
  kpiDate: "",
  adStackedBarChartInfo: {},
  tunnelUsageType: "topTunnel"
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.GET_AD_DATE:
      return {
        ...state,
        adDate: payload,
        kpiDate: payload
      };
    case ACTIONS.GET_KPI_DATE:
      return {
        ...state,
        kpiDate: payload
      };
    case ACTIONS.GET_BAR_CHART_DATE:
      return {
        ...state,
        adStackedBarChartInfo: payload
      };
    case ACTIONS.UPDATE_USAGE_TYPE:
      return {
        ...state,
        tunnelUsageType: payload
      };
    default:
      return state;
  }
};

reduxContext.injectReducer({
  STORE: storeKey,
  REDUCER: reducer,
  ACTION_TYPE: Object.values(ACTIONS)
});
