import React, { Suspense } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import i18n from "../../../../nls/root/i18n";
import Spinner from "../../../../../common/Spinner";

const ErrorComponent = React.lazy(() =>
  import("../../../../../common/ErrorComponent")
);

const [HbrTable, HbrInput, HbrIcon, HbrPagination, HbrButton, HbrSpinner, HbrTooltip] =
  reactWrapper(["hbr-table", "hbr-input", "hbr-icon", "hbr-pagination", "hbr-button", "hbr-spinner", "hbr-tooltip"
  ]);

const ADTableView = (props) => {

  const {
    source,
    columns,
    query,
    count
  } = props.tableStore.getState();

  const { search, pageLength, page } = query;

  const onSearch = (e) => {
    props.tableStore.setQuery({ search: e.target.value });
  }

  return (
    <div>
      <div className="hbr-css__layout-row-xs">
        <div className="hbr-css__text-heading-lg-bold">{props.header}</div>
        {props.type === "KpiDetails" &&
          <>
            <HbrTooltip hoist>
              <div slot="content"> {i18n.predictiveNetworks.anomalyTableInfo}</div>
              <HbrIcon name="info" sentiment="neutral"></HbrIcon>
            </HbrTooltip>
            {props.tagInfo && source[0]?.usedTunnelsX !== undefined &&
              <hbr-tag>
                {`${source[0]?.usedTunnelsX} out of ${source[0]?.totalTunnelsY} tunnels scanned`}
              </hbr-tag>}
          </>
        }
      </div>
      {props.type === "kpiType" &&
        <div className="search-and-export">
          <HbrInput
            id='hbr-search-field'
            placeholder="Search"
            onHbr-clear={onSearch}
            clearable
            onkeydown={(e) => {
              if (e.code === "Enter") onSearch(e);
            }}
            style = {{ padding: "20px 10px 10px 0px" }}
            value={search}
          >
            <HbrIcon
              slot="prefix"
              name="magnifying-glass"
              sentiment="neutral"
            />
          </HbrInput>
          <div id="hbr-count-field" className="hbr-css__text-secondary-sm">{`${count} tunnels`}</div>
          <HbrButton
            id='hbr-export-button'
            variant="outline"
            onClick={() => {
              props.tableStore.setQuery({ export: "export" })
            }}
          >
            <HbrIcon slot="prefix" sentiment="interact" name="upload-simple" />
            {props.exportText}
          </HbrButton>
        </div>
      }
      <div className="harborTableView">
        <HbrTable
          serverSide={true}
          source={source}
          columns={columns}
          useClipboard={false}
          canFocus={false}
          style={{ height: props.type === "kpiType" ? "400px" : "200px", width: "100%", paddingBottom: "20px" }}
          externalSorting={true}
          onHbr-before-sort={(event) => {
            const { column, order } = event.detail;
            if (order) {
              props.tableStore.hooks[0].sort({ order: [[column.id, order]] });
            } else {
              if (column.id.includes(query.order[0][0])) {
                if (query.order[0][1] === 'desc') {
                  props.tableStore.hooks[0].sort({ order: [[column.id, 'asc']] });
                } else {
                  props.tableStore.hooks[0].sort({ order: [[column.id, 'desc']] });
                }
              } else {
                props.tableStore.hooks[0].sort({ order: props.defaultSort });
              }
            }
          }}
        >
          <div className="hbr-css__table-empty-state">
            {props.status.isLoading
              ? (<HbrSpinner direction="column" size="small">
                <div slot="label">{i18n.loading}</div>
              </HbrSpinner>)
              : props.status.error
                ? (<Suspense fallback={<Spinner />}>
                  <ErrorComponent
                    {...props.status.error}
                    className="small-dashlet-error"
                    width="220px"
                  />
                </Suspense>)
                : (source.length === 0 && (
                  <div slot="empty" className="hbr-css__table-empty-state">
                    <hbr-icon style={{ width: "85px", height: "75px" }} name="hbr-empty-state"></hbr-icon>
                    <span className="hbr-css__text-secondary-sm">{i18n.emptyData}</span>
                  </div>)
                )}
          </div>
        </HbrTable>
        {props.type === "kpiType" &&
          <HbrPagination
            id="ad-pagination"
            pageSize={pageLength}
            itemCount={count}
            page={page}
            orderArray={[2, 1, 0]}
            sizeChanger={true}
            onHbr-size-changed={(e) => {
              props.tableStore.setQuery({ pageLength: e.detail.pageSize, page: 0 });
            }}
            onHbr-page-changed={(e) => {
              props.tableStore.setQuery({ page: e.detail });
            }}
          />
        }
      </div>
    </div>
  )
}

ADTableView.propTypes = {
  tableStore: PropTypes.object.isRequired,
  type: PropTypes.string,
  header: PropTypes.string,
  tagInfo: PropTypes.string,
  defaultSort: PropTypes.array,
  exportText: PropTypes.string,
  status: PropTypes.object
}

export default ADTableView




