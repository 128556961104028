import React, { useEffect, useState, Suspense } from "react";
import { PropTypes } from "prop-types";
import { withRouter} from 'react-router-dom';
import i18n from "../../../../../nls/root/i18n";
import css from "../../anomalyMagneticStyle.less";
import { convertedKpiName } from "../../../../utils";
import Spinner from "../../../../../../common/Spinner";
import ApiService from "../../../../../../config/api-config";
import { BASE_URL } from "../../../../../../apis/apiConstants";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import ErrorComponent from "../../../../../../common/ErrorComponent";
import NoDataAvailable from "../../../../../../common/NoDataAvailable";
import CustomTimeStamp from "../../../../../../generics/TimeStamp/customTimeStamp";
import StackedMultiLineChart from "../../../../../../common/StackedMultiLineChart";
import { elt_kpiTrendPayload } from "../../../../../../config/reqConfig/anomalyDetection";
import { convertTsToLocal,
    shortDisplayTime,
    addUnitsFromDate,
    subtractUnitsFromDate } from "../../../../../../utils/displayTime";
import AppHoc from "../../../../../../generics/AppHOC";

const [HbrBreadcrumb, HbrBreadcrumbItem, HbrCard] = 
reactWrapper(["hbr-breadcrumb", "hbr-breadcrumb-item", "hbr-card"])

const KpiTrendChart = (props) => {
    const [chartTrendData, setChartTrendData] = useState({
        isLoading: true,
        error: null,
        data: [],
        chartType: null
    });
    const [usageTrendData, setUsageTrendData] = useState({
        isLoading: true,
        error: null,
        data: []
    });
    const [kpiType, setKpiType] = useState([]);
    const [timeFrame, setTimeFrame] = useState([]);
    const [kpSelectedDate, setKpSelectedDate] = useState("");

    const kpiInfoFun = async () => {
        let kpiInfo = JSON.parse(sessionStorage.getItem("kpiTrendInfo"))
        setKpiType(kpiInfo)
        setKpSelectedDate(kpiInfo.kpiDate)
        if (kpiInfo.val) {
            setChartTrendData({
                ...chartTrendData,
                ...{ data: [], isLoading: true }
            });
            setUsageTrendData({...usageTrendData,
                ...{ data: [], isLoading: true }})
            let apiKeys = [kpiInfo.kpiType, kpiInfo.val.tunnel_name];
            let res = await ApiService.getKPITrendData(elt_kpiTrendPayload(kpiInfo.kpiDate, apiKeys), apiKeys);
            if (!res.errorObject) {
                let chartData = res.data.data.map(data => {
                    let date = convertTsToLocal(data.entry_ts);
                    let entry = {};
                    let kpi_name = convertedKpiName[kpiInfo.kpiType]
                    entry.date = new Date(date);
                    entry[kpiInfo.kpiType] = data[kpi_name];
                    entry["is_anomaly"] = data[`${kpi_name}_anomaly`];
                    return entry;
                })
                let usageData = res.data.data.map(data => {
                    let date = convertTsToLocal(data.entry_ts);
                    let usgEntry = {};
                    usgEntry.date = new Date(date);
                    usgEntry["app_usage"] = data.app_usage
                    return usgEntry;
                })
                setChartTrendData({...chartTrendData,
                    ...{ data: chartData, isLoading: false, chartType: kpiInfo.kpiType }});
                setUsageTrendData({...usageTrendData,
                        ...{ data: usageData, isLoading: false }})
            } else {
                setChartTrendData({...chartTrendData,
                    ...{error: res.errorObject, isLoading: false}});
                setUsageTrendData({...usageTrendData,
                    ...{error: res.errorObject, isLoading: false}})
            }
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        kpiInfoFun()
    }, [props.globalFilter.onKpiRefresh])

    useEffect(async() => {
        let currentTime = shortDisplayTime(new Date())
        let endTime = shortDisplayTime(addUnitsFromDate(new Date(kpSelectedDate), 24, "h"))
        let startTime = shortDisplayTime(subtractUnitsFromDate(new Date(kpSelectedDate), 6, "d"))
        setTimeFrame([startTime, endTime > currentTime ? currentTime : endTime])
    }, [kpSelectedDate])

    return (
        <div>
            <div className={css["Kpi-trend-breadcrumb"]}>
                <HbrBreadcrumb>
                    <HbrBreadcrumbItem
                         onClick={() => props.history.push(`${BASE_URL}/predictivenetworks/anomalydetection`)}
                    >
                        {i18n.predictiveNetworks.anomaly}
                    </HbrBreadcrumbItem>
                    <HbrBreadcrumbItem
                        onClick={() => props.history.push(`${BASE_URL}/predictivenetworks/anomalydetection/${convertedKpiName[kpiType.kpiType]}`)}
                    >
                        {`${kpiType.kpiType} ${i18n.predictiveNetworks.kpiDetails}`}
                    </HbrBreadcrumbItem>
                </HbrBreadcrumb>
            </div>
            <div className={css["Kpi-trend-heading"]}>
                <div className="Kpi-trend-usage-heading">
                    {i18n.predictiveNetworks.anomalyAndUsageTrend}
                </div>
                <div className="kpi-trend-ts">
                    <CustomTimeStamp
                        prevTime={timeFrame[0]}
                        currentTime={timeFrame[1]}
                    />
                </div>
            </div>
            <div className={css["Kpi-trend-sec-heading"]}>
                <div className={css["Kpi-trend-att"]}>
                    <div>
                        <div className="hbr-css__text-primary-xs-semibold">Tunnel Name</div>
                        <div className="hbr-css__text-primary-xs">{kpiType?.val?.tunnel_name}</div>

                    </div>
                    <div>
                        <div className="hbr-css__text-primary-xs-semibold">Local Site</div>
                        <div className="hbr-css__text-primary-xs">{kpiType?.val?.site_name}</div>

                    </div>
                    <div>
                        <div className="hbr-css__text-primary-xs-semibold">Remote Site</div>
                        <div className="hbr-css__text-primary-xs">{kpiType?.val?.remote_site_name}</div>
                    </div>
                    <div>
                        <div className="hbr-css__text-primary-xs-semibold">Local Location</div>
                        <div className="hbr-css__text-primary-xs">{kpiType?.val?.city}</div>
                    </div>
                    <div>
                        <div className="hbr-css__text-primary-xs-semibold">Remote Location</div>
                        <div className="hbr-css__text-primary-xs">{kpiType?.val?.remote_site_city}</div>
                    </div>
                </div>
            </div>
            <HbrCard className={css["kpi-trend"]}>
                {chartTrendData.isLoading
                    ? (<Spinner />)
                    : chartTrendData.error
                        ? (<Suspense fallback={<Spinner />}>
                            <ErrorComponent
                                {...chartTrendData.error}
                                className="small-dashlet-error"
                                width="220px"
                            />
                            </Suspense>)
                        : chartTrendData.data.length === 0
                            ?(<Suspense fallback={<Spinner />}>
                                <NoDataAvailable
                                    text={i18n.predictiveNetworks.noAnomaly}
                                />
                                </Suspense>)
                            : (chartTrendData.data.length > 0 &&
                                <div>
                                    <StackedMultiLineChart
                                        id="kpi-trend-chart"
                                        data={chartTrendData.data}
                                        scrollbar={true}
                                        label={chartTrendData.chartType}
                                        rangeChart={true}
                                        stacked={false}
                                        selectedHour={kpSelectedDate}
                                        legendPosition="down"
                                    />
                                </div>)
                    }
            </HbrCard>
            <HbrCard className={css["kpi-usage-trend"]}>
                {usageTrendData.isLoading
                    ? (<Spinner />)
                    : usageTrendData.error
                        ? (<Suspense fallback={<Spinner />}>
                            <ErrorComponent
                                {...usageTrendData.error}
                                className="small-dashlet-error"
                                width="220px"
                            />
                            </Suspense>)
                        : usageTrendData.data.length === 0
                            ?(<Suspense fallback={<Spinner />}>
                                <NoDataAvailable
                                    text={i18n.allSiteUsageLabel}
                                />
                                </Suspense>)
                            : (usageTrendData.data.length > 0 &&
                                <div>
                                    <StackedMultiLineChart
                                        id="kpi-Usage-trend-chart"
                                        data={usageTrendData.data}
                                        scrollbar={true}
                                        label={"Usage"}
                                        rangeChart={false}
                                        stacked={false}
                                        selectedHour={kpSelectedDate}
                                        legendPosition="down"
                                    />
                                </div>)
                    }
            </HbrCard>
        </div>
    )
}

KpiTrendChart.propTypes = {
    history: PropTypes.object.isRequired,
    globalFilter: PropTypes.object,
}
 
export default AppHoc(withRouter(KpiTrendChart))
