import React, { memo, useEffect, useMemo, useCallback, useRef } from "react";
import { PropTypes } from "prop-types";
import { DtableWrapper } from "dnx-web-components/dist/index-with-deps-react.min";
import DTable from "dnx-web-components/dist/table.min";
import Spinner from "../../../../common/Spinner";
import ErrorComponent from "../../../../common/ErrorComponent";
import { genericViewFromComponent } from "@cisco-dna/react-utils";
import { shortDisplayTime } from "../../../../utils/displayTime";
import { defaultTableProps } from "./tableConfig";

const DNXTable = DtableWrapper(DTable);

const Table = ({ loader, error, data, timePeriod }) => {
  const tableRef = useRef();

  const adjustTable = useCallback(() => {
    if (tableRef.current !== null)
      tableRef.current.table.getDataTable().columns.adjust();
  }, []);

  useEffect(() => {
    if (loader === false && error === null) adjustTable();
    else tableRef.current = null;
  }, [loader, error]);

  useEffect(() => {
    window.addEventListener("resize", adjustTable);
    return () => window.removeEventListener("resize", adjustTable);
  }, []);

  useMemo(() => {
    if (loader === false && error === null && timePeriod[0]) {
      const startDate = shortDisplayTime(timePeriod[0]);
      const endDate = shortDisplayTime(timePeriod[1]);
      defaultTableProps.tools[2].factory = genericViewFromComponent(
        () => `${startDate} - ${endDate}`,
        "stamp flex-items"
      );
    }
  });

  return (
    <div className="table-section">
      {loader === true ? (
        <Spinner />
      ) : error !== null ? (
        <ErrorComponent
          {...error}
          className="small-dashlet-error"
          width="220px"
        />
      ) : (
        <DNXTable ref={tableRef} {...defaultTableProps} data={data} />
      )}
    </div>
  );
};

Table.propTypes = {
  loader: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  timePeriod: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default memo(Table);
