import React, { useState, useEffect, useCallback, useRef } from "react";
import { PropTypes } from "prop-types";
import Spinner from "../../../common/Spinner";
import ErrorComponent from "../../../common/ErrorComponent";
import NoDataAvailable from "../../../common/NoDataAvailable";
import StackedMultiLineChart from "../../../common/StackedMultiLineChart";
import { getDiaTrendData } from "../actions";
import { useMount } from "../../../utils/genericCommon";
import { addBytes } from "../../../utils/format";
import {
  displayDateTime,
  getTimestampFromUTCString
} from "../../../utils/displayTime";
import i18n from "../../nls/root/i18n";
import css from "../circuitsMagneticStyle.less";
import { defaultTextValue } from "../../../utils/enums";
import { chartPrimaryColors } from "../../../utils/colors";
import { compareAndMatchDateTime, getIsHourlyData, getTimeInterval } from "../../../utils/common";

const SideBar = ({ circuitsData, globalFilter }) => {
  const [loader, setLoader] = useState(true);
  const dataRef = useRef({
    timeRange: circuitsData.query.timeRange.slice(0),
    error: null,
    data: [],
    seriesColors: {
      dia: chartPrimaryColors[1],
      tunnel: chartPrimaryColors[0]
    },
    reverseOrder: false
  });
  const mount = useMount();
  // load bandwidth usage graph data on initial load
  useEffect(() => {
    const getData = async () => {
      const res = await getDiaTrendData(globalFilter.globalV4Payload);
      if (mount.mounted === true) {
        if (res.errorObject instanceof Object)
          dataRef.current.error = res.errorObject;
        else if (
          res.data instanceof Object && Array.isArray(res.data.data)
          && res.data.data.length > 0
        ) {
          // create entry object from response data
          const timeInterval = getTimeInterval(globalFilter.globalV4Payload.time_frame);
          const min = dataRef.current.timeRange[0];
          const resData = res.data.data;
          const entryTime = {};
          for (const item of resData) {
            const date = getTimestampFromUTCString(item.entry_ts);
            if (date < min) continue;
            if (Number.isFinite(item.usage)) {
              if (entryTime[date] instanceof Object)
                entryTime[date][item.flow_type] = item.usage;
              else entryTime[date] = { date, [item.flow_type]: item.usage };
            }
          }

          // create entry array
          const entryData = Object.values(entryTime);
          // generate graph data from entry array
          if (entryData.length > 0) {
            const missedEntries = [];
            const lastIndex = entryData.length - 1;

            let diaUsage = 0;
            let tunnelUsage = 0;
            let validTunnelUsage = false;
            entryData.forEach((item1, index) => {
              // create graph hover tooltip data
              const { values, str } = addBytes([
                item1.dia || 0,
                item1.tunnel || 0
              ]);
              item1.tooltipData = {
                date: displayDateTime(item1.date),
                dia: Number.isFinite(item1.dia)
                  ? values[0].str
                  : defaultTextValue,
                tunnel: Number.isFinite(item1.tunnel)
                  ? values[1].str
                  : defaultTextValue,
                total: str
              };
              if (Number.isFinite(item1.dia)) diaUsage += item1.dia;
              if (Number.isFinite(item1.tunnel)) {
                tunnelUsage += item1.tunnel;
                validTunnelUsage = true;
              }
              const item2 = index < lastIndex ? entryData[index + 1] : null;
              if (item2 instanceof Object) {
                /* add an entry to extend lines till current entry's end range if
              next entry is after an hour */
                if (item2.date > item1.date + timeInterval) {
                  missedEntries.push({ ...item1, date: item1.date + (timeInterval -1) });
                } else {
                  /* add an entry in current entry's end range if dia or tunnel
                    in current entry is missing in next entry */
                  const item = {
                    ...item1,
                    date: item1.date +  (timeInterval -1)
                  };
                  let missedDatapoint = false;
                  if (Number.isFinite(item1.dia)) {
                    if (Number.isFinite(item2.dia) === false) {
                      item.dia = 0;
                      missedDatapoint = true;
                    } else item.dia = item2.dia;
                  }
                  if (Number.isFinite(item1.tunnel)) {
                    if (Number.isFinite(item2.tunnel) === false) {
                      item.tunnel = 0;
                      missedDatapoint = true;
                    } else item.tunnel = item2.tunnel;
                  }
                  if (missedDatapoint === true) missedEntries.push(item);
                }
                /* add an entry for the last entry to extend lines till its
                end range */
              }
            });
            // set graph data and sort based on entry time

            let diaTunnelData = entryData.concat(missedEntries)
              .sort((item1, item2) => item1.date > item2.date ? 1 : -1);
            if (getIsHourlyData(globalFilter.globalV4Payload.time_frame)) {
               diaTunnelData = compareAndMatchDateTime(diaTunnelData, globalFilter, "date");
            }
            dataRef.current.data = diaTunnelData;
            if (diaUsage > tunnelUsage && validTunnelUsage === true) {
              dataRef.current.seriesColors = {
                tunnel: chartPrimaryColors[1],
                dia: chartPrimaryColors[0]
              };
              dataRef.current.reverseOrder = true;
            } else
              dataRef.current.seriesColors = {
                dia: chartPrimaryColors[1],
                tunnel: chartPrimaryColors[0]
              };
          }
        }
        setLoader(false);
      }
    };
    getData();
  }, []);

  // customize the chart
  const customizeChart = useCallback(chart => {
    if (dataRef.current.reverseOrder === true) {
      chart.series.values[0].name = i18n.circuit.tunnel;
      chart.series.values[1].name = i18n.circuit.ddcDia;
    } else {
      chart.series.values[0].name = i18n.circuit.ddcDia;
      chart.series.values[1].name = i18n.circuit.tunnel;
    }
    chart.series.values.forEach(series => {
      series.adapter.add("tooltipHTML", (html, target) => {
        const data = target.tooltipDataItem.dataContext;
        if (data && data.tooltipData) {
          const series = [...chart.series.values].reverse();
          html = `
            <div class="ttip-header">
              <span class="date">${data.tooltipData.date}</span>
            </div>
            <table class="ttip-content">
            <tr>
              <td>${i18n.circuit.totalUsage}</td>
              <td>${data.tooltipData.total}</td>
            </tr>
            ${series
              .map(item =>
                !item.isHidden
                  ? `
                  <tr>
                    <td>
                      <div class="flex-items">
                        <span
                          class="square-legend"
                          style="background-color:${item.fill.hex};"
                        ></span>
                        ${item.name}
                      </div>
                    </td>
                    <td>${data.tooltipData[item.dataFields.valueY]}</td>
                  </tr>
                  `
                  : ""
              )
              .join("")}
            </table>`;
        }
        return html;
      });
    });
    chart.yAxes.values[0].numberFormatter.numberFormat = "#.b";
    chart.yAxes.values[0].maxPrecision = 0;
    chart.yAxes.values[0].renderer.minGridDistance = 90;
  }, []);

  return (
    <div className={`${css.sidebar} flex-column-full`} data-testid="dia-trend">
      {loader === true ? (
        <Spinner />
      ) : dataRef.current.error !== null ? (
        <ErrorComponent
          {...dataRef.current.error}
          className="small-dashlet-error"
          width="220px"
        />
      ) : dataRef.current.data.length === 0 ? (
        <NoDataAvailable />
      ) : (
        <div className="chart-container  flex-main-content">
          <StackedMultiLineChart
            id="circuit-dia-trend"
            label={i18n.c360.ucYLabel}
            scrollbar
            scrollBarInitialZoom
            colorset={dataRef.current.seriesColors}
            callbackFromParent={customizeChart}
            data={dataRef.current.data}
            min={globalFilter.timeFilter.current_period[0]}
            max={globalFilter.timeFilter.current_period[1]}
          />
        </div>
      )}
    </div>
  );
};

SideBar.propTypes = {
  circuitsData: PropTypes.object.isRequired,
  globalFilter: PropTypes.object
};

export default SideBar;
