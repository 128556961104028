import React, { Suspense } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

import _ from "lodash";

import BulletColumnAndLineChart from "../../../../../common/BulletColumnAndLineChart";
import Spinner from "../../../../../common/Spinner";

//utils
import { getTipBulletColumnChart } from "../../../../../utils/common";

// lazy loading components
const NoDataAvailable = React.lazy(() =>
  import("../../../../../common/NoDataAvailable")
);
const ErrorComponent = React.lazy(() =>
  import("../../../../../common/ErrorComponent")
);

// i18nMessageBundle
import i18nMessageBundle from "amdi18n-loader!../../../../nls/i18n";

//style
import css from "../../../insightsAppMagneticStyle.less";

const [HbrCard] = reactWrapper(["hbr-card"]);

const PathQosUserExpChart = props => {
  const { selectedSite } = props.globalFilter;
  switch (true) {
    case props.hasFailed:
      return (
        <div
          className={css["qos-user-exp-chart"]}
          data-cy="pathQosUserExpChartError"
        >
          <Suspense fallback={<Spinner />}>
            <ErrorComponent
              {...props.errorMessage}
              width={"200px"}
              className={"super-small-dashlet-error"}
            />
          </Suspense>
        </div>
      );
    case props.userChartData &&
      props.userChartData.length === 0 &&
      props.isDone:
      return (
        <div
          className={css["qos-user-exp-chart"]}
          data-cy="pathQosUserExpChartNoData"
        >
          <div className="no-data-chart">
            <NoDataAvailable
              text={
                i18nMessageBundle.predictiveNetworks.networkQoeMetricChartNoData
              }
            />
          </div>
        </div>
      );
    case props.isDone && !_.isEmpty(props.userChartData):
      return (
        <HbrCard
          className={css["qos-user-exp-chart"]}
          data-cy="pathQosUserExpChart"
        >
          {
            <BulletColumnAndLineChart
              data={props.userChartData}
              chartsData={props.chartsData}
              callbackFromParent={props.callbackFromParent}
              showSynchronization={true}
              disabledScrollbar={false}
              isZoomDisabled={false}
              chartProps={{
                site: selectedSite,
                isSeriesConnected: false,
                chartId: "sidePanelUserExpChart",
                tooltipHTML: getTipBulletColumnChart(),
                title: i18nMessageBundle.appGroupPerSiteSidePanelTitle,
                zoomInDaysCount: 1,
                leftTitleForY:
                  i18nMessageBundle.appGroupPerSite.userExpChartActiveUsrLabel,
                rightTitleForY: i18nMessageBundle.appGroupPerSite.meanQuality,
                legendLabels: [
                  i18nMessageBundle.appGroupPerSite.userExpChartRecomLabel,
                  i18nMessageBundle.appGroupPerSite
                    .userExpChartCurrentPathLabel,
                  i18nMessageBundle.appGroupPerSite.userExpChartActiveUsrLabel
                ],
                backgroundStyle: {
                  background: "var(--hbr-color-neutral-bg, #ffffff)"
                }
              }}
              isTitleShown={true}
              isScrollbarGripVisible={true}
            />
          }
        </HbrCard>
      );
    default:
      return (
        <div className="center-spinner">
          <Spinner />
        </div>
      );
  }
};

PathQosUserExpChart.propTypes = {
  chartProps: PropTypes.object.isRequired,
  callbackFromParent: PropTypes.func,
  chartsData: PropTypes.array.isRequired,
  userChartData: PropTypes.array,
  globalFilter: PropTypes.object,
  hasFailed: PropTypes.bool,
  isDone: PropTypes.bool,
  errorMessage: PropTypes.object
};

export default PathQosUserExpChart;
