import React, { useState, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import reduxContext from "../../../reducer";
import * as actions from "../../../actions";
import AppHOC from "../../../../../generics/AppHOC";
import { DtableWrapper } from "dnx-web-components/dist/index-with-deps-react.min";
import { isCustom } from "../../../../../utils/common";

import { getColumns, getTools } from "../../../CircuitsTableView/circuitsTableViewConfig";
import { createRequest } from "../../../../../utils/query-utils";

import ApiService from "../../../../../config/api-config";

import { getTrendCircuitsAvailPayload } from "../../../../../config/reqConfig/circuits";
import storeKey from "../../../../../generics/AppHOC/reducer";
import table from "dnx-web-components/dist/table.min";

let selected_circuit_names = []
let selected_circuit_payload_names = []
let isCallingHeatmap = true;

const TrendByCircuits = (props) => {
  const {
    timeFilter,
    timePeriodSelected,
    selectedCustomTime,
    selectedOverlay,
    selectedSite,
    currentTimeStamp,
    globalV4Payload,
  } = props.globalFilter;
  const [tableComp, seTableComp] = useState();
  const DnxTable = DtableWrapper(table);
  const tableRef = useRef();

  const getExportData = dataTable => {
    const { columns } = dataTable.props;
    const settings = dataTable.table.settings()[0];
    const payload = {
      ...props.globalFilter.globalV4Payload,
      sort: {
        [columns[settings.aaSorting[0][0]].data]: settings.aaSorting[0][1]
      }
    };
    if (settings.oPreviousSearch.sSearch)
      payload.search = settings.oPreviousSearch.sSearch;
    return ApiService.getCircuitsTableList(payload);
  };

  useEffect(() => {

    if (!isCustom(timePeriodSelected) || selectedCustomTime) {
      const getTableProps = () => {
        const tableConfig = {
          columns: getColumns(props.history),
          idData: "circuit_display_name",
          serverSide: true,
          tools: getTools(getExportData),
          getData: getData,
          dtOptions: {
            stateSave: false,
            scrollX: true,
            scrollY: 300,
            stateDuration: -1,
            order: [[5, "asc"]],
            drawCallback: function () {
              if (!tableRef.current) return;
              if (isCallingHeatmap) {

                if (selected_circuit_names?.length > 0) {

                  tableRef.current.table.selectRowsUndraw(selected_circuit_names);
                  isCallingHeatmap = false;
                  props.heatMapApiStatus({ isloading: true, resObj: undefined });
                  props.selectedTrendCircuits(selected_circuit_names);
                  props.resetHeatMapAvail({});
                  let reqPayload = getTrendCircuitsAvailPayload(
                    storeKey.store.getState().vanalytics.app.globalFilter.timeFilter
                      .current_period,
                    selected_circuit_payload_names
                  );

                  ApiService.getTrendCircuitsAvailability(reqPayload).then(res => {
                    if (!res.errorObject) {
                      if (Array.isArray(res.data?.data)) {
                        let circuitsList = res.data.data;
                        for (const item of circuitsList) {
                          const [, color] = item.circuit_name.split(":");
                          item.circuit_name = `${item.host_name}:${color}`;
                        }
                      }
                      props.setTrendAvail(res)
                    } else {

                      let payload = { isloading: false, resObj: res.errorObject }
                      props.heatMapApiStatus(payload)
                    }
                  })
                  isCallingHeatmap = false;
                }
              }
            }
          },
          columnSearch: true,
          columnSearchEnabled: true,
          dataTableClasses: "nowrap",
          rowSelect: true,
          multiSelectLimit: 10,
          rowSelectCallback: (item, itemList, itemObj, itemObjList) => {
            const isSelection = (itemList.includes(item) || item === "SELECT_ALL");
            if (itemList?.length > 0) {
              if (
                isSelection === true
              ) {
                let circuitsNames = []
                let circuitsPayloadNames = []
                itemObjList.forEach(data => {
                  circuitsNames.push(data.circuit_display_name);
                  circuitsPayloadNames.push(data.circuit_name)
                });

                isCallingHeatmap = false;
                props.heatMapApiStatus({ isloading: true, resObj: undefined });
                props.selectedTrendCircuits(circuitsNames);
                props.resetHeatMapAvail({});
                let reqPayload = getTrendCircuitsAvailPayload(
                  storeKey.store.getState().vanalytics.app.globalFilter.timeFilter
                    .current_period,
                  circuitsPayloadNames
                );

                ApiService.getTrendCircuitsAvailability(reqPayload).then(res => {
                  if (!res.errorObject) {
                    if (Array.isArray(res.data?.data)) {
                      let circuitsList = res.data.data;
                      for (const item of circuitsList) {
                        const [, color] = item.circuit_name.split(":");
                        item.circuit_name = `${item.host_name}:${color}`;
                      }
                    }
                    props.setTrendAvail(res)
                  } else {

                    let payload = { isloading: false, resObj: res.errorObject }
                    props.heatMapApiStatus(payload)
                  }
                })
              } else {
                props.selectedTrendCircuits(itemList);
              }
            } else {
              props.selectedTrendCircuits([]);
            }
          }
        };
        return tableConfig;
      };

      const getData = async (aoData) => {
        let dTable = null;
        props.heatMapApiStatus({ isloading: true, resObj: undefined });
        props.selectedTrendCircuits([]);
        props.resetHeatMapAvail({});
        const defaultRequest = { ...globalV4Payload };
        if (tableRef.current) {
          dTable = tableRef.current.table;
          if (dTable?.store?.state?.search_simpleQuery) {
            defaultRequest.search = dTable.store.state.search_simpleQuery;
          }
        }
        defaultRequest.sort = { availability: "asc" };
        const requestPayload = createRequest(defaultRequest, aoData, dTable);
          requestPayload.size = props.view == "custom" ? 25 : 10;
        if (props.view == "custom") {
          requestPayload.offset = 0;
        }
        const result = await ApiService.getCircuitsTableList(requestPayload)
        isCallingHeatmap = true;
        const selectedAppNames = result.data.data
          .slice(0, 10);
        let circuitsNames = [];
        let circuitsPayloadNames = [];
        selectedAppNames.forEach(data => {
          circuitsPayloadNames.push(data.circuit_name);
          circuitsNames.push(data.circuit_display_name);
        });
        selected_circuit_names = circuitsNames;
        selected_circuit_payload_names = circuitsPayloadNames
        if (result && !result.errorObject) {
          const siteTableData = result.data.data;
          return {
            response: siteTableData,
            count: props.view != "custom" && result.data.count > 10 ? 10 : result.data.count

          }
        } else return {
          response: [],
          count: 0
        }
      };
      const tableProps = getTableProps();
      seTableComp(<DnxTable {...tableProps} ref={tableRef} />);
    }
  }, [
    timeFilter.current_period && timeFilter.current_period[0],
    timeFilter.previous_period && timeFilter.current_period[1],
    selectedOverlay,
    selectedSite,
    currentTimeStamp && currentTimeStamp,
    props.view
  ]);

  return (
    <div className="flex-column flex-main-content">
      {tableComp}
    </div>
  );
};

TrendByCircuits.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  tableData: PropTypes.array,
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  view: PropTypes.string,
  heatMapApiStatus: PropTypes.func,
  selectedTrendCircuits: PropTypes.func,
  resetHeatMapAvail: PropTypes.object,
  setTrendAvail: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default reduxContext.withProvider(
  connect(null, mapDispatchToProps)(AppHOC(TrendByCircuits))
);

