import React, { memo } from "react";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import Availability from "../UptimeAvailability";
import DiaUsage from "../diaUsage";
import ColorUsage from "../colorUsage";
import css from "../circuitsMagneticStyle.less";

const [HbrCard] = reactWrapper(["hbr-card"]);
const cards = [
  { id: "availability", component: Availability },
  { id: "dia_usage", component: DiaUsage },
  { id: "color_usage", component: ColorUsage }
];

const View = () => {
  return (
    <div className={`card-items ${css.cards}`}>
      {cards.map(({ id, component: Content }) => (
        <HbrCard key={id} id={id}>
          <Content />
        </HbrCard>
      ))}
    </div>
  );
};

export default memo(View);
