import React, { useState, useEffect, useCallback, useRef } from "react";
import { PropTypes } from "prop-types";
import Spinner from "../../../common/Spinner";
import ErrorComponent from "../../../common/ErrorComponent";
import NoDataAvailable from "../../../common/NoDataAvailable";
import StackedMultiLineChart from "../../../common/StackedMultiLineChart";
import { getColorTrendData } from "../actions";
import { useMount } from "../../../utils/genericCommon";
import { compareAndMatchDateTime, getIsHourlyData, getTimeInterval } from "../../../utils/common";
import { formatBytes } from "../../../utils/format";
import {
  displayDateTime,
  getTimestampFromUTCString
} from "../../../utils/displayTime";
import i18n from "../../nls/root/i18n";
import css from "../circuitsMagneticStyle.less";
import { defaultTextValue } from "../../../utils/enums";
import { chartPrimaryColors } from "../../../utils/colors";

const maxColorLimit = 7;

const SideBar = ({ circuitsData, globalFilter }) => {
  const [loader, setLoader] = useState(true);
  const dataRef = useRef({
    timeRange: circuitsData.query.timeRange.slice(0),
    error: null,
    data: [],
    seriesColors: {}
  });
  const mount = useMount();
  // load bandwidth usage graph data on initial load
  useEffect(() => {
    const getData = async () => {
      const res = await getColorTrendData(globalFilter.globalV4Payload);
      if (mount.mounted === true) {
        if (res.errorObject instanceof Object)
          dataRef.current.error = res.errorObject;
        else if (
          res.data instanceof Object && Array.isArray(res.data.data)
          && res.data.data.length > 0
        ) {

          const timeInterval = getTimeInterval(globalFilter.globalV4Payload.time_frame);
          const min = dataRef.current.timeRange[0];
          const resData = res.data.data;
          // get local colors and sort colors based on usage
          const localColors = {};
          let otherColors = null;
          const filteredData = resData.filter(item => {
            item.date = getTimestampFromUTCString(item.entry_ts);
            if (item.date < min) return false;
            if (Number.isFinite(item.usage)) {
              if (Number.isFinite(localColors[item.color]))
                localColors[item.color] += item.usage;
              else localColors[item.color] = item.usage;
              return true;
            }
            return false;
          });
          const localColorList = Object.entries(localColors);
          let colorCount = localColorList.length;
          localColorList.sort((item1, item2) => (item1[1] > item2[1] ? -1 : 1));
          if (localColorList.length > maxColorLimit) {
            colorCount = maxColorLimit;
            otherColors = Object.fromEntries(
              localColorList.splice(maxColorLimit - 1)
            );
          }
          // set series color set for the lines of graph
          if (otherColors instanceof Object)
            dataRef.current.seriesColors.others =
              chartPrimaryColors[--colorCount];
          localColorList.reverse().forEach(item => {
            dataRef.current.seriesColors[item[0]] =
              chartPrimaryColors[--colorCount];
          });
          // create entry object from response data
          const entryTime = {};
          if (otherColors instanceof Object)
            for (const item of filteredData) {
              if (entryTime[item.date] instanceof Object) {
                if (Number.isFinite(otherColors[item.color])) {
                  if (Number.isFinite(entryTime[item.date].others))
                    entryTime[item.date].others += item.usage;
                  else entryTime[item.date].others = item.usage;
                } else entryTime[item.date][item.color] = item.usage;
              } else {
                if (Number.isFinite(otherColors[item.color]))
                  entryTime[item.date] = {
                    date: item.date,
                    others: item.usage
                  };
                else
                  entryTime[item.date] = {
                    date: item.date,
                    [item.color]: item.usage
                  };
              }
            }
          else
            for (const item of filteredData) {
              if (entryTime[item.date] instanceof Object)
                entryTime[item.date][item.color] = item.usage;
              else
                entryTime[item.date] = {
                  date: item.date,
                  [item.color]: item.usage
                };
            }

          // create entry array and sort based on entry time
          const entryData = Object.values(entryTime).sort((item1, item2) =>
            item1.date > item2.date ? 1 : -1
          );
          // generate graph data from entry array
          if (entryData.length > 0) {
            const seriesList = Object.keys(dataRef.current.seriesColors);
            const missedEntries = [];
            const lastIndex = entryData.length - 1;

            entryData.forEach((item1, index) => {
              // create graph hover tooltip data
              item1.tooltipData = { date: displayDateTime(item1.date) };
              for (const item of seriesList)
                item1.tooltipData[item] = Number.isFinite(item1[item])
                  ? formatBytes(item1[item])
                  : defaultTextValue;

              const item2 = index < lastIndex ? entryData[index + 1] : null;
              if (item2) {
                /* add an entry to extend lines till current entry's end range if
              next entry is after an hour */
                if (item2.date > item1.date + timeInterval) {
                missedEntries.push({ ...item1, date: item1.date + (timeInterval - 1) });
              } else {
                  /* add an entry in current entry's end range if a color
                    in current entry is missing in next entry */
                  const item = {
                    ...item1,
                    date: item1.date +  (timeInterval -1)
                  };
                  let missedDatapoint = false;
                  for (const value of seriesList) {
                    if (Number.isFinite(item1[value])) {
                      if (!Number.isFinite(item2[value])) {
                        item[value] = 0;
                        missedDatapoint = true;
                      } else item[value] = item2[value];
                    }
                  }
                  if (missedDatapoint) missedEntries.push(item);
                }
                /* add an entry for the last entry to extend lines till its
                end range */
              }
            });
            // set graph data and sort based on entry time

            let diaTunnelData = entryData.concat(missedEntries)
              .sort((item1, item2) => item1.date > item2.date ? 1 : -1);
            if (getIsHourlyData(globalFilter.globalV4Payload.time_frame)) {
              diaTunnelData = compareAndMatchDateTime(diaTunnelData, globalFilter, "date");
            }
            dataRef.current.data = diaTunnelData;
          }
        }
        setLoader(false);
      }
    };
    getData();
  }, []);

  // customize the chart
  const customizeChart = useCallback(chart => {
    chart.series.values.forEach(series => {
      series.adapter.add("tooltipHTML", (html, target) => {
        const data = target.tooltipDataItem.dataContext;
        if (data && data.tooltipData) {
          const series = [...chart.series.values].reverse();
          html = `
            <div class="ttip-header">
            ${i18n.circuit.cdcTitle}&nbsp;
            <span class="date">${data.tooltipData.date}</span>
            </div>
            <table class="ttip-content">
            ${series
              .map(item =>
                !item.isHidden
                  ? `
                  <tr>
                    <td>
                      <div class="flex-items">
                        <span
                          class="square-legend"
                          style="background-color:${item.fill.hex};"
                        ></span>
                        ${item.name}
                      </div>
                    </td>
                    <td>${data.tooltipData[item.dataFields.valueY]}</td>
                  </tr>
                  `
                  : ""
              )
              .join("")}
            </table>`;
        }
        return html;
      });
    });
    chart.yAxes.values[0].numberFormatter.numberFormat = "#.b";
    chart.yAxes.values[0].maxPrecision = 0;
    chart.yAxes.values[0].renderer.minGridDistance = 90;
  }, []);

  return (
    <div
      className={`${css.sidebar} flex-column-full`}
      data-testid="color-trend"
    >
      {loader === true ? (
        <Spinner />
      ) : dataRef.current.error !== null ? (
        <ErrorComponent
          {...dataRef.current.error}
          className="small-dashlet-error"
          width="220px"
        />
      ) : dataRef.current.data.length === 0 ? (
        <NoDataAvailable />
      ) : (
        <div className="chart-container flex-main-content">
          <StackedMultiLineChart
            id="circuit-color-trend"
            label={i18n.c360.ucYLabel}
            scrollbar
            scrollBarInitialZoom
            colorset={dataRef.current.seriesColors}
            callbackFromParent={customizeChart}
            data={dataRef.current.data}
            min={globalFilter.timeFilter.current_period[0]}
            max={globalFilter.timeFilter.current_period[1] }
          />
        </div>
      )}
    </div>
  );
};

SideBar.propTypes = {
  circuitsData: PropTypes.object.isRequired,
  globalFilter: PropTypes.object
};

export default SideBar;
