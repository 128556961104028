import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

// components
import AppHOC from "../../../generics/AppHOC";
import reduxContext from "../reducer";
import * as actions from "../actions";

// i18nMessageBundle
import i18nMessageBundle from "amdi18n-loader!../../nls/i18n";

// style
import css from "../insightsMagneticStyle.less";

// utils
import {
  setLocalStorageFlag,
  isBandForecastingTabSelected,
  getLocalStorageFlag,
  isAnomalyPage
} from "../../../utils/common";
import BetaTag from "../../../common/BetaTag";
import { FEATURE_CONFIG } from "../../../utils/enums";
import { BASE_URL } from "../../../apis/apiConstants";
import { getCookie } from "../../../serviceWorker";

const mapStateToProps = state => {
  return {
    bandwidth: state.vanalytics.bandwidth
  };
};
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const [HbrTabGroup, HbrTab] = reactWrapper(["hbr-tab-group", "hbr-tab"]);

const PredictiveNetworksTabs = props => {
  const isBandwidthTab = isBandForecastingTabSelected();
  const availableFeatures = JSON.parse(
    getLocalStorageFlag("availableFeatures")
  );
  const initSelection =
    isBandwidthTab && availableFeatures?.bwf
      ? "bandForecastingTab"
      : props.globalFilter.predictiveTabSelected;
  const isSecondaryTabsShown = availableFeatures?.bwf || availableFeatures?.ad;
  const [selectedTab, setSelectedTab] = useState(initSelection);
  const siteWidget = document.querySelector("[data-cy=sites-dropdown-component]");
  const isUIConvergence = getCookie("cl-analytics");
  const tabStyle = isUIConvergence ? "convergencePredictiveTab" : "predictiveNetworksTabs";
  const first20CharsUrl = location.href.substring(0, 20);
  //check if au region
  const isPredictivLabelEnabled = first20CharsUrl.includes("au");

  if (siteWidget) {
    if (localStorage.getItem("onboardingStatus") === "ACTIVE" && !isAnomalyPage()) {
      siteWidget.style.visibility = "visible";
    } else if (!isUIConvergence) {
      siteWidget.style.visibility = "hidden";
    }
  }

  const onTabsChange = (tabValue) => {
    setLocalStorageFlag("bandForecastingTab", tabValue === "bandForecastingTab");

    // to update tab selection
    setSelectedTab(tabValue);

    if (tabValue === "anomalyTab") {
      props.history.push(`${BASE_URL}/predictivenetworks/anomalydetection`);
    } else {
      props.history.push(`${BASE_URL}/predictivenetworks`);
    }
  };

  useEffect(() => {
    if (
      location.pathname === `${BASE_URL}/predictivenetworks` &&
      selectedTab !== "bandForecastingTab"
    ) {
      setSelectedTab("pprTab");
    } else if (
      location.pathname.includes("/anomalydetection")
    ) {
      setSelectedTab("anomalyTab");
      props.setAltoProps({ predictiveTabSelected: "anomalyTab" });
    }
  }, []);

  return (
    <div
      className={css[tabStyle]}
      id="predictiveNetworksTab"
      data-cy="predictive-tab"
      data-testid="predictive-tab"
    >
      <div className="title-second-tab-level">
        <div className="tabs">
          {isSecondaryTabsShown ? (
            <HbrTabGroup primary={isUIConvergence ? true : false}>
              {!isPredictivLabelEnabled && <HbrTab
                slot="nav"
                active={selectedTab === "pprTab" ? true : false}
                key={"pprTab"}
                panel={"pprTab"}
                onHbr-active={() => onTabsChange("pprTab")}
                sentiment={isUIConvergence ? "success" : "info"}
              >
                {i18nMessageBundle.predictiveNetworks.pprTab}
              </HbrTab>}
              {availableFeatures?.bwf && (
                <HbrTab
                  slot="nav"
                  active={selectedTab === "bandForecastingTab" ? true : false}
                  key={"bandForecastingTab"}
                  panel={"bandForecastingTab"}
                  onHbr-active={() => onTabsChange("bandForecastingTab")}
                  sentiment={isUIConvergence ? "success" : "info"}
                >
                  {i18nMessageBundle.predictiveNetworks.bandForecastingTab}
                  <BetaTag msg={FEATURE_CONFIG.BF.betaMessage} />
                </HbrTab>
              )}
              {availableFeatures?.ad && (
                <HbrTab
                  slot="nav"
                  active={selectedTab === "anomalyTab" ? true : false}
                  key={"anomalyTab"}
                  panel={"anomalyTab"}
                  onHbr-active={() => onTabsChange("anomalyTab")}
                  sentiment={isUIConvergence ? "success" : "info"}
                >
                  {i18nMessageBundle.predictiveNetworks.anomaly}
                  <BetaTag msg={FEATURE_CONFIG.AD.betaMessage} />
                </HbrTab>
              )}
            </HbrTabGroup>
          ) : (
              <div className="no-tab-title hbr-type-h2">
                {i18nMessageBundle.predictiveNetworks.pprTab}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

PredictiveNetworksTabs.propTypes = {
  actions: PropTypes.object,
  setAltoProps: PropTypes.func,
  globalFilter: PropTypes.object,
  history: PropTypes.object.isRequired
};

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHOC(PredictiveNetworksTabs))
);