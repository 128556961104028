
import i18n from "amdi18n-loader!../../../nls/i18n";
import { formatBytes, formatString } from "../../../../utils/format";
import {
  circuitNameClick,
  downloadAsCSVFile,
  getInfoIcon
} from "../../../../utils/common";
import { formatBits } from "../../../circuit360/common";
import { convertTimetoUnit } from "../../../circuits/utils/circuitsTableView";
import { defaultCellValue } from "../../../../utils/enums";

export const getColumns = (
  history,
) => {
  return [
    {
      data: "circuit_display_name",
      title: i18n.circuit.ctName,
      render: data => (
        `<div class="flex-items"><div class="table-link">${data}</div><div>`
      ),
      createdCell: (td, value, rowData) => {
        const linkElement = td.querySelector(".table-link");
        if (linkElement)
          linkElement.addEventListener("click", e => {
            e.preventDefault();
            circuitNameClick(history, rowData);
          });
      }
    },
    { data: "service_provider", title: i18n.circuit.ctProvider,className: "column-rxtx-alignment-fix" },
    {
      data: "site_name", title: i18n.circuit.ctSites,
      className: "column-rxtx-alignment-fix",
      render: function (data) {
        let truncatedData = formatString(data, 12);
        return `
            <div class="application-trending-apps-qoe-widget-link"
             id="sites-table-site-${data}">${truncatedData}</div>
            <hbr-portal>
             <hbr-tooltip anchor="#sites-table-site-${data}">
               <div slot="content"><strong>${data}</strong></div>
              </hbr-tooltip>
            </hbr-portal>`
      }
    },
    { data: "site_location", title: i18n.circuit.ctLocation },
    {
      data: "availability",
      title: i18n.circuit.ctAvail,
      titleSuffix: getInfoIcon(i18n.circuit.unknownAvailTtip),
      name: "avail_disp",
      render: function (data, type) {
        if (type != "display") {
          return data;
        }
       return Number.isFinite(data) ? `${parseFloat(data.toFixed(2))}%` : data;
      }
    },
    {
      data: "downtime",
      title: i18n.circuit.ctDowntime,
      name: "dtime",
      render: function (data, type) {
        if (type != "display") {
          return data;
        }
        return data;
      }
    },
    {
      data: "total_bps",
      title: i18n.circuit.ctRxTx,
      className: "column-rxtx-alignment-fix",
      type: "num",
      render: function (data, type) {
        if (type != "display") {
          return data;
        }
        return Number.isFinite(data) ? formatBits(data) : data;
      }
    },
    {
      data: "rx_bps",
      title: i18n.circuit.ctRx,
      className: "column-alignment-fix",
      type: "num",
      render: data => (Number.isFinite(data) ? formatBits(data) : data)
    },
    {
      data: "tx_bps",
      title: i18n.circuit.ctTx,
      className: "column-alignment-fix",
      type: "num",
      render: data => (Number.isFinite(data) ? formatBits(data) : data)
    },
    {
      data: "rx_utilization",
      title: i18n.circuit.ctRxUtil,
      type: "num",
      render: data => (Number.isFinite(data) ? `${parseFloat(data.toFixed(2))}%` : data)
    },
    {
      data: "tx_utilization",
      title: i18n.circuit.ctTxUtil,
      type: "num",
      render: data => (Number.isFinite(data) ? `${parseFloat(data.toFixed(2))}%` : data)
    },
    { data: "vdevice_id", title: i18n.circuit.ctLocalDevice },
    { data: "color", title: i18n.circuit.ctLocalColor },
    { data: "loss", name: "ls", title: i18n.circuit.ctLoss, type: "num" },
    {
      data: "latency",
      name: "ly",
      title: i18n.circuit.ctLatency,
      type: "num"
    },
    {
      data: "jitter",
      name: "jr",
      title: i18n.circuit.ctJitter,
      type: "num"
    },
    {
      data: "total_octets",
      title: i18n.circuit.ctTotalUsage,
      type: "num",
      render: data => (Number.isFinite(data) ? formatBytes(data) : data)
    },
    {
      data: "tunnel_usage",
      title: i18n.circuit.ctTunnelUsage,
      type: "num",
      render: data => (Number.isFinite(data) ? formatBytes(data) : data)
    },
    {
      data: "dia_usage",
      title: i18n.circuit.ctDIAUsage,
      type: "num",
      titleSuffix: getInfoIcon(i18n.circuit.diaText),
      render: data => {
        return Number.isFinite(data) ? formatBytes(data) : data;
      }
    },
    { data: "apps_count", title: i18n.circuit.ctAppCount, type: "num" },
    { data: "tunnel_count", title: i18n.circuit.ctTunnelCount, type: "num" },
    { data: "vrf_count", title: i18n.circuit.ctVRFCount, type: "num" }
  ];
};

export const getTools = getExportData => {
  return [
    {
      ref: { name: "search" },
      filter: true,
      level: "secondary",
      justify: "start"
    },
    {
      ref: {
        name: "export",
        config: {
          title: i18n.circuit.title,
          action: async dataTable => {
            const res = await getExportData(dataTable);
            if (
              res.errorObject instanceof Object === false
              && res.data instanceof Object
              && Array.isArray(res.data.data)
            ) {
              const exportData = res.data.data;
              const columns =
                dataTable.props.columns.filter(item => item.visible !== false);
              if (dataTable.props.rowSelect === true) columns.shift();
              const data = [columns.map(item => `"${item.title}"`).join(",")];
              for (const item of exportData) {
                const availability = Number.isFinite(item.availability)
                  ? `${parseFloat(item.availability.toFixed(2))}%`
                  : defaultCellValue;
                const downtime = Number.isFinite(item.downtime)
                  ? convertTimetoUnit(item.downtime)
                  : defaultCellValue;
                const rxTx = Number.isFinite(item.total_bps)
                  ? formatBits(item.total_bps)
                  : defaultCellValue;
                const rx = Number.isFinite(item.rx_bps)
                  ? formatBits(item.rx_bps)
                  : defaultCellValue;
                const tx = Number.isFinite(item.tx_bps)
                  ? formatBits(item.tx_bps)
                  : defaultCellValue;
                const rxUtilization = Number.isFinite(item.rx_utilization)
                  ? `${item.rx_utilization}%`
                  : defaultCellValue;
                const txUtilization = Number.isFinite(item.tx_utilization)
                  ? `${item.tx_utilization}%`
                  : defaultCellValue;
                const totalUsage = Number.isFinite(item.total_octets)
                  ? formatBytes(item.total_octets)
                  : defaultCellValue;
                const tunnelUsage = Number.isFinite(item.tunnel_usage)
                  ? formatBytes(item.tunnel_usage)
                  : defaultCellValue;
                const diaUsage = Number.isFinite(item.dia_usage)
                  ? formatBytes(item.dia_usage)
                  : defaultCellValue;
                data.push([
                  `"${item.circuit_display_name}"`,
                  `"${item.service_provider}"`,
                  `"${item.site_id}"`,
                  `"${item.site_location}"`,
                  `"${availability}"`,
                  `"${downtime}"`,
                  `"${rxTx}"`,
                  `"${rx}"`,
                  `"${tx}"`,
                  `"${rxUtilization}"`,
                  `"${txUtilization}"`,
                  `"${item.vdevice_id}"`,
                  `"${item.color}"`,
                  `"${item.loss}"`,
                  `"${item.latency}"`,
                  `"${item.jitter}"`,
                  `"${totalUsage}"`,
                  `"${tunnelUsage}"`,
                  `"${diaUsage}"`,
                  `"${item.apps_count}"`,
                  `"${item.tunnel_count}"`,
                  `"${item.vrf_count}"`
                ].join(","));
              }
              downloadAsCSVFile(data.join("\n"), i18n.circuit.title);
            }
          }
        }
      },
      level: "secondary",
      justify: "end"
    }
  ];
}

