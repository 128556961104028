import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import _ from "lodash";

//components
import reduxContext from "../../reducer";
import AppHOC from "../../../../generics/AppHOC";
import Spinner from "../../../../common/Spinner";
import ErrorComponent from "../../../../common/ErrorComponent";
import PathRecommendation from "../../../../common/PathRecommendation";
import GeoMapComponentMini from "./GeoMapComponentMini";

//utils
import {
  getLocalStorageFlag,
  isDeviceInfoPage
} from "../../../../utils/common";
import usePredictiveNetworksHooks from "../../../insights/usePredictiveNetworkHooks";
import { validateProps, formatGains } from "../../../../utils/waniCommon";
import { BASE_URL } from "../../../../apis/apiConstants";

//style
import css from "../../insightsAppMagneticStyle.less";

//i18n file
import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";

const mapStateToProps = state => {
  return {
    predictiveNetworks: state.vanalytics.predictiveNetworks
  };
};

const [HbrCard] = reactWrapper(["hbr-card"]);

const ActionCards = props => {
  const {
    selectedOverlay,
    selectedSite,
    appGroup,
    onboardingStatus
  } = validateProps(props.globalFilter);
  const [isLoading, setIsLoading] = useState(true);
  const [cards, setCards] = useState(<></>);
  const {
    state,
    getListActions,
    getEndpointsDetails,
    getPathRecommendation
  } = usePredictiveNetworksHooks(props);
  const pathDetails = JSON.parse(getLocalStorageFlag("pathDetails"));

  useEffect(() => {
    if (isDeviceInfoPage())
      getPathRecommendation();
  }, [])

  useEffect(async () => {
    if (onboardingStatus === "ACTIVE") {
      window.scrollTo(0, 0);

      const actionCardSite = getLocalStorageFlag("actionCardSite");

      if (!isLoading) {
        setIsLoading(true);
      }

      if (_.isEmpty(pathDetails) && isDeviceInfoPage())
        props.history.push(`${BASE_URL}/predictivenetworks`);

      if (parseInt(actionCardSite) !== -1) {
        getListActions(selectedOverlay);
        getEndpointsDetails(selectedOverlay, selectedSite);
      } else if (selectedSite === -1) {
        props.history.push(`${BASE_URL}/predictivenetworks`);
      }
    } else {
      setIsLoading(false);
    }
  }, [appGroup]);

  useEffect(() => {
    let cards = null;

    if (state.isFetchingActionsDone) {
      const actionCardSite = getLocalStorageFlag("actionCardSite");
      const filteredActionBySite = state.actionPaths.filter(
        action =>
          action.siteId === actionCardSite.toString() &&
          action.appClass === appGroup
      );

      if (!_.isEmpty(filteredActionBySite)) {
        cards = filteredActionBySite.map(card => {
          return (
            <div className="card-action-view" key={Math.random()}>
              {state.isFetchingRecommedationsDone &&
                state.endpointDetails?.location?.longitude && (
                  <div
                    className="card-action-view-mini-map-container"
                    data-cy="card-action-view-mini-map-container"
                  >
                    <GeoMapComponentMini
                      longitude={card.location.longitude}
                      latitude={card.location.latitude}
                    />
                  </div>
                )}
              <div className="card-action-view-group">
                <div className="card-action-view-row-1 hbr-type-body3">
                  <span>
                    {
                      i18nMessageBundle.predictiveNetworks
                        .appTableConfigSiteIdLabel
                    }
                  </span>
                </div>
                <div className="card-action-view-row-2 hbr-type-body3">
                  {filteredActionBySite[0].siteId}
                </div>
                <div className="card-action-view-row-1 hbr-type-body3">
                  <span>
                    {
                      i18nMessageBundle.predictiveNetworks
                        .appTableConfigLocationLabel
                    }
                  </span>
                </div>
                <div className="card-action-view-row-2 hbr-type-body3">
                  {`${filteredActionBySite[0].location.country} · ${filteredActionBySite[0].location.city}`}
                </div>
              </div>
              {pathDetails ? (
                <>
                  <div className="card-action-view-group">
                    <div className="card-action-view-row-1 hbr-type-body3">
                      {i18nMessageBundle.predictiveNetworks.currentPath}
                    </div>
                    <div className="card-action-view-row-2 hbr-type-body3">
                      {pathDetails.defaultQuality}
                    </div>
                  </div>
                  <div className="card-action-view-group">
                    <div className="card-action-view-row-1 hbr-type-body3">
                      {i18nMessageBundle.predictiveNetworks.recomPathQuality}
                    </div>
                    <div className="card-action-view-row-2 hbr-type-body3">
                      {pathDetails.recommendationQuality}
                      <span className="card-action-view-row-4 hbr-type-body4">
                        {formatGains(pathDetails.gains)}
                      </span>
                    </div>
                  </div>
                  <div className="card-action-view-group">
                    <div className="card-action-view-row-1 hbr-type-body3">
                      {i18nMessageBundle.predictiveNetworks.recommendation}
                    </div>
                    <div>
                      {_.isArray(state.pathRecommendation) &&
                        state.pathRecommendation[0]?.metadata &&
                        <PathRecommendation {...state.pathRecommendation[0].metadata} />}
                    </div>
                  </div>
                </>
              ) : (
                  <>
                    <div className="card-action-view-group">
                      <div className="card-action-view-row-3">
                        <PathRecommendation {...card.metadata} />
                      </div>
                    </div>
                    <div className="card-action-view-group">
                      <div className="card-action-view-row-1 hbr-type-body3">
                        <span>
                          {i18nMessageBundle.predictiveNetworks.currentPath}
                        </span>
                      </div>
                      <span className="card-action-view-row-2 hbr-type-body3">
                        {card.defaultQuality}
                      </span>
                    </div>
                    <div className="card-action-view-group">
                      <div className="card-action-view-row-1 hbr-type-body3">
                        <span>
                          {i18nMessageBundle.predictiveNetworks.recomPathQuality}
                        </span>
                      </div>
                      <div className="card-action-view-row-2 hbr-type-body3">
                        {card.recommendationQuality}
                        <span className="card-action-view-row-4 hbr-type-body4">
                          {formatGains(card.gains)}
                        </span>
                      </div>
                    </div>
                    <div className="card-action-view-group">
                      <div className="card-action-view-row-1 hbr-type-body3">
                        <span>
                          {i18nMessageBundle.predictiveNetworks.impactedUsers}
                        </span>
                      </div>
                      <div className="card-action-view-row-2 hbr-type-body3">
                        {card.impactedUsers}
                      </div>
                    </div>
                  </>
                )}
            </div>
          );
        });
      } else {
        props.history.push(`${BASE_URL}/predictivenetworks`);
      }
      setCards(cards);
      setIsLoading(false);
    }
  }, [
    state.isFetchingActionsDone,
    state.isFetchingActionsError,
    state.isFetchingRecommedationsDone,
    state.isFetchingRecommedationsError
  ]);

  if (isLoading) {
    return (
      <div className={css["card-action"]} data-cy="card-action">
        <div className="spinner-card-action" data-cy="spinner-card-action">
          <Spinner />
        </div>
      </div>
    );
  } else if (state.isFetchingActionsError?.errorObject) {
    return (
      <div className={css["card-action"]} data-cy="card-action">
        <ErrorComponent
          {...state.isFetchingActionCardsError.errorObject}
          width={"200px"}
          className={"super-small-dashlet-error"}
        />
      </div>
    );
  } else {
    return (
      <HbrCard className={css["card-action"]} data-cy="card-action">
        {cards}
      </HbrCard>
    );
  }
};
ActionCards.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  getActionCardsPerSite: PropTypes.func,
  history: PropTypes.object
};

export default reduxContext.withProvider(
  connect(mapStateToProps)(AppHOC(ActionCards))
);
