import { createContext } from "@cisco-dna/redux-utils";

const reduxContext = createContext();
const keyStore = "vanalytics.predictiveNetworks";

export default reduxContext;

const reducer = () => {};

reduxContext.injectReducer({
  STORE: keyStore,
  REDUCER: reducer,
  ACTION_TYPE: []
});
