import React, { useState } from "react";
import { bindActionCreators } from "redux";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import PredictiveNetworksTabs from "../../../PredictiveNetworksTabs";
import css from "../anomalyMagneticStyle.less";
import ADChartView from "./ADChartView";
import { getCookie } from "../../../../../serviceWorker";
import i18n from "../../../../nls/root/i18n";
import * as actions from "../actions";
import reduxContext from "../reducer";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

const mapStateToProps = state => {
  return {
    adKey: state.vanalytics.adKey
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const [HbrRadioButton, HbrViewSwitcher, HbrCard, HbrTooltip, HbrIcon] =
  reactWrapper(["hbr-radio-button", "hbr-view-switcher", "hbr-card", "hbr-tooltip", "hbr-icon"])
const isUIConvergence = getCookie("cl-analytics");

const AnomalyDetectionView = (props) => {
  const [selection, setSelection] = useState("topTunnel")
  const [usageTunnel, setUsageTunnel] = useState("topTunnel")
  const handleTunnel = (event) => {
    setSelection(event.target.id)
    props.actions.updateUsageType(event.target.id)
    let usageSelected = event.target.id === "topTunnel"
              ? i18n.predictiveNetworks.topTunnelsUsed
              : i18n.predictiveNetworks.allTunnelsUsed
    setUsageTunnel(usageSelected)
  }

  return (
    <div>
      <HbrCard className={css["anomaly-detection-widget"]}>
        {!isUIConvergence && <PredictiveNetworksTabs />}
        <div style={{ display: "flex" }}>
          <HbrViewSwitcher className="tunnelGroup"
            value={usageTunnel}
            onClick={handleTunnel}
          >
            <HbrRadioButton
              key="topTunnel"
              id={i18n.predictiveNetworks.topTunnel}
              value={i18n.predictiveNetworks.topTunnel}>
              {i18n.predictiveNetworks.topTunnelsUsed}
            </HbrRadioButton>
            <HbrRadioButton
              key="allTunnel"
              id="allTunnel"
              value={i18n.predictiveNetworks.allTunnelsUsed}>
              {i18n.predictiveNetworks.allTunnelsUsed}
            </HbrRadioButton>
          </HbrViewSwitcher>
          <span  className={css["anomaly-detection-widget-tooltip"]}>
            <HbrTooltip hoist>
              <div slot="content">
                <span className="info-title">{i18n.predictiveNetworks.topUsedTunnelsTitle} </span>
                <span>{i18n.predictiveNetworks.topUsedTunnels}</span></div>
              <div slot="content"> <span className="info-title">{i18n.predictiveNetworks.allUsedTunnelsTitle} </span>
                <span>{i18n.predictiveNetworks.allUsedTunnels}</span></div>
              <HbrIcon name="info" sentiment="neutral"></HbrIcon>
            </HbrTooltip>
          </span>
        </div>
        <ADChartView usageType={selection} />
      </HbrCard>
    </div>
  )
}

AnomalyDetectionView.propTypes = {
  actions: PropTypes.object
};

export default reduxContext.withProvider(connect(mapStateToProps, mapDispatchToProps)(AnomalyDetectionView))