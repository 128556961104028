export const getLocationRange = circuitLength => {
  let start = 0.4,
    end = 0.6;
  if (circuitLength === 10) {
    return [start, end];
  } else {
    let customSpace = (10 - circuitLength) * 0.01;
    return [start + customSpace, end - customSpace];
  }
};

// time units and their limits
export const timeUnits = [
  { unit: "ms", overLimit: 1000 },
  { unit: "s", overLimit: 60 * 1000 },
  { unit: "m", overLimit: 60 * 60 * 1000 },
  { unit: "h", overLimit: 24 * 60 * 60 * 1000 },
  { unit: "d", overLimit: Infinity }
];

/**
 * Description: This method converts time in milliseconds to respective time units
 * @param {number} time is time in milliseconds
 */

export const convertTimetoUnit = time => {
  let timeStr = null;
  timeUnits.find((item, index) => {
    if (time < item.overLimit) {
      const value =
        index === 0 ? time : Math.round(time / timeUnits[index - 1].overLimit);
      return (timeStr = `${value} ${item.unit}`);
    }
  });
  return timeStr;
};
