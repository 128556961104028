import React, { useState, useEffect, useRef, Suspense } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { DtableWrapper as dTableWrapper } from "dnx-web-components/dist/index-with-deps-react.min";
import table from "dnx-web-components/dist/table.min";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import _ from "lodash";

//components
import Spinner from "../../../../common/Spinner";
import AppHOC from "../../../../generics/AppHOC";
import reduxContext from "../../reducer";
const ErrorComponent = React.lazy(() =>
  import("../../../../common/ErrorComponent")
);
const NoDataAvailable = React.lazy(() =>
  import("../../../../common/NoDataAvailable")
);

//utils
import {
  getSingleSiteColumns,
  getTools,
  getAllSitesColumns
} from "./sitesTableConfig";
import useApplicationInfoHooks from "../../useApplicationInfoHooks";
import {
  getLocalStorageFlag,
  setLocalStorageFlag
} from "../../../../utils/common";
import { validateProps } from "../../../../utils/waniCommon";
import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";

//style
import css from "../../insightsAppMagneticStyle.less";

const mapStateToProps = state => {
  return {
    predictiveNetworks: state.vanalytics.predictiveNetworks
  };
};

const [HbrCard] = reactWrapper(["hbr-card"]);

const SitesTable = props => {
  const { endpoints } = props.globalFilter;
  const { selectedOverlay, onboardingStatus, appGroup } = validateProps(
    props.globalFilter
  );
  const {
    state,
    getAppGroupSingleSiteTableData,
    getAppGroupAllSiteTableData
  } = useApplicationInfoHooks();
  const DnxTable = dTableWrapper(table);
  const [tablePathData, setTablePathData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const tableRefSingleSite = useRef();
  const tableRefAllSites = useRef();

  useEffect(() => {
    const siteId = parseInt(getLocalStorageFlag("actionCardSite"));

    if (onboardingStatus === "ACTIVE") {
      setIsLoading(true);

      if (siteId === -1) {
        getAppGroupAllSiteTableData(selectedOverlay);
      } else {
        let endpoint = null;

        if (endpoints && !_.isEmpty(endpoints[siteId.toString()])) {
          setLocalStorageFlag(
            "endpoints",
            JSON.stringify(endpoints[siteId.toString()])
          );
        }
        if (!endpoints) {
          if (getLocalStorageFlag("endpoints")) {
            endpoint = JSON.parse(getLocalStorageFlag("endpoints"));
          }
        } else {
          endpoint = endpoints[siteId.toString()];
        }

        getAppGroupSingleSiteTableData(endpoint);
      }
    } else {
      setIsLoading(false);
    }
  }, [appGroup]);

  useEffect(() => {
    const updatedTable = data => {
      const devData = data;
      devData.sort((a, b) => b.gains - a.gains);
      setTablePathData(devData);
    };

    if (
      state.isFetchingAppGroupAllSiteTableDataDone &&
      state.allSiteTableData
    ) {
      updatedTable(state.allSiteTableData);
      setIsLoading(false);
    } else if (
      state.isFetchingAppGroupPerSingleSiteTableDataDone &&
      state.singleSiteTableData
    ) {
      updatedTable(state.singleSiteTableData);
      setIsLoading(false);
    } else if (
      state.isFetchingAppGroupAllSiteTableDataError.errorObject ||
      state.isFetchingAppGroupPerSingleSiteTableDataError.errorObject
    ) {
      setIsLoading(false);
    }
  }, [
    state.isFetchingAppGroupAllSiteTableDataDone,
    state.isFetchingAppGroupAllSiteTableDataError,
    state.isFetchingAppGroupPerSingleSiteTableDataDone,
    state.isFetchingAppGroupPerSingleSiteTableDataError
  ]);

  /**
   * Returns an object for site table with list of props.
   * @description create a table for app group 360 with all sites
   * @return {object} a table object with list of props.
   */
  const getTableProps = () => {
    const siteId = parseInt(getLocalStorageFlag("actionCardSite"));
    const columns =
      siteId === -1 ? getAllSitesColumns() : getSingleSiteColumns();

    const tableConfig = {
      id: "appGroupPerSiteTable",
      idData: "appGroupPerSite",
      serverSide: false,
      titleCount: true,
      data: tablePathData,
      columns: columns,
      findAction: false,
      layoutBtn: false,
      dataTableClasses: "nowrap",
      tools: getTools(siteId > 0),
      columnSearch: false,
      dtOptions: {
        scrollY: 300,
        scrollX: true,
        stateSave: true,
        stateDuration: 0,
        order: [[4, "desc"]],
        processing: true
      },
      disableAutoLayout: true
    };
    return tableConfig;
  };

  if (isLoading) {
    return (
      <div className={css["remote-site-table"]} data-cy="pdeTableView">
        <div id="remote-sites-spinner" data-cy="spinner-remoteSites">
          <Spinner />
        </div>
      </div>
    );
  } else if (onboardingStatus !== "ACTIVE") {
    return (
      <div className={css["remote-site-table"]} data-cy="pdeTableView">
        <Suspense fallback={<Spinner />}>
          <NoDataAvailable text={i18nMessageBundle.syncronizedChartNoData} />
        </Suspense>
      </div>
    );
  } else if (
    state.isFetchingAppGroupAllSiteTableDataError?.errorObject ||
    state.isFetchingAppGroupPerSingleSiteTableDataError.errorObject
  ) {
    return (
      <HbrCard
        className={css["remote-site-table"]}
        data-cy="pde-table-view-error"
      >
        <div className="dnx--css_table-container">
          <Suspense fallback={<Spinner />}>
            <ErrorComponent
              {...state.isFetchingAppGroupAllSiteTableDataError?.errorObject}
              {...state.isFetchingAppGroupPerSingleSiteTableDataError
                ?.errorObject}
              width={"200px"}
              className={"super-small-dashlet-error"}
            />
          </Suspense>
        </div>
      </HbrCard>
    );
  } else if (state.isFetchingAppGroupAllSiteTableDataDone) {
    return (
      <HbrCard
        className={css["remote-site-table"]}
        data-cy="pde-table-view-all-sites"
      >
        <div className="dnx--css_table-container">
          <DnxTable {...getTableProps()} ref={tableRefAllSites} />
        </div>
      </HbrCard>
    );
  } else if (state.isFetchingAppGroupPerSingleSiteTableDataDone) {
    return (
      <HbrCard
        className={css["remote-site-table"]}
        data-cy="pde-table-view-single-site"
      >
        <div className="dnx--css_table-container">
          <DnxTable {...getTableProps()} ref={tableRefSingleSite} />
        </div>
      </HbrCard>
    );
  } else {
    return (
      <div
        className={css["remote-site-table"]}
        data-cy="pde-table-view-spinner"
      >
        <Suspense fallback={<Spinner />}>
          <NoDataAvailable text={i18nMessageBundle.syncronizedChartNoData} />
        </Suspense>
      </div>
    );
  }
};

SitesTable.propTypes = {
  globalFilter: PropTypes.object
};

export default reduxContext.withProvider(
  connect(mapStateToProps)(AppHOC(SitesTable))
);
