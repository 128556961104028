import i18n from "amdi18n-loader!../../../nls/i18n";
import { downloadAsCSVFile } from "../../../../utils/common";
import { formatBytes, formatNumberNDecimalPlaces } from "../../../../utils/format";

export const getColumns = () => {
  return [
    { data: "site_name", title: i18n.site, name: "si" },
    {
      data: "tunnel_name",
      title: i18n.c360.ftTName,
      name: "nm",
    },
    {
      data: "local_system_ip",
      title: i18n.c360.ttLocalIP,
      name: "li",
    },
    {
      data: "remote_system_ip",
      title: i18n.c360.ttRemoteIP,
      name: "ri",
    },
    {
      data: "local_color",
      title: i18n.c360.ttLocalColor,
      name: "lc",
    },
    {
      data: "remote_color",
      title: i18n.c360.ttRemoteColor,
      name: "rc",
    },
    {
      data: "usage",
      title: i18n.c360.atUsage,
      render: data => {
        if (data === null || data === undefined) return "-";
        return formatBytes(data);
      }
    },
    {
      data: "loss_percentage",
      title: i18n.c360.atLoss,
      name: "ls",
      render: data => {
        if (!data) return 0;
        return formatNumberNDecimalPlaces(data, 1);
      }
    },
    {
      data: "latency",
      title: i18n.c360.atLatency,
      render: data => {
        if (!data) return 0;
        return formatNumberNDecimalPlaces(data, 1);
      }
    },
    {
      data: "jitter", title: i18n.c360.atJitter,
      render: data => {
        if (!data) return 0;
        return formatNumberNDecimalPlaces(data, 1);
      }
    },
  ];
};

export const getTools = (getExportData) => {
  return [
    {
      ref: { name: "title", config: { text: i18n.c360.ttTitle, count: true } }
    },
    // search
    {
      ref: { name: "search", config: { inputPlaceholder: i18n.c360.tSearch } },
      level: "secondary",
      justify: "start"
    },
    {
      ref: {
        name: "export",
        config: {
          title: i18n.c360.ttTitle,
          action: async dataTable => {
            const res = await getExportData(dataTable);
            if (
              res.errorObject instanceof Object === false
              && res.data instanceof Object
              && Array.isArray(res.data.data)
            ) {
              const exportData = res.data.data;
              const columns =
                dataTable.props.columns.filter(item => item.visible !== false);
              if (dataTable.props.rowSelect === true) columns.shift();
              const data = [columns.map(item => `"${item.title}"`).join(",")];
              for (const item of exportData) {
                data.push([
                  `"${item.site_name}"`,
                  `"${item.tunnel_name}"`,
                  `"${item.local_system_ip}"`,
                  `"${item.remote_system_ip}"`,
                  `"${item.local_color}"`,
                  `"${item.remote_color}"`,
                  `"${item.usage}"`,
                  `"${item.loss_percentage}"`,
                  `"${item.latency}"`,
                  `"${item.jitter}"`,
                ].join(","));
              }
              downloadAsCSVFile(data.join("\n"), i18n.c360.ttTitle);
            }
          }
        }
      },
      level: "secondary",
      justify: "end"
    }
  ]
};

export const defaultTableProps = {
  id: "c360-tt",
  dtOptions: {
    stateSave: false,
    scrollX: true,
    scrollY: 300,
    order: [[6, "desc"]],
    language: {
      zeroRecords: i18n.c360.tEmpty,
      emptyTable: i18n.c360.tEmpty
    }
  },
  serverSide: true,
  columns: getColumns(),
  dataTableClasses: "nowrap"
};