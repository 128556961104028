import { useEffect, useRef, useState } from "react";
import { PropTypes } from "prop-types";
import {convertedKpiV4, topTunnel } from "../../../utils"
import { am4core, am4charts } from "../../../../../loaders/amchartsLoader";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { displayPathAnalyticsTime } from "../../../../../utils/displayTime";
import i18n from "amdi18n-loader!../../../../nls/i18n";
import { charCircle } from "../../../../../utils/enums";
import { anomalyColors, colors } from "../../../../../utils/colors";

let barColors = {
  loss_percentage_anomaly_cnt: anomalyColors.loss,
  latency_anomaly_cnt: anomalyColors.latency,
  jitter_anomaly_cnt: anomalyColors.jitter,
  loss_percentage_anomaly_percentage_record: anomalyColors.loss,
  latency_anomaly_percentage_record: anomalyColors.latency,
  jitter_anomaly_percentage_record: anomalyColors.jitter,
  topx_tunnel_usage_loss_percentage_anomaly_percentage_record: anomalyColors.loss,
  topx_tunnel_usage_latency_anomaly_percentage_record: anomalyColors.latency,
  topx_tunnel_usage_jitter_anomaly_percentage_record: anomalyColors.jitter
}

const ADStackedBarChart = props => {
  const { chartsData, usageType } = props;
  const { leftTitleForY, chartId } = props.chartProps;
  const chartsRef = useRef(chartsData);
  const [preSelected, setPreSelected] = useState(props.selectedBar);
  const currentChartRef = useRef();
  am4core.useTheme(am4themes_animated);

  useEffect(() => {
    chartsRef.current = props.chartsData;
  }, [props.chartsData]);

  const createStackedChart = (id, data) => {
    /* Create chart */
    let chart = am4core.create(id, am4charts.XYChart);
    // Add data
    chart.data = data;

    // Create axes
    const dateAxisX = chart.xAxes.push(new am4charts.DateAxis());
    dateAxisX.baseInterval.timeUnit = "hour";
    dateAxisX.baseInterval.count = 1;
    dateAxisX.tooltip.disabled = true;

    // Create series
    let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.title.text = `[var(--text-secondary, #535B62)] ${leftTitleForY}`;
    valueAxis2.cursorTooltipEnabled = false;

    const highlighColumn = (selectedDate) => {
      chart.series.each(function(series) {
        if (series instanceof am4charts.ColumnSeries) {
          series.columns.each(function(column) {
            if(selectedDate) {
              if(displayPathAnalyticsTime(column.dataItem.dateX) !== selectedDate) {
                column.setState("default");
                column.isActive = false;
              } else {
                column.isActive = true;
                column.fillOpacity = 1;
              }
            }
          })
        }
      })
    }

    let series
    if(data?.length > 0) {
      let keyList = data.map(d => {
        return Object.keys(d);
      });
      let noOfSeries = [...new Set(keyList.flat())];
      let text
      noOfSeries.map(key => {
        if (key !== "entry_ts") {
          series = chart.series.push(new am4charts.ColumnSeries());
          series.dataFields.valueY = key;
          series.dataFields.dateX = "entry_ts";
          if (props.chartProps.chartId === "anomaly-chart") {
            series.dataFields.lossValueY = usageType === i18n.predictiveNetworks.topTunnel
              ? 'topx_tunnel_usage_loss_percentage_anomaly_percentage_record'
              : 'loss_percentage_anomaly_percentage_record';
            series.dataFields.latencyValueY = usageType === i18n.predictiveNetworks.topTunnel
              ? 'topx_tunnel_usage_latency_anomaly_percentage_record'
              : 'latency_anomaly_percentage_record';
            series.dataFields.jitterValueY = usageType === i18n.predictiveNetworks.topTunnel
              ? 'topx_tunnel_usage_jitter_anomaly_percentage_record'
              : 'jitter_anomaly_percentage_record';
          }
          series.yAxis = valueAxis2;
          series.name = convertedKpiV4[key];
          series.fill = barColors[key];
          series.fillOpacity = 0.8;
          series.strokeWidth = 0;
          series.stacked = true;
          var columnTemplate = series.columns.template;
          columnTemplate.width = am4core.percent(50);
          columnTemplate.fillOpacity = .5;

          var hs = columnTemplate.states.create("active");
          hs.properties.fillOpacity = 1;

          columnTemplate.events.on("hit", function hit(ev) {
            let selectedDate = displayPathAnalyticsTime(ev.target.column._dataItem.dates.dateX);
            props.saveData(selectedDate);
            setPreSelected(selectedDate);
            highlighColumn(selectedDate);
          });

          series.events.on("ready", () => {
            highlighColumn(preSelected);
          })

          if (props.chartProps.chartId === "kpi-chart") {
            let kpiTip = convertedKpiV4[key]
            text = `${i18n.postSpace}[bold]{entry_ts.formatDate("MM/dd/yyyy hh:mm a")}[/]\n
              [${barColors[key]}]${charCircle}[/] ${i18n.preSpace} ${kpiTip}: ${i18n.preSpace} [bold]{valueY}%[/]\n${i18n.postSpace}`
          }

          if(props.chartProps.chartId === "anomaly-chart") {
            text = `${i18n.postSpace}[bold]{entry_ts.formatDate("MM/dd/yyyy hh:mm a")}[/]\n
              [${barColors['loss_percentage_anomaly_percentage_record']}]${charCircle}[/] ${i18n.preSpace} Loss: ${i18n.preSpace} {lossValueY}%\n
              [${barColors['latency_anomaly_percentage_record']}]${charCircle}[/] ${i18n.preSpace} Latency: ${i18n.preSpace} {latencyValueY}%\n;
              [${barColors['jitter_anomaly_percentage_record']}]${charCircle}[/] ${i18n.preSpace} Jitter: ${i18n.preSpace} {jitterValueY}%\n${i18n.postSpace}`;
          }

          columnTemplate.tooltipText = text;
          series.columns.template.adapter.add('tooltipText', function (text,target) {
            if (props.chartProps.chartId === "anomaly-chart") {
              var data = target?.tooltipDataItem?.dataContext;
              let loss = usageType === i18n.predictiveNetworks.topTunnel
                ? topTunnel(data?.topx_tunnel_usage_loss_percentage_anomaly_percentage_record)
                : topTunnel(data?.loss_percentage_anomaly_percentage_record)
              let latency = usageType === i18n.predictiveNetworks.topTunnel
                ? topTunnel(data?.topx_tunnel_usage_latency_anomaly_percentage_record)
                : topTunnel(data.latency_anomaly_percentage_record)
              let jitter = usageType === i18n.predictiveNetworks.topTunnel
                ? topTunnel(data?.topx_tunnel_usage_jitter_anomaly_percentage_record)
                : topTunnel(data?.jitter_anomaly_percentage_record)
              return `${i18n.postSpace}[bold]{entry_ts.formatDate("MM/dd/yyyy hh:mm a")}[/]\n
                [${barColors['loss_percentage_anomaly_percentage_record']}]${charCircle}[/] ${i18n.preSpace} Loss: ${i18n.preSpace} ${loss}%\n
                [${barColors['latency_anomaly_percentage_record']}]${charCircle}[/] ${i18n.preSpace} Latency: ${i18n.preSpace}  ${latency}%\n
                [${barColors['jitter_anomaly_percentage_record']}]${charCircle}[/] ${i18n.preSpace} Jitter: ${i18n.preSpace} ${jitter}%\n${i18n.postSpace}`;
            }
            return text
            });
          series.tooltip.getFillFromObject = false;
          series.tooltip.background.fill = colors.gray100;
          series.tooltip.autoTextColor = false;
          series.tooltip.label.fill = colors.gray15;
        }
      })
    }


    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    var marker = chart.legend.markers.template.children.getIndex(0);
    marker.width = 16;
    marker.height = 16;
    marker.valign = "middle";
    marker.horizontalCenter = "left";
    marker.stroke = am4core.color("#ccc");
    chart.legend.position = "bottom";
    chart.legend.paddingBottom = 8;
    chart.legend.contentAlign = "right";

    // Add scrollbar
    chart.scrollbarX = new am4charts.XYChartScrollbar();
    chart.zoomOutButton.disabled = true;
    chart.scrollbarX.background.fill = am4core.color(colors.blue70);
    chart.scrollbarX.startGrip.visible = true;
    chart.scrollbarX.endGrip.visible = true;
    chart.scrollbarX.disabled = false;
    chart.scrollbarX.thumb.minWidth = 10;
    const startLine = chart.scrollbarX.startGrip.createChild(am4core.Rectangle);
    startLine.height = 30;
    startLine.width = 5;
    startLine.fillOpacity = 0.4;
    startLine.align = "center";
    startLine.valign = "middle";
    const endLine = chart.scrollbarX.endGrip.createChild(am4core.Rectangle);
    endLine.height = 30;
    endLine.width = 5;
    endLine.fillOpacity = 0.4;
    endLine.align = "center";
    endLine.valign = "middle";
    chart.scrollbarX.background.fillOpacity = 0.5;
    if(series) {
      chart.scrollbarX.series.push(series);
      var scrollSeries = chart.scrollbarX.scrollbarChart.series.getIndex(0);
      scrollSeries.strokeDasharray = "2,4";
    }
    chart.scrollbarX.parent = chart.bottomAxesContainer;
    chart.scrollbarX.minHeight = 30;
    chart.scrollbarX.disabled = props.disabledScrollbar;
    chart.scrollbarX.thumb.minWidth = 50;

    customizeGrip(chart.scrollbarX.startGrip);
    customizeGrip(chart.scrollbarX.endGrip);

    // Customize scrollbar thumb
    chart.scrollbarX.background.fill = am4core.color(colors.blue45);
    chart.scrollbarX.background.fillOpacity = 0.5;

    chart.chartType = "bulletColumnChart";
    return chart;
  };

  useEffect(() => {
    if (currentChartRef.current) {
      currentChartRef.current.dispose();
    }
    let chart2Obj = createStackedChart(chartId, props.data);

    return () => {
      chart2Obj.dispose();
      chart2Obj.events.disable();
    };
  }, [props.data]);

  useEffect(() => {
    return () => {
      if (currentChartRef.current) {
        currentChartRef.current.dispose();
      }
      am4core.disposeAllCharts();
    };
  }, []);

  // Style scrollbar
  const customizeGrip = grip => {
    // Remove default grip image

    // Disable background
   grip.background.disabled = true;

    // Add vertical bar
    let line = grip.createChild(am4core.Rectangle);
    line.height = 30;
    line.width = 3;
    line.fill = colors.gray80;
    line.fillOpacity = 0.4;
    line.align = "center";
    line.valign = "middle";
  };
  return (
    <div>
      <div
        id={chartId}
        className="bulletColumnAndLineChart"
        style={{
          paddingTop: props.isTitleShown ? "0px" : "55px",
          ...props.chartProps.backgroundStyle
        }}
      ></div>
    </div>
  );
};

ADStackedBarChart.propTypes = {
  chartsData: PropTypes.array.isRequired,
  chartProps: PropTypes.object.isRequired,
  isTitleShown: PropTypes.bool,
  data: PropTypes.array,
  disabledScrollbar: PropTypes.bool,
  saveData: PropTypes.func,
  selectedBar: PropTypes.string,
  usageType: PropTypes.string
};

export default ADStackedBarChart;
