import axios from "axios";
import { createGenericErrorObject, isCustom } from "../../../utils/common";
import { circuitUrls } from "../../../apis/apiConstants";

export const getCircuitsData = async v4Payload => {
  try {
    const payload = { ...v4Payload, sort: { availability: "asc" } };
    return await axios.post(circuitUrls.singleTable, payload);
  } catch (error) {
    return createGenericErrorObject(error);
  }
};

export const health = {
  nwa: "nwa",
  gray: "gray",
  red: "red",
  green: "green"
};

export const getCircuitsAvailability = (
  circuitsData,
  siteId = -1,
  getChange = false,
  getAllSiteData = false
) => {
  let availability = { change: 0, current: 0, previous: 0 };
  if (siteId === -1 && !getAllSiteData)
    availability = circuitsData.circuit_availability;
  else if (Array.isArray(circuitsData.data) && circuitsData.data.length > 0) {
    const { data, query } = circuitsData;
    const isCustomTime = isCustom(query.timeFilterType);
    const timeMS = isCustomTime
      ? query.timeRange[1] - query.timeRange[0]
      : parseInt(query.timeFilterType) * 3600000;
    const timePerPercent = timeMS / 100;
    const previousTimeMS = isCustomTime
      ? query.preTimeRange[1] - query.preTimeRange[0]
      : timeMS;
    const previousTimePerPercent = previousTimeMS / 100;
    const filteredData = getAllSiteData ?
      data.filter(item => item.health !== health.gray)
      : data.filter(item => item.site_id === siteId && item.health !== health.gray);
    let downtime = 0;
    let downtime_previous = 0;
    if (getChange)
      filteredData.forEach(item => {
        if (Number.isFinite(item.downtime)) downtime += item.downtime;
        if (Number.isFinite(item.downtime_previous))
          downtime_previous += item.downtime_previous;
      });
    else
      filteredData.forEach(item => {
        if (Number.isFinite(item.downtime)) downtime += item.downtime;
      });
    if (filteredData.length > 0) {
      availability.current =
        100 - downtime / (timePerPercent * filteredData.length);
      if (getChange) {
        availability.previous =
          100 -
          downtime_previous / (previousTimePerPercent * filteredData.length);
        availability.change = availability.current - availability.previous;
      }
    }
  }
  return availability;
};

/**
 * Description: This method returns high availability percentage value of circuits
 * @param {object} circuitsData is an object that contains data for circuits
 * @param {number} siteId is the selected site id
 */
export const getCircuitsHighAvailability = (circuitsData, siteId = -1) => {
  let availability = { change: 0, current: 0, previous: 0 };

  if (Array.isArray(circuitsData.data) && circuitsData.data.length > 0) {
    // excludes gray circuits and filters circuits by selected site id
    const filteredData = siteId === -1 ?
      circuitsData.data.filter(item => item.health !== health.gray)
      : circuitsData.data.filter(item => item.site_id === siteId && item.health !== health.gray);

    if (filteredData.length > 0) {
      const totalCount = filteredData.length;
      let good = 0;
      let prevGood = 0;
      for (const { availability, availability_previous } of filteredData) {
        if (availability >= 95) good++;
        if (availability_previous >= 95) prevGood++;
      }
      availability.current = (good / totalCount) * 100;
      availability.previous = (prevGood / totalCount) * 100;
      availability.change = availability.current - availability.previous;
    }
  }
  return availability;
};
