import React, { useState, Suspense } from "react";
import { useEffect } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

//components
import AppHOC from "../../../../generics/AppHOC";
import reduxContext from "../../reducer";
import Spinner from "../../../../common/Spinner";
import BulletColumnAndLineChart from "../../../../common/BulletColumnAndLineChart";

// lazy loading components
const ErrorComponent = React.lazy(() =>
  import("../../../../common/ErrorComponent")
);
const NoDataAvailable = React.lazy(() =>
  import("../../../../common/NoDataAvailable")
);

//utils
import useApplicationInfoHooks from "../../useApplicationInfoHooks";
import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";
import {
  getLocalStorageFlag,
  getTipBulletColumnChart
} from "../../../../utils/common";
import { validateProps } from "../../../../utils/waniCommon";

//style
import css from "../../insightsAppMagneticStyle.less";

const mapStateToProps = state => {
  return {
    predictiveNetworks: state.vanalytics.predictiveNetworks
  };
};

const [HbrCard] = reactWrapper(["hbr-card"]);

const UserExperienceChrt = props => {
  const { endpoints } = props.globalFilter;
  const {
    selectedOverlay,
    selectedSite,
    onboardingStatus,
    globalAltoId,
    appGroup
  } = validateProps(props.globalFilter);

  const { state, getChartData } = useApplicationInfoHooks();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (onboardingStatus === "ACTIVE") {
      setIsLoading(true);
      getChartData(globalAltoId, selectedOverlay, endpoints);
    } else {
      setIsLoading(false);
    }
  }, [appGroup]);

  useEffect(() => {
    if (onboardingStatus === "ACTIVE") {
      if (state.chartData?.chartList?.length !== undefined) {
        setIsLoading(true);
        getChartData(globalAltoId, selectedOverlay, endpoints);
      }
    }
  }, [selectedSite]);

  useEffect(() => {
    if (state.isFetchingUserChartDone) setIsLoading(false);
  }, [state.isFetchingUserChartDone, state.isFetchingUserChartError]);

  if (isLoading) {
    return (
      <div className={css["user-experience-chart"]}>
        <div data-cy="spinner-userExperienceChart">
          <Spinner />
        </div>
      </div>
    );
  } else {
    if (_.isEmpty(state.chartData?.chartList)) {
      return (
        <HbrCard
        className={css["user-experience-chart"]}
        data-cy="user-experience-chart"
      >
        <Suspense fallback={<Spinner />}>
          <NoDataAvailable text={i18nMessageBundle.syncronizedChartNoData} />
        </Suspense>
        </HbrCard>
      );
    } else if (state.isFetchingUserChartError.errorObject) {
      return (
        <Suspense fallback={<Spinner />}>
          <ErrorComponent
            {...state.isFetchingUserChartError.errorObject}
            width={"200px"}
            className={"super-small-dashlet-error"}
          />
        </Suspense>
      );
    } else {
      return (
        <HbrCard
          className={css["user-experience-chart"]}
          data-cy="user-experience-chart"
        >
          <div className="bullet-chart-view">
            <BulletColumnAndLineChart
              data={state.chartData.chartList}
              chartsData={[]}
              isTitleShown={true}
              chartProps={{
                site: parseInt(getLocalStorageFlag("actionCardSite")),
                isSeriesConnected: false,
                chartId: "isLoaded_chart",
                tooltipHTML: getTipBulletColumnChart(),
                title: i18nMessageBundle.appGroupWidget.recommendations,
                zoomInDaysCount: 1.5,
                leftTitleForY:
                  i18nMessageBundle.appGroupPerSite.userExpChartActiveUsrLabel,
                rightTitleForY: i18nMessageBundle.appGroupPerSite.meanQuality,
                legendLabels: [
                  i18nMessageBundle.appGroupPerSite.userExpChartRecomLabel,
                  i18nMessageBundle.appGroupPerSite
                    .userExpChartCurrentPathLabel,
                  i18nMessageBundle.appGroupPerSite.userExpChartActiveUsrLabel
                ]
              }}
            />
          </div>
        </HbrCard>
      );
    }
  }
};

UserExperienceChrt.propTypes = {
  globalFilter: PropTypes.object
};

export default reduxContext.withProvider(
  connect(mapStateToProps)(AppHOC(UserExperienceChrt))
);
