import React, { useState, useEffect, useCallback, useRef } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import AppHOC from "../../../generics/AppHOC";
import Spinner from "../../../common/Spinner";
import ErrorComponent from "../../../common/ErrorComponent";
import NoDataAvailable from "../../../common/NoDataAvailable";
import StackedMultiLineChart from "../../../common/StackedMultiLineChart";
import { getDiaVsTunnelChartData } from "../actions";
import { useMount } from "../../../utils/genericCommon";
import { addBytes } from "../../../utils/format";
import {
  displayDateTime,
  getTimestampFromUTCString
} from "../../../utils/displayTime";
import { getCurrentCircuit } from "../common";
import i18n from "amdi18n-loader!../../nls/i18n";
import css from "../circuits360MagneticStyle.less";
import { defaultTextValue } from "../../../utils/enums";
import { chartPrimaryColors } from "../../../utils/colors";
import { compareAndMatchDateTime, getIsHourlyData, getTimeInterval } from "../../../utils/common";

const [HbrCard] = reactWrapper(["hbr-card"]);
// color set for the lines of graph
const seriesColors = {
  dia: chartPrimaryColors[1],
  tunnel: chartPrimaryColors[0]
};

const View = ({
  globalFilter,
  match: {
    params: { circuitName }
  }
}) => {
  const [loader, setLoader] = useState(true);
  const dataRef = useRef({
    timestamp: 0,
    error: null,
    data: [],
    seriesColors: { ...seriesColors },
    reverseOrder: false
  });
  const mount = useMount();

  const loadData = useCallback(async (gFilter, queryData, timestamp) => {
    const res = await getDiaVsTunnelChartData(
      gFilter.globalV4Payload,
      queryData
    );
    if (mount.mounted === true && timestamp === dataRef.current.timestamp) {
      if (res.errorObject instanceof Object)
        dataRef.current.error = res.errorObject;
      else if (
        res.data instanceof Object && Array.isArray(res.data.data)
        && res.data.data.length > 0
      ) {
        // create entry object from response data
        const timeInterval = getTimeInterval(gFilter.globalV4Payload.time_frame);
        const min = gFilter.timeFilter.current_period[0];
        const resData = res.data.data;
        const entryTime = {};
        for (const item of resData) {
          const date = getTimestampFromUTCString(item.entry_ts);
          if (date < min) continue;
          if (Number.isFinite(item.usage)) {
            if (entryTime[date] instanceof Object)
              entryTime[date][item.flow_type] = item.usage;
            else entryTime[date] = { date, [item.flow_type]: item.usage };
          }
        }

        // create entry array
        const entryData = Object.values(entryTime);
        // generate graph data from entry array
        if (entryData.length > 0) {
          const missedEntries = [];
          const lastIndex = entryData.length - 1;

          let diaUsage = 0;
          let tunnelUsage = 0;
          let validTunnelUsage = false;
          entryData.forEach((item1, index) => {
            // create graph hover tooltip data
            const { values, str } = addBytes([
              item1.dia || 0,
              item1.tunnel || 0
            ]);
            item1.tooltipData = {
              date: displayDateTime(item1.date),
              dia: Number.isFinite(item1.dia)
                ? values[0].str
                : defaultTextValue,
              tunnel: Number.isFinite(item1.tunnel)
                ? values[1].str
                : defaultTextValue,
              total: str
            };
            if (Number.isFinite(item1.dia)) diaUsage += item1.dia;
            if (Number.isFinite(item1.tunnel)) {
              tunnelUsage += item1.tunnel;
              validTunnelUsage = true;
            }
            const item2 = index < lastIndex ? entryData[index + 1] : null;
            if (item2 instanceof Object) {
              /* add an entry to extend lines till current entry's end range if
            next entry is after an hour */
              if (item2.date > item1.date + timeInterval) {
                missedEntries.push({ ...item1, date: item1.date + (timeInterval - 1) });
              } else {
                /* add an entry in current entry's end range if dia or tunnel
                  in current entry is missing in next entry */
                const item = {
                  ...item1,
                  date: item1.date + (timeInterval -1)
                };
                let missedDatapoint = false;
                if (Number.isFinite(item1.dia)) {
                  if (Number.isFinite(item2.dia) === false) {
                    item.dia = 0;
                    missedDatapoint = true;
                  } else item.dia = item2.dia;
                }
                if (Number.isFinite(item1.tunnel)) {
                  if (Number.isFinite(item2.tunnel) === false) {
                    item.tunnel = 0;
                    missedDatapoint = true;
                  } else item.tunnel = item2.tunnel;
                }
                if (missedDatapoint === true) missedEntries.push(item);
              }
              /* add an entry for the last entry to extend lines till its
              end range */
            }
          });
          // set graph data and sort based on entry time

          let diaTunnelData = entryData.concat(missedEntries)
            .sort((item1, item2) => item1.date > item2.date ? 1 : -1);
          if (getIsHourlyData(gFilter.globalV4Payload.time_frame)) {
            diaTunnelData = compareAndMatchDateTime(diaTunnelData, globalFilter, "date");
          }
          dataRef.current.data = diaTunnelData;
          if (diaUsage > tunnelUsage && validTunnelUsage === true) {
            dataRef.current.seriesColors = {
              tunnel: chartPrimaryColors[1],
              dia: chartPrimaryColors[0]
            };
            dataRef.current.reverseOrder = true;
          } else
            dataRef.current.seriesColors = {
              dia: chartPrimaryColors[1],
              tunnel: chartPrimaryColors[0]
            };
        }
      }
      setLoader(false);
    }
  }, []);

  // load bandwidth usage graph data
  useEffect(() => {
    // get circuit data from session storage
    const circuit = getCurrentCircuit(circuitName);
    if (
      circuit &&
      (circuit.overlay === globalFilter.selectedOverlay || mount.initMount)
    ) {
      const timestamp = Date.now();
      Object.assign(dataRef.current, {
        timestamp,
        error: null,
        data: [],
        seriesColors: { ...seriesColors },
        reverseOrder: false
      });
      setLoader(true);
      loadData(globalFilter, circuit, timestamp);
    }
    mount.initMount = false;
  }, [
    globalFilter.timeFilter.current_period[0],
    globalFilter.timeFilter.current_period[1],
    globalFilter.currentTimeStamp,
    circuitName
  ]);

  // customize the chart
  const customizeChart = useCallback(chart => {
    if (dataRef.current.reverseOrder === true) {
      chart.series.values[0].name = i18n.circuit.tunnel;
      chart.series.values[1].name = i18n.circuit.ddcDia;
    } else {
      chart.series.values[0].name = i18n.circuit.ddcDia;
      chart.series.values[1].name = i18n.circuit.tunnel;
    }
    chart.series.values.forEach(series => {
      series.adapter.add("tooltipHTML", (html, target) => {
        const data = target.tooltipDataItem.dataContext;
        if (data && data.tooltipData) {
          const series = [...chart.series.values].reverse();
          html = `
            <div class="ttip-header">
              <span class="date">${data.tooltipData.date}</span>
            </div>
            <table class="ttip-content">
            <tr>
              <td>${i18n.circuit.totalUsage}</td>
              <td>${data.tooltipData.total}</td>
            </tr>
            ${series
              .map(item =>
                !item.isHidden
                  ? `
                    <tr>
                      <td>
                        <div class="flex-items">
                          <span
                            class="square-legend"
                            style="background-color:${item.fill.hex};"
                          ></span>
                          ${item.name}
                        </div>
                      </td>
                      <td>${data.tooltipData[item.dataFields.valueY]}</td>
                    </tr>
                  `
                  : ""
              )
              .join("")}
            </table>`;
        }
        return html;
      });
    });
    chart.yAxes.values[0].numberFormatter.numberFormat = "#.b";
    chart.yAxes.values[0].maxPrecision = 0;
    chart.yAxes.values[0].renderer.minGridDistance = 75;
  }, []);

  return (
    <HbrCard
      className={`${css.chart} widget-container`}
      data-testid="c360_dia_vs_tunnel_usage_chart"
      container
    >
      <div className="flex-column flex-main-content">
        <div className="title-block">
          <div className="hbr-type-h2">{i18n.circuit.ddcTitle}</div>
        </div>
        {loader === true ? (
          <Spinner />
        ) : dataRef.current.error !== null ? (
          <ErrorComponent
            {...dataRef.current.error}
            className="small-dashlet-error"
            width="220px"
          />
        ) : dataRef.current.data.length === 0 ? (
          <NoDataAvailable />
        ) : (
          <div className="flex-main-content">
            <StackedMultiLineChart
              id="c360_dia_vs_tunnel"
              scrollbar
              scrollBarInitialZoom
              label={i18n.c360.ucYLabel}
              callbackFromParent={customizeChart}
              data={dataRef.current.data}
              min={globalFilter.timeFilter.current_period[0]}
              max={globalFilter.timeFilter.current_period[1]}
              colorset={dataRef.current.seriesColors}
            />
          </div>
        )}
      </div>
    </HbrCard>
  );
};

View.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default AppHOC(View);
