import React, { Suspense } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

// components
import { MultiLineChart } from "../../../../../common/MultiLineChart";
import Spinner from "../../../../../common/Spinner";
import AppHOC from "../../../../../generics/AppHOC";
import ChartLegend from "../../../../../common/ChartLegend";

// lazy loading components
const ErrorComponent = React.lazy(() =>
  import("../../../../../common/ErrorComponent")
);

// utils
import reduxContext from "../../../reducer";
import { chartPrimaryColors } from "../../../../../utils/colors";
// i18nMessageBundle
import i18nMessageBundle from "amdi18n-loader!../../../../nls/i18n";

// style
import css from "../../../insightsAppMagneticStyle.less";

const mapStateToProps = state => {
  return {
    applications: state.vanalytics.applications
  };
};

const [HbrCard] = reactWrapper(["hbr-card"]);

// Format legend data
const formatLegendData = legend => {
  const { labels } = legend;
  return labels.map((label, index) => {
    const color = chartPrimaryColors[index];
    const formatLabel = label;
    return { label: formatLabel, color };
  });
};

const PathQosMetrics = props => {
  switch (true) {
    case props.hasFailed:
      return (
        <div
          className={css["path-metrics-chart"]}
          data-cy="pathMetricsChartError"
        >
          <Suspense fallback={<Spinner />}>
            <ErrorComponent
              {...props.errorMessage}
              width={"200px"}
              className={"super-small-dashlet-error"}
            />
          </Suspense>
        </div>
      );
    case props.isDone:
      return (
        <HbrCard
          className={css["path-metrics-chart"]}
          data-cy="pathMetricsChart"
        >
          <div>
            <div className="hbr-type-h2">{i18nMessageBundle.appGroupPerSite.pathMetricsLabel}</div>
            {props.legend &&
              <ChartLegend
                data={formatLegendData({
                  labels: props.legend.map(l => (`${l.dstInterfaceId} · ${l.srcInterfaceId}`)),
                })}>
              </ChartLegend>}
          </div>
          <div className="three-charts">
            {[
              {
                key: "pathMetrixLoss",
                label: i18nMessageBundle.appGroupPerSite.sidePanelLossLabel
              },
              {
                label: i18nMessageBundle.appGroupPerSite.sidePanelLatencyLabel,
                key: "pathMetrixLatency"
              },
              {
                label: i18nMessageBundle.appGroupPerSite.sidePanelJitterLabel,
                key: "pathMetrixJitter"
              }
            ].map(config => {
              return (
                <div key={config.key}>
                  <MultiLineChart
                    id={"insights_path_qos_metrics" + config.key}
                    callbackFromParent={props.callbackFromParent}
                    label={config.label}
                    chartsData={props.chartsData}
                    skipAccordion={false}
                    showSynchronization
                    data={props.data[config.key]}
                    topChart="true"
                    isPathQoSdetails
                    enableDisposeAllCharts={false}
                  />
                </div>
              );
            })}
          </div>
        </HbrCard>
      );
    default:
      return (
        <div className="center-spinner">
          <Spinner />
        </div>
      );
  }
};

PathQosMetrics.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  applications: PropTypes.object,
  actions: PropTypes.object,
  callbackFromParent: PropTypes.func.isRequired,
  hoverCallback: PropTypes.func,
  chartsData: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  hasFailed: PropTypes.bool,
  isDone: PropTypes.bool,
  errorMessage: PropTypes.object,
  legend: PropTypes.array
};

export default reduxContext.withProvider(
  connect(mapStateToProps)(AppHOC(PathQosMetrics))
);
