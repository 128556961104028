import React, { memo, useEffect, useMemo, useRef } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { DtableWrapper } from "dnx-web-components/dist/index-with-deps-react.min";
import table from "dnx-web-components/dist/table.min";
import { useMount } from "../../../../utils/genericCommon";
import { createRequest } from "../../../../utils/query-utils";
import { defaultTableProps, getTools } from "./tableConfig";
import apiService from "../../../../config/api-config";
import css from "../../../circuits/circuitsMagneticStyle.less";

const [HbrCard] = reactWrapper(["hbr-card"]);
const DnxTable = DtableWrapper(table);
let isFilterChanged = false;
const Table = ({ globalFilter, circuitData }) => {
  const tableRef = useRef(null);
  const mount = useMount();
  let site = circuitData?.site_id || -1
  const dataRef = useRef({
    tableProps: {
      ...defaultTableProps,
      getData: aoData => getData(aoData, tableRef, dataRef),
      tools: getTools(dataTable => getExportData(dataTable, dataRef))
    },
    v4Payload: {
      ...globalFilter.globalV4Payload,
      site_id: site,
      vdevice_id: circuitData.vdevice_id,
      local_color: circuitData.color
    }
  });

  useMemo(() => {
    if (mount.initMount === false)
      Object.assign(dataRef.current.v4Payload, globalFilter.globalV4Payload);
  }, [globalFilter.globalV4Payload]);

  useEffect(() => {
    if (mount.initMount === false && tableRef.current !== null) {
      isFilterChanged = true;
      tableRef.current.table.refresh();
    }
    mount.initMount = false;
  }, [
    globalFilter.timeFilter.current_period[0],
    globalFilter.timeFilter.current_period[1],
    globalFilter.currentTimeStamp
  ]);

  return (
    <HbrCard className={`${css.table} widget-container`} container>
      <div className="flex-column flex-main-content">
        <DnxTable ref={tableRef} {...dataRef.current.tableProps} />
      </div>
    </HbrCard>
  );
};

const getData = async (aoData, tableRef, dataRef) => {
  let payload = {
    ...dataRef.current.v4Payload,
    sort: {
      usage: "desc",
      tunnel_name: "asc"
    },
    offset: 0,
    size: 25
  };
  if (tableRef.current !== null) {
    payload = createRequest(payload, aoData, tableRef.current.table)
    let dTable = tableRef.current.table;
    if (dTable?.store?.state?.search_simpleQuery) {
      payload.search = dTable.store.state.search_simpleQuery;
    }
  }
  if (isFilterChanged) {
    payload.sort = {
      usage: "desc",
      tunnel_name: "asc"
    }
    isFilterChanged = false;
  }
  const result = await apiService.getCircuitsTunnelList(payload);
  if (result.errorObject instanceof Object === false && result.data instanceof Object
              && Array.isArray(result.data.data)){
    return { response: result.data.data, count: result.data.count };
  } else {
    return { response: [], count: 0 };
  }
};

const getExportData = (dataTable, dataRef) => {
  const { columns } = dataTable.props;
  const settings = dataTable.table.settings()[0];
  const payload = {
    ...dataRef.current.v4Payload,
    sort: {
      [columns[settings.aaSorting[0][0]].data]: settings.aaSorting[0][1]
    }
  };
  if (settings.oPreviousSearch.sSearch)
    payload.search = settings.oPreviousSearch.sSearch;
  return apiService.getCircuitsTunnelList(payload);
};

Table.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  circuitData: PropTypes.object.isRequired
};

export default memo(Table);