import Authorization from "../../../generics/authorization";
import HeaderBar from "../common/HeaderBar";
import ActionCards from "./ActionCards";
import UserExperienceChart from "../common/UserExperienceChart";
import SitesTable from "../common/SitesTable";

const pageProps = {
  filterDashlets: [{
    component: HeaderBar,
    name: "header-hero-bar-site-info",
    layout: { x: 0, y: 0, w: 12, h: 3 },
    style: { zIndex: 1 }
  }],
  dashlets: [
    {
      component: ActionCards,
      name: "single-site-action-card",
      layout: { x: 0, y: 12, w: 2.5, h: 50 }
    },
    {
      component: UserExperienceChart,
      name: "single-site-user-experience-chart",
      layout: { x: 4, y: 15, w: 9.5, h: 21 }
    },
    {
      component: SitesTable,
      name: "single-site-table",
      layout: { x: 4, y: 20, w: 9.5, h: 36 }
    }
  ]
};

const Page = () => (<Authorization pageProps={pageProps} />);

export default Page;
