export const ACTIONS = {
  GET_AD_DATE: "GET_AD_DATE",
  GET_BAR_CHART_DATE: "GET_BAR_CHART_DATE",
  GET_KPI_DATE: "GET_KPI_DATE",
  UPDATE_USAGE_TYPE: "UPDATE_USAGE_TYPE"
};

export const saveDateForTable = date => dispatch => {
  dispatch({
    type: ACTIONS.GET_AD_DATE,
    payload: date
  });
};

export const saveKpiDateForTable = payload => dispatch => {
  dispatch({
    type: ACTIONS.GET_KPI_DATE,
    payload: payload
  });
};

export const updateBarChartData = payload => dispatch => {
  dispatch({
    type: ACTIONS.GET_BAR_CHART_DATE,
    payload: payload
  });
};

export const updateUsageType = payload => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_USAGE_TYPE,
    payload: payload
  });
}
