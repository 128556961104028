import keyStore from "../../reducer";
import { convertedKpiName } from "../../../../utils";
import i18n from "../../../../../nls/root/i18n";

const anomaliesDetected = (rowData, history, url, usageType) => {
    if (rowData.kpiType) {
      let chartData = keyStore.store.getState().vanalytics.adKey.adStackedBarChartInfo
      let selectedDate = keyStore.store.getState().vanalytics.adKey.adDate
      let selectedChartData = chartData[rowData.kpiType]
      sessionStorage.setItem(
        "adSelectedChartData",
        JSON.stringify({kpiType: rowData.kpiType, dateSelected: selectedDate, selectedChartData, usageType: usageType })
      );
      history.push(`${url}/predictivenetworks/anomalydetection/${convertedKpiName[rowData.kpiType]}`);
    }
  };

export const getColumns = (history, url, usageType) => {
    return [
        { id: "kpiType_col",
          prop: "kpiType",
          name: i18n.predictiveNetworks.kpi,
          sortable: true,
          canResize: true,
          size: 250
        },
        { id: "numOfAnomaliesDetected_col",
          prop: "numOfAnomaliesDetected",
          name: i18n.predictiveNetworks.anomalyOneHourLabel,
          sortable: true,
          size: 250,
          cellTemplate: (createElement, prop) => {
            if(prop.model.numOfAnomaliesDetected !== 0) {
              return createElement("hbr-link", 
              {
                "onHbr-focus": (e) => {
                  e.preventDefault()
                  anomaliesDetected(prop.model, history, url, usageType)
                }
              }, prop.model.numOfAnomaliesDetected);
            }
          }
        },
        { id: "percentage_col",
          prop: "percentage",
          name: i18n.predictiveNetworks.anomalyRate,
          sortable: true,
          size: 250
        },
        { id: "numOfImpactedSites_col",
          prop: "numOfImpactedSites",
          name: i18n.predictiveNetworks.anomalySitesLabel,
          sortable: true,
          size: 250
        },
        { id: "numOfImpactedTunnels_col",
          prop: "numOfImpactedTunnels",
          name: i18n.predictiveNetworks.anomalyTunnelsLabel,
          sortable: true,
          size: 250
        }
    ];
}