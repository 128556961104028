import { useState } from "react";
import _ from "lodash";

//utils
import {
  formatEndpointsById,
  getRecommendationFormatted,
  createLocalRemoteDevicesObject,
  recommandActionCards,
  formatEndpointsBySiteid,
  formatActionStateLabel
} from "../../utils/waniCommon";
import { setLocalStorageFlag, getLocalStorageFlag, getStoredCurrentSite } from "../../utils/common";
import ApiService from "../../config/api-config";
import { displayUserExpChartTooltp } from "../../utils/displayTime";

const arrayToObject = (array, attr) =>
  array.reduce((obj, item) => {
    obj[item[attr]] = item;
    return obj;
  }, {});

const useApplicationInfoHooks = () => {
  const appGroup = getLocalStorageFlag("appGroup");
  const [chartData, setChartData] = useState();
  const [singleSiteTableData, setSingleSiteTableData] = useState();
  const [isFetchingUserChartDone, setIsFetchingUserChartDone] = useState(false);
  const [isFetchingUserChartError, setIsFetchingUserChartError] = useState({});
  const [allSiteTableData, setAllSiteTableData] = useState();
  const [
    isFetchingAppGroupAllSiteTableDataDone,
    setIsFetchingAppGroupAllSiteTableDataDone
  ] = useState(false);
  const [
    isFetchingAppGroupAllSiteTableDataError,
    setIsFetchingAppGroupAllSiteTableDataError
  ] = useState({});
  const [
    isFetchingAppGroupPerSingleSiteTableDataDone,
    setIsFetchingAppGroupPerSingleSiteTableDataDone
  ] = useState(false);
  const [
    isFetchingAppGroupPerSingleSiteTableDataError,
    setIsFetchingAppGroupPerSingleSiteTableDataError
  ] = useState({});
  const [isFetchingActionCardsDone, setIsFetchingActionCardsDone] = useState(
    false
  );
  const [isFetchingActionCardsError, setIsFetchingActionCardsError] = useState(
    {}
  );
  const [actionCards, setActionCards] = useState();
  /**
   * Returns an object 'chartDat' that contains such props as: chartList, recoEndpointMetrics
   * @description call api to get user experience chart details for one month for chart in the Dashboard>Recommendations
   * @param {object} props is an object that  contains globalFilter with such props as:
   * appGroup, endpoints, selectedOverlay, globalAltoId
   */
  const getChartData = async (globalAltoId, selectedOverlay, endpoints) => {
    const siteId = getStoredCurrentSite();
    const initErrorObject = { errorObject: { errorCode: 500, message: "" } };
    let endpointId;

    setIsFetchingUserChartDone(false);

    if (isFetchingUserChartError.errorObject) {
      setIsFetchingUserChartError({});
    }

    if (siteId === -1) {
      if (globalAltoId) {
        endpointId = globalAltoId;
      } else {
        const endpointIdResult = await ApiService.getAltoEndpoints(
          {
            aggregatedBy: "location.global",
            limit: 5000
          },
          selectedOverlay
        );
        if (endpointIdResult.errorObject || endpointIdResult?.data?.error) {
          if (endpointIdResult?.data?.error) {
            setIsFetchingUserChartError(initErrorObject);
          } else {
            setIsFetchingUserChartError(endpointIdResult);
          }
        } else {
          endpointId = endpointIdResult.data.endpoints[0].endpointId;
          setLocalStorageFlag("globalAltoId", endpointId);
        }
      }
    } else {
      if (_.isEmpty(endpoints)) {
        const result = await ApiService.getAltoEndpoints(
          { limit: 5000 },
          selectedOverlay
        );

        if (result?.errorObject || result?.data?.error) {
          if (result?.data?.error) {
            setIsFetchingUserChartError(initErrorObject);
          } else {
            setIsFetchingUserChartError(result);
          }
        }
        endpoints = formatEndpointsBySiteid(result.data.endpoints);
      }

      endpointId = endpoints[siteId.toString()]
        ? endpoints[siteId.toString()].endpointId
        : endpoints[0];
    }

    const resOneHourMetric = await ApiService.getAltoRecoEndpointMetrics(
      {
        windowSize: "1h",
        appClass: appGroup,
        numWindows: 720,
        endpointId: endpointId,
        experimental: "v2",
        limit: 5000
      },
      selectedOverlay
    );

    const resOneDayMetric = await ApiService.getAltoRecoEndpointMetrics(
      {
        windowSize: "30d",
        appClass: appGroup,
        numWindows: 1,
        endpointId: endpointId,
        experimental: "v2",
        limit: 5000
      },
      selectedOverlay
    );

    if (resOneHourMetric.errorObject) {
      setIsFetchingUserChartError(resOneHourMetric);
    } else if (resOneDayMetric.errorObject) {
      setIsFetchingUserChartError(resOneDayMetric);
    } else if (resOneHourMetric) {
      const chartData =
        resOneHourMetric.data && resOneHourMetric.data.recoEndpointMetrics
          ? resOneHourMetric.data.recoEndpointMetrics.map(item => {
              return {
                totalNumUsers:
                  item.totalNumUsers === null ? 0 : item.totalNumUsers,
                recommendationQuality:
                  item.recommendationQuality === null
                    ? 0
                    : Math.round(item.recommendationQuality * 100),
                defaultQuality:
                  item.defaultQuality === null
                    ? 0
                    : Math.round(item.defaultQuality * 100),
                fromDateFormated: displayUserExpChartTooltp(item.window.start),
                toDateFormated: displayUserExpChartTooltp(item.window.end),
                fromDate: new Date(item.window.start),
                toDate: new Date(item.window.end),
                date: new Date(item.windowEnd)
              };
            })
          : [];
      setChartData({
        chartList: chartData
      });
    }
    setIsFetchingUserChartDone(true);
  };

  /**
   * Returns an object 'siteList' that contains such props as: siteId, totalNumUsers, defaultQuality, recommendationQuality, userImpact, actionState
   * @description call api to get table data with all sites list details in the Dashboard>Recommendations
   * @param {object} props is an object that  contains globalFilter with such props as:
   * appGroup, selectedOverlay
   */
  const getAppGroupAllSiteTableData = async selectedOverlay => {
    const sitesList = [];

    setIsFetchingAppGroupPerSingleSiteTableDataDone(false);
    setIsFetchingAppGroupAllSiteTableDataDone(false);

    if (isFetchingAppGroupAllSiteTableDataError.errorObject) {
      setIsFetchingAppGroupAllSiteTableDataError({});
    }

    const recoMetric = await ApiService.getAltoRecoEndpointMetrics(
      {
        windowSize: "30d",
        appClass: appGroup,
        numWindows: 1,
        aggregatedBy: "siteId",
        experimental: "v2",
        limit: 5000
      },
      selectedOverlay
    );

    const endpointsList = await ApiService.getAltoEndpoints(
      { limit: 5000 },
      selectedOverlay
    );
    const actionStateList = await ApiService.getAltoEndpointRecommendationAction(
      {
        appClass: appGroup,
        limit: 5000
      },
      selectedOverlay
    );

    if (actionStateList.errorObject) {
      setIsFetchingAppGroupAllSiteTableDataError(actionStateList);
    } else if (endpointsList.errorObject) {
      setIsFetchingAppGroupAllSiteTableDataError(endpointsList);
    } else if (recoMetric.errorObject) {
      setIsFetchingAppGroupAllSiteTableDataError(recoMetric);
    } else if (actionStateList) {
      const srcEndpointIdObj = arrayToObject(
        actionStateList.data.actions,
        "srcEndpointId"
      );
      const formatedEndpointsById = formatEndpointsById(
        endpointsList.data.endpoints
      );
      const appClass = recoMetric.config.params.appClass;

      recoMetric.data.recoEndpointMetrics.forEach(elem => {
        if ([elem.endpointId] in formatedEndpointsById) {
          const {
            defaultQuality,
            recommendationQuality,
            userImpact,
            gains,
            impactedUsers
          } = getRecommendationFormatted(elem, false);
          const currentPath = srcEndpointIdObj[elem.endpointId]
            ? srcEndpointIdObj[
                elem.endpointId
              ].metadata.defaultInterfaceIds.toString()
            : "--";
          const recommendedPath = srcEndpointIdObj[elem.endpointId]
            ? srcEndpointIdObj[
                elem.endpointId
              ].metadata.recommendedInterfaceIds.toString()
            : "--";

          sitesList.push({
            appGroup: appClass,
            siteIds: formatedEndpointsById[elem.endpointId].siteId,
            location: formatedEndpointsById[elem.endpointId].location,
            currentPath: currentPath,
            recommendedPath: recommendedPath,
            defaultQuality: defaultQuality,
            recommendationQuality: recommendationQuality,
            gains: gains,
            impactedUsers: impactedUsers,
            userImpact: userImpact,
            state: srcEndpointIdObj[elem.endpointId]
              ? formatActionStateLabel(srcEndpointIdObj[elem.endpointId])
              : "--"
          });
          // delete the sites that have recommendation
          delete formatedEndpointsById[elem.endpointId];
        }
      });
      setAllSiteTableData(sitesList);
    }
    setIsFetchingAppGroupAllSiteTableDataDone(true);
  };

  /**
   * Returns an object 'recoObject' that contains such props as: pathRecommendation, defaultQuality, recommendationQuality
   * @description call api to get interface details data for side panel for load balance chart
   * @param {string} endpoint of a site (i.e. 'mrNh2Yolew')
   */
  const getAppGroupSingleSiteTableData = async site => {
    const currentEndpointId = site?.endpointId
      ? site.endpointId
      : getLocalStorageFlag("currentEndpointId");
    const isSymEndpoint =
      site?.symEndpointsArray && site.symEndpointsArray.length;

    setIsFetchingAppGroupAllSiteTableDataDone(false);
    setIsFetchingAppGroupPerSingleSiteTableDataDone(false);

    if (isFetchingAppGroupPerSingleSiteTableDataError.errorObject) {
      setIsFetchingAppGroupPerSingleSiteTableDataError({});
    }

    /*
    based on symEndpointsArray of srcParentEndpointId received when we called 
    handleActionClick(event.path[0].id, true);
    i.e. event.path[0].id = NUJUb4QfnV,lJJQc10piV,ETHPRSL5297RR004,ITSUSAB1988RR602,100%,100%
    we now can construct the api to get data for side panel
    */
    const resultPathDetails = await ApiService.getAltoPathsDetailed({
      aggregatedBy: "endpointId",
      srcParentEndpointId: currentEndpointId,
      limit: 5000
    });

    const resultPaths = isSymEndpoint
      ? await ApiService.getAltoPaths({
          windowSize: "7d",
          appClass: appGroup,
          numWindows: 1,
          actionId: getLocalStorageFlag("actionId"),
          aggregatedBy: "endpointId",
          experimental: "v2",
          limit: 5000
        })
      : [];

    let recoObject = {},
      recoArray = [];

    if (resultPathDetails.errorObject) {
      setIsFetchingAppGroupPerSingleSiteTableDataError(resultPathDetails);
    } else if (resultPaths.errorObject) {
      setIsFetchingAppGroupPerSingleSiteTableDataError(resultPaths);
    } else if (resultPathDetails.data?.paths?.length) {
      const pathsWithPairOneObject = createLocalRemoteDevicesObject(
        [resultPathDetails, resultPaths],
        getLocalStorageFlag("symEndpointOne")
      );
      recoObject = { ...pathsWithPairOneObject };

      for (const key in recoObject) {
        const defaultQ = recoObject[key].pathRecommendation.defaultQuality;
        const recomQ = recoObject[key].pathRecommendation.recommendationQuality;
        const impactedUsers = recoObject[key].pathRecommendation.impactedUsers;
        // genereate the path details table data from recommendations metrics apis
        recoArray.push({
          defaultQuality: defaultQ,
          recommendationQuality: recomQ,
          totalNumUsers: recoObject[key].pathRecommendation.totalNumUsers,
          localSite: recoObject[key].source.siteId,
          localDevice: recoObject[key].source.hostname,
          remoteDevice: recoObject[key].destination.hostname,
          remoteSite: recoObject[key].destination.siteId,
          location: recoObject[key].destination.location,
          impactedUsers: impactedUsers,
          gains: recoObject[key].pathRecommendation.gains,
          pathQosPair: recoObject[key].pathQosPair
        });
      }
      setSingleSiteTableData(recoArray);
    }
    setIsFetchingAppGroupPerSingleSiteTableDataDone(true);
  };

  const getActionCardsPerSite = async (endpoint, appGroup, selectedOverlay) => {
    let pathQuery = {
      windowSize: "7d",
      aggregatedBy: "actionId",
      appClass: appGroup,
      numWindows: 1,
      experimental: "v2",
      limit: 5000
    };
    const actionCardsPromise = [
      ApiService.getAltoPaths(pathQuery, selectedOverlay),
      ApiService.getAltoEndpointRecommendationAction(
        {
          appClass: appGroup,
          limit: 5000
        },
        selectedOverlay
      )
    ];

    Promise.all([...actionCardsPromise]).then(actionResults => {
      let actionPathsAndStates = [];
      let actionRecommendations = [];
      let errorObject;

      for (const actionResult of actionResults) {
        if (actionResult.errorObject) {
          errorObject = actionResult.errorObject;
          break;
        } else {
          if (actionResult.data.actions) {
            actionPathsAndStates = actionResult.data.actions;
          } else {
            actionRecommendations = actionResult.data.recoPathMetrics;
          }
        }
      }

      if (errorObject) {
        setIsFetchingActionCardsError(errorObject);
      } else {
        const actionResult = recommandActionCards(
          actionPathsAndStates,
          endpoint,
          actionRecommendations
        );
        setActionCards(actionResult);
        setIsFetchingActionCardsDone(true);
      }
    });
  };
  return {
    state: {
      chartData,
      actionCards,
      isFetchingActionCardsDone,
      isFetchingActionCardsError,
      isFetchingUserChartDone,
      isFetchingUserChartError,
      allSiteTableData,
      singleSiteTableData,
      isFetchingAppGroupAllSiteTableDataDone,
      isFetchingAppGroupAllSiteTableDataError,
      isFetchingAppGroupPerSingleSiteTableDataError,
      isFetchingAppGroupPerSingleSiteTableDataDone
    },
    getChartData,
    getAppGroupSingleSiteTableData,
    getAppGroupAllSiteTableData,
    getActionCardsPerSite
  };
};

export default useApplicationInfoHooks;
