import Authorization from "../../generics/authorization";
import InsightsHeroBar from "./common/InsightsHeroBar";
import UserExperienceChart from "./common/UserExperienceChart";
import SitesTable from "./common/SitesTable";

const pageProps = {
  filterDashlets: [{
    component: InsightsHeroBar,
    name: "insights-hero-bar",
    layout: { x: 0, y: 0, w: 12, h: 13 },
    style: { zIndex: 1 }
  }],
  dashlets: [
    {
      component: UserExperienceChart,
      name: "user-experience-chart",
      layout: { x: 0, y: 20.5, w: 12, h: 20.5 }
    },
    {
      component: SitesTable,
      name: "all-sites-table",
      layout: { x: 0, y: 36, w: 12, h: 36 }
    }
  ]
};

const Page = () => (<Authorization pageProps={pageProps} />);

export default Page;
