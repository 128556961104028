import React, { useEffect } from "react";
import { PropTypes } from "prop-types";
import { Redirect, useParams } from "react-router-dom";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import AppHOC from "../../../generics/AppHOC";
import TimeFilter from "../../../generics/TimeFilter";
import TimeStamp from "../../../generics/TimeStamp";
import { scrollToTop } from "../../../utils/common";
import { checkCircuit } from "../common";
import i18n from "amdi18n-loader!../../nls/i18n";                 
import { BASE_URL } from "../../../apis/apiConstants";

const [HbrBreadcrumb, HbrBreadcrumbItem, HbrIcon] =
  reactWrapper(["hbr-breadcrumb", "hbr-breadcrumb-item", "hbr-icon"]);

const FilterView = props => {
  const { circuitName } = useParams();
  const name = checkCircuit(circuitName);
  useEffect(() => name && scrollToTop(), [circuitName]);

  return (
    <>
      {" "}
      {name ? (
        <div className="flex-column header-filters-container">
          <HbrBreadcrumb>
            <HbrBreadcrumbItem
              onClick={() => props.history.push(`${BASE_URL}/circuits`)}
            >
              <div className="flex-items">
                <HbrIcon name="arrow-left" />
                {i18n.circuit.title}
              </div>
            </HbrBreadcrumbItem>
          </HbrBreadcrumb>
          <div className="flex-items header-filters-bar">
            <div className="flex-items header-filters">
              <div className="hbr-type-h1">{name}</div>
              <TimeFilter {...props} />
            </div>
            <div className="header-timestamp">
              <TimeStamp {...props} />
            </div>
          </div>
        </div>
      ) : (
        <Redirect to={`${BASE_URL}/overview`} />
      )}
    </>
  );
};

FilterView.propTypes = {
  history: PropTypes.object.isRequired
};

export default AppHOC(FilterView);
