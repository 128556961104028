import {
  compareAndMatchELT,
  getIsHourlyData,
  getTimeInterval
} from "../../../utils/common";
import { getTimestampFromUTCString } from "../../../utils/displayTime";
import i18n from "amdi18n-loader!../../nls/i18n";

export const chartTypesObj = {
  rxTx: i18n.circuit.rxTx,
  rx: i18n.circuit.rxUtilization,
  tx: i18n.circuit.txUtilization
};

export const chartTypes = Object.entries(chartTypesObj).map(item => ({
  value: item[0],
  label: item[1]
}));

export const defaultData = {
  loader: true,
  timestamp: 0,
  error: null,
  circuits: [],
  data: [],
  configStatus: { rx: false, tx: false }
};

export const setChartData = (resData, circuits) => {
  const circuitMap = Object.fromEntries(circuits.map(item => ([
    item.circuit_name,
    {
      name: item.circuit_display_name,
      rx: item.bandwidth_downstream,
      isValidRx: Number.isFinite(item.bandwidth_downstream)
        && item.bandwidth_downstream !== 0,
      tx: item.bandwidth_upstream,
      isValidTx: Number.isFinite(item.bandwidth_upstream)
        && item.bandwidth_upstream !== 0
    }
  ])));
  for (const item of resData) {
    item.date = getTimestampFromUTCString(item.entry_ts);
    const circuit = circuitMap[item.circuit_name];
    Object.assign(item, { name: circuit.name, rxTx: item.total_bps });
    item.name = circuit.name;
    if (circuit.isValidRx === true)
      item.rx =
        parseFloat(((item.rx_bps / (circuit.rx * 10))).toFixed(2))
    if (circuit.isValidTx === true)
      item.tx =
        parseFloat(((item.tx_bps / (circuit.tx * 10))).toFixed(2))
  }
  return resData;
};

export const getChartData = (gFilter, dataRef) => {
  const v4Payload = gFilter.globalV4Payload;
  const timeInterval = getTimeInterval(v4Payload.time_frame);
  const { currentCircuits, data: resData, type } = dataRef;
  const circuitNames = currentCircuits.map(item => item.circuit_display_name);
  let visibleItems = {};
  const configStatus = {
    rx: currentCircuits.some(
      item => Number.isFinite(item.bandwidth_downstream) === false
        || item.bandwidth_downstream === 0
    ),
    tx: currentCircuits.some(
      item => Number.isFinite(item.bandwidth_upstream) === false
        || item.bandwidth_upstream === 0
    )
  };
  const entryData = {};
  // create entry object from response data
  for (const { date, name, [type]: value } of resData) {
    if (Number.isFinite(value) && circuitNames.includes(name)) {
      visibleItems[name] = true;
      if (entryData[date] instanceof Object)
        entryData[date][name] = value;
      else entryData[date] = { date, [name]: value };
    }
  }
  visibleItems = Object.keys(visibleItems);
  // generate graph data from entry array
  const data = Object.values(entryData);
  const newEntries = [];
  const lastIndex = data.length - 1;
  data.forEach((entry1, dataIndex) => {
    if (dataIndex < lastIndex) {
      const entry2 = data[dataIndex + 1];
      /* add an entry to extend lines till current entry's end range if
      next entry is after an hour */
      if (entry2.date > entry1.date + timeInterval) {
        newEntries.push({ ...entry1, date: entry1.date + (timeInterval - 1) });
      } else {
        const newEntry = {
          ...entry1,
          date: entry1.date + (timeInterval - 1)
        };
        const missedItems = visibleItems.filter(item => {
          if (Number.isFinite(entry1[item])) {
            if (Number.isFinite(entry2[item]) === false) {
              newEntry[item] = entry1[item];
              return true;
            } else newEntry[item] = entry2[item];
          }
          return false;
        });
        /* add an entry in current entry's end range if any application
        in current entry is missing in next entry */
        if (missedItems.length > 0) newEntries.push(newEntry);
      }
    } else {
      if (getIsHourlyData(v4Payload.time_frame))
        compareAndMatchELT(data, gFilter.timeFilter);
      else
        newEntries.push({ ...entry1, date: entry1.date + (timeInterval - 1) });
    }
  });
  // add new records to graph data
  data.push(...newEntries);
  // sort graph data based on entry time
  data.sort((item1, item2) => item1.date > item2.date ? 1 : -1);
  return { data, visibleItems, configStatus };
};
