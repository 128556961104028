import AnomalyDetectionView from "./ADView";
import { getCookie } from "../../../../serviceWorker";
import TabNavigation from "../../../../common/TabNavigation";
import PredictiveNetworksTabs from "../../PredictiveNetworksTabs";
import PredictiveNetworkHeader from "../../PredictiveNetworkHeader";
import Authorization from "../../../../generics/authorization";

const isUIConvergence = getCookie("cl-analytics");
const xValue = 0;
const wValue = 12;

const filterDashlets = isUIConvergence ?
    {
        filterDashlets: [
            {
                component: PredictiveNetworkHeader,
                name: "predictive-header",
                layout: { x: xValue, y: 0, w: wValue, h: 4 }
            },
            {
                component: PredictiveNetworksTabs,
                name: "predictive-tabs",
                layout: { x: xValue, y: 4, w: wValue, h: 4 }
            }
        ]
    } :
    {
        filterDashlets: [
            {
                component: TabNavigation,
                name: "tab-nav",
                layout: { x: xValue, y: 0, w: wValue, h: 9 },
                style: { zIndex: 1 }
            }
        ]
    }

const pageProps = {
    ...filterDashlets,
    dashlets: [
        {
            component: AnomalyDetectionView,
            name: "ad-view",
            layout: { x: xValue, y: 0, w: wValue, h: 66 }
        }
    ]
};

const Page = () => (<Authorization pageProps={pageProps} />);

export default Page;
