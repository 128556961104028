import i18n from "amdi18n-loader!../../../nls/i18n";

export const defaultTableProps = {
  id: "c360-ft",
  dtOptions: {
    scrollY: 300,
    scrollX: true,
    stateSave: true,
    stateDuration: -1,
    order: [[7, "desc"]],
    language: {
      zeroRecords: i18n.c360.tEmpty,
      emptyTable: i18n.c360.tEmpty
    },
    deferRender: true
  },
  columnSearch: true,
  dataTableClasses: "nowrap",
  tools: [
    // table title
    {
      ref: { name: "title", config: { text: i18n.c360.ftTitle, count: true } }
    },
    // search
    {
      ref: { name: "search", config: { inputPlaceholder: i18n.c360.tSearch } },
      level: "secondary",
      justify: "start"
    },
    // time period
    { factory: null, level: "primary", justify: "end" },
    {
      ref: {
        name: "export",
        config: {
          title: i18n.c360.ftTitle,
          customize: (csvString, { csvParser }) => {
            const data = csvParser.csvStringToArray(csvString);
            for (const item of data) item.splice(8, 2);
            return csvParser.arrayToCsvString(data);
          }
        }
      },
      level: "secondary",
      justify: "end"
    }
  ],
  columns: [
    { data: "date", title: i18n.c360.ftDate, name: "ets", orderData: 8 },
    {
      data: "application",
      title: i18n.c360.ftApp,
      name: "app",
      searchable: true,
      searchPopover: true
    },
    {
      data: "source_ip",
      title: i18n.c360.ftSIp,
      name: "sip",
      searchable: true,
      searchPopover: true
    },
    {
      data: "destination_ip",
      title: i18n.c360.ftDIp,
      name: "dip",
      searchable: true,
      searchPopover: true
    },
    {
      data: "source_port",
      title: i18n.c360.ftSPort,
      name: "spt",
      type: "num"
    },
    {
      data: "destination_port",
      title: i18n.c360.ftDPort,
      name: "dpt",
      type: "num"
    },
    {
      data: "protocol_key",
      title: i18n.c360.ftPcol,
      name: "pk",
      searchable: true,
      searchPopover: true
    },
    { data: "usage", title: i18n.c360.ftUsage, name: "usg", orderData: 9 },
    { data: "entry_time", visible: false, name: "et", type: "num" },
    { data: "octets", visible: false, name: "oc", type: "num" },
    {
      data: "tunnel_name",
      title: i18n.c360.ftTName,
      name: "tunnel_name",
      searchable: true,
      searchPopover: true
    }
  ]
};
