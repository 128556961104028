import Authorization from "../../generics/authorization";
import PredictiveNetworkHeader from "./PredictiveNetworkHeader";
import PredictiveNetworksTabs from "./PredictiveNetworksTabs";
import TabNavigation from "../../common/TabNavigation";
import PredictiveWidgetTop from "./PredictiveWidgetTop";
import { getCookie } from "../../serviceWorker";

const isUIConvergence = getCookie("cl-analytics");
const xValue = 0;
const wValue = 12;

const filterDashlets = isUIConvergence ?
  {
    filterDashlets: [
      {
        component: PredictiveNetworkHeader,
        name: "predictive-header",
        layout: { x: xValue, y: 0, w: wValue, h: 4 }
      },
      {
        component: PredictiveNetworksTabs,
        name: "predictive-tabs",
        layout: { x: xValue, y: 4, w: wValue, h: 4 }
      }
    ]
  } : {
    filterDashlets: [
      {
        component: TabNavigation,
        name: "tab-nav-insights",
        layout: { x: xValue, y: 0, w: wValue, h: 9 },
        style: { zIndex: 1 }
      }
    ]
  };

const pageProps = {
  ...filterDashlets,
  dashlets: [{
    component: PredictiveWidgetTop,
    name: "predictive-path-view",
    layout: { x: xValue, y: 1, w: wValue, h: 1 }
  }]
};

const Page = () => (<Authorization pageProps={pageProps} />);

export default Page;
