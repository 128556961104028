// bits per socond units
export const bitUnits = [
  "bps",
  "Kbps",
  "Mbps",
  "Gbps",
  "Tbps",
  "Pbps",
  "Ebps",
  "Zbps",
  "Ybps"
];

/**
 * Description: This method converts number to bits per second
 * @param {*} value is the number to be converted
 * @param {*} unit is custom unit from bitUnits array
 * @param {*} decimals is the number of decimal positions after dot
 */

export const formatBits = (value, unit, decimals = 0) => {
  if (value < 1) {
    if (value > 0) {
      const number = decimals ? parseFloat(value).toFixed(decimals) : 1;
      return `${number} ${unit ? unit : bitUnits[0]}`;
    }
    return `0 ${unit ? unit : bitUnits[0]}`;
  } else {
    const unitIndex = unit
      ? bitUnits.indexOf(unit)
      : Math.floor(Math.log(Math.abs(value)) / Math.log(1000));
    const number = value / Math.pow(10, 3 * unitIndex);
    const roundedNumber = decimals
      ? parseFloat(number).toFixed(decimals)
      : Math.round(number);
    return `${roundedNumber} ${bitUnits[unitIndex]}`;
  }
};

/**
 * Description: Adds rounded string or number values in bps unit
 * @param {*} values are the string values to be added
 * @param {*} unit is custom unit from bitUnits array
 * @param {*} decimals is the number of decimal positions after dot
 * @param {number} mode is the expected return value ( 0 - formatted, 1 - number, 2 - both)
 */

export const add_bps = (values, unit, decimals = 0, mode = 0) => {
  let result = 0;
  values.forEach(value => {
    let str = value;
    if (Number.isFinite(value)) str = formatBits(value, unit, decimals);
    const [number, strUnit] = str.split(" ");
    const unitIndex = bitUnits.indexOf(strUnit);
    if (unitIndex !== -1) result += number * Math.pow(10, 3 * unitIndex);
  });
  if (mode === 0) return formatBits(result, unit, decimals);
  else if (mode === 1) return result;
  else if (mode === 2)
    return { text: formatBits(result, unit, decimals), value: result };
};

/**
 * Description: Gets circuit data from session storage
 * @param {string} circuitName is the combination as 'hostname:color'
 */
export const getCurrentCircuit = circuitName => {
  let circuit = sessionStorage.getItem("circuit");
  if (circuit) {
    circuit = JSON.parse(circuit);
    if (circuitName.replace(/~(?!.*~)/, ":") === circuit.circuit_display_name)
      return circuit;
  }
  return null;
};

/**
 * Description: Checks circuit data from session storage
 * @param {string} circuitName is the combination as 'hostname:color'
 */
export const checkCircuit = circuitName => {
  const circuit = getCurrentCircuit(circuitName);
  if (circuit && circuit.overlay === localStorage.getItem("currentOverlay"))
    return circuit.circuit_display_name;
  else sessionStorage.removeItem("circuit");
};
