import React, { useEffect, useState } from "react";
import reduxContext from "../../reducer";
import AppHoc from "../../../../../../generics/AppHOC";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { getColumns } from "./adTableConfig";
import useColumns from "@harbor/elements/utils/table/hooks/columns";
import TableStore from "@harbor/elements/utils/table/store";
import { payloadADTable, topTunnelADTable } from "../../../../../../config/reqConfig/anomalyDetection";
import ApiService from "../../../../../../config/api-config";
import ADTableView from "../../TableView";
import { adTableData } from "../../../../utils/index";
import css from "../../anomalyMagneticStyle.less";
import i18nMessageBundle from "amdi18n-loader!../../../../../nls/i18n";
import CustomTimeStamp from "../../../../../../generics/TimeStamp/customTimeStamp";
import { addUnitsFromDate } from "../../../../../../utils/displayTime";
import { BASE_URL } from "../../../../../../apis/apiConstants";

const mapStateToProps = state => {
    return {
      adKey: state.vanalytics.adKey
    };
};
  
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

let defaultSort = [['numOfAnomaliesDetected_col', 'asc']]

const ADTable = (props) => {
    const [tableStore, setTableStore] = useState("")
    const [adData, setAdData] = useState({
        isLoading: true,
        error: null,
    });

    useEffect(() => {
        if(props.adKey.adDate) {
            const columnsConfig = () => (getColumns(props.history, BASE_URL, props.usageType));
            const columnsHook = useColumns({
                config: columnsConfig,
                order: defaultSort
            });
            setAdData({ ...adData, ...{ isLoading: true, error: null } });
            let tableStore = new TableStore({
                query: { pageLength : 30 },
                hooks: [columnsHook],
                fetchData: async ({ query, setSource, setCount }) => {
                    console.debug("query", query)
                    let payload = props.usageType === i18nMessageBundle.predictiveNetworks.topTunnel
                        ? topTunnelADTable(props.adKey.adDate)
                        : payloadADTable(props.adKey.adDate)
                    let results = await ApiService.getTableKPI(payload)
                    if (!results.errorObject) {
                        const tableData = adTableData(results, props.usageType)
                        setSource(tableData)
                        setCount(tableData.length, false)
                        setAdData({ ...adData, ...{ isLoading: false } });
                    } else {
                        setAdData({ ...adData,
                            ...{ error: results.errorObject, isLoading: false } });
                    }
                }
            })
            setTableStore(tableStore)
        } else if(props.adKey.adDate === null) {
            setAdData({ ...adData, ...{ isLoading: false } });
        }
    },[props.adKey?.adDate || props.usageType, props.globalFilter.onAnomalyRefresh])

    return (tableStore &&
        <div className={css["anomaly-table"]}
             data-cy="p-netw-anomaly-table"
             data-testid="p-netw-anomaly-table">
            <div className="customt-timestamp">
                <CustomTimeStamp
                    prevTime={props.adKey.adDate}
                    currentTime={addUnitsFromDate(new Date(props.adKey.adDate), 1, "h")}
                />
            </div>
            <ADTableView
                tableStore={tableStore}
                type={"KpiDetails"}
                header={`Anomaly Summary`}
                tagInfo={true}
                infoMessage={i18nMessageBundle.infoMessage}
                status={adData}
            />
        </div>
    )
}

ADTable.propTypes = {
    globalFilter: PropTypes.object,
    actions: PropTypes.object,
    adKey: PropTypes.object,
    history: PropTypes.object.isRequired,
    usageType: PropTypes.string
};

export default reduxContext.withProvider(
    connect(mapStateToProps, mapDispatchToProps)(AppHoc(ADTable))
);